import styled from '@emotion/styled';
import Skeleton from '@mui/material/Skeleton';
import { pxToEm } from 'theme';

export const SkeletonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${pxToEm(32)};
`;

export const SkeletonLine = styled(Skeleton)`
  border-radius: ${pxToEm(10)};
  transform: none;
  width: 100%;
  &:nth-of-type(odd) {
    width: 75%;
  }
  &:first-of-type {
    width: 50%;
  }
`;
