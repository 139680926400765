import styled from '@emotion/styled';
import MBDialog from 'components/Elements/MBDialog';
import palette from 'theme/palette';

export const FiltersDialog = styled(MBDialog)`
  .MuiDialogTitle-root {
    .MuiTypography-root {
      font-size: 20px;
    }
  }

  .MuiDialog-container .MuiPaper-root {
    height: inherit;
  }

  .MuiDialogContent-root {
    margin-top: 0;
    padding: 0px;
    margin-bottom: 0px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .MuiDialogActions-root {
    .MuiButtonBase-root {
      width: 100%;
    }
    border-top: 1px solid ${palette.grey[50]};
    margin: 0 -1.25em;
    padding-left: 1.25em;
    padding-right: 1.25em;
  }
`;
