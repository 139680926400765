import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { Button as MuiButton } from 'components/Buttons';
import palette from 'theme/palette';

export const MobileResultsOuterWrapper = styled(Grid)`
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  padding: 0;
  margin-bottom: 0.5em;
  width: 100%;
`;

export const Button = styled(MuiButton, {
  shouldForwardProp: (prop) => !['isLastInRow'].includes(String(prop)),
})<{ isLastInRow?: boolean }>`
  ${({ isLastInRow }) => isLastInRow && 'padding-right: 0;'};
  &.Mui-disabled {
    opacity: 0.6;
  }
`;

export const ButtonWithBadgeContainer = styled.div`
  position: relative;
  margin-right: auto;
`;

export const Badge = styled.div`
  position: absolute;
  top: -3px;
  right: 4px;
  background: ${palette.success.main};
  border-radius: 8px;
  font-size: 10px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  min-width: 16px;
  padding: 0 2px;
  color: ${palette.common.white};
  margin-left: auto;
  margin-right: 6px;
  border: 1px solid ${palette.common.white};
`;

export const FilterButton = styled(Button)`
  margin-right: 1em;
  flex-shrink: 0;
  border-radius: 8px;
  & > span:first-of-type {
    margin-right: 10px;
  }
`;
