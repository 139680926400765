import React, { FC, useMemo, useCallback, useEffect } from 'react';
import { AnalyticsEventLocation, Project } from '@mb/lib';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styles from './productGrid.module.scss';
import { sendAnalytics } from '../../../api/events';
import { ProductCartClickProps } from '../../../components/Cards/ResultCard/types';
import { getEventsMaterialProps } from '../../../helpers';
import useAddToBagWithValidations from '../../../hooks/useAddToBagWithValidations';
import { cartItemsSelector } from '../../../redux/selectors/commonSelector';
import { searchToggleSelector } from '../../../redux/selectors/filtersSelector';
import { projectsListSelector } from '../../../redux/selectors/projectsSelector';
import {
  searchOptionsSelector,
  selectedVariantsSelector,
  xRequestIdSelector,
} from '../../../redux/selectors/searchSelector';
import { removeCartItemFromQueue } from '../../../redux/sliceCreators/commonSlice';
import { setCreatedProject } from '../../../redux/sliceCreators/projectsSlice';
import { ProductSearchMaterial } from '../../../types';
import { handleAddToBoards } from '../../../utils/helpers';
import { ResultCardWrapper } from '../../MaterialsContainer/ResultCardWrapper';
import { getProductCardLabels } from '../utilities/getProductCardLabels';

const activeBagTime = 2500;

interface ProductGridProps {
  products: ProductSearchMaterial[];
  indexLocation: number;
  blockId: string;
  handleProductSelect: (product: ProductSearchMaterial) => void;
  selectedProducts?: ProductSearchMaterial[];
  handleProjectClick: (projectId: string) => void;
  onProjectSelect?: (projectId: string) => void;
  selectedProject?: string;
  onCreateProjectClick?: (projectId: string) => void;
}

export const ProductGrid: FC<ProductGridProps> = ({
  products,
  indexLocation,
  blockId,
  handleProductSelect,
  selectedProducts = [],
  handleProjectClick,
  onProjectSelect,
  selectedProject,
  onCreateProjectClick,
}) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const searchOptions = useSelector(searchOptionsSelector);
  const searchToggle = useSelector(searchToggleSelector);
  const projects = useSelector(projectsListSelector);
  const xRequestId = useSelector(xRequestIdSelector);
  const cartItems = useSelector(cartItemsSelector);
  const { handleAddToBag, loadingIds } = useAddToBagWithValidations();

  const handleContextMenuClick = () => {};
  const onProductInViewChange = () => {};
  const handleOnColorVariantsClick = () => {};
  const selectedVariants = useSelector(selectedVariantsSelector);
  const intl = useIntl();

  const cardStaticLabels = useMemo(() => {
    return getProductCardLabels(intl);
  }, [intl]);

  const cartItemsIds = useMemo(() => {
    return cartItems?.map((item: ProductSearchMaterial) => item.entityId) || [];
  }, [cartItems]);

  // If something was added to Cart, show isAdded icon for X secconds,
  // and then remove icon
  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const oldCartItems = cartItems?.filter(
        // @ts-expect-error // TODO: fix types
        (item) => now - item.time >= activeBagTime,
      );
      if (oldCartItems?.length > 0) {
        dispatch(removeCartItemFromQueue(oldCartItems));
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [cartItems]);

  const multiselectIds = useMemo(() => {
    const relevantList = selectedProducts;
    return (relevantList ?? []).map(
      (item: ProductSearchMaterial) => item.entityId,
    );
  }, [selectedProducts]);

  const structuredProjects = useMemo(() => {
    return [...projects].map((project) => ({
      id: project.project_id,
      label: project.project_title,
    }));
  }, [projects]);

  const onProjectCreate = useCallback((project: Project) => {
    dispatch(setCreatedProject(project));
  }, []);

  const handleMaterialSelect = (product: ProductSearchMaterial) => {
    handleProductSelect(product);
  };

  const handleAddToCart = ({
    product,
    productEventData,
  }: ProductCartClickProps) => {
    handleAddToBag({
      product,
      productEventData,
      isMobile,
      analyticsEventLocation: AnalyticsEventLocation.CATALOG,
      relatedProductData: undefined,
      shouldTriggerRecModal: undefined,
      additionalSplitProperties: {},
      listId: `contentblocks_catalog_${blockId}_${indexLocation}`,
      listName: `contentblocks catalog ${blockId} ${indexLocation}`,
      customSplitEvents: [],
    });
  };

  const handleBoards = async (
    product: ProductSearchMaterial,
    index: number,
  ) => {
    await handleAddToBoards({
      product,
      index,
      itemListId: `contentblocks_catalog_${blockId}_${indexLocation}`,
      itemListName: `contentblocks catalog ${blockId} ${indexLocation}`,
      analyticsEventLocation: AnalyticsEventLocation.CATALOG,
    });
  };

  const handleProductClick = async (
    product: ProductSearchMaterial,
    _index = 0,
    withAnalytics = true,
  ) => {
    //Analytics
    if (withAnalytics) {
      const analyticsProps = getEventsMaterialProps(product);
      await sendAnalytics(
        {
          id: [String(product?.entityId)],
          type: 'view',
          ...analyticsProps,
        },
        1200,
      );
    }
    dataLayer.push({
      event: 'view_item_list',
      list_id: `contentblocks_catalog_${blockId}_${indexLocation}`,
      list_name: `contentblocks catalog ${blockId} ${indexLocation}`,
      event_location: 'Catalog',
      item_list_id: `contentblocks_catalog_${blockId}_${indexLocation}`,
      item_list_name: `contentblocks catalog ${blockId} ${indexLocation}`,
    });

    if (!isMobile) {
      window.dispatchEvent(
        new CustomEvent('initMiniPDP', {
          detail: {
            id: product.entityId,
            product,
            isProduct: product.isProducts,
            isMaterial: !product.isProducts,
            clickedProductGridIndex: product.gridIndex,
            clickedProductRequestId: product.originRequestId || xRequestId,
            clickedProductAnalyticsClass: product.analyticsData?.analyticsClass,
            clickedProductAnalyticsImpressions:
              product.analyticsData?.impressions,
            dataLayerAnalytics: product.analyticsData?.dataLayerAnalytics,
          },
        }),
      );
    } else {
      window.location.href = product.url;
    }
  };

  const renderItem = (index: number) => {
    const rawMaterial = products[index];
    const material = {
      ...rawMaterial,
      gridIndex: index + 1,
    };

    const isMultiSelected =
      (multiselectIds ?? []).find(
        (id: string) =>
          id === material?.entityId ||
          id === material?.selectedColorFilterEntityId,
      ) != null;

    return (
      <ResultCardWrapper
        isVirtualized={false}
        key={material?.sku}
        isSearchPage={true}
        material={material}
        searchOptions={searchOptions}
        searchToggle={searchToggle}
        cardStaticLabels={cardStaticLabels}
        // this kicks off the select mode
        isMultiselect={selectedProducts.length > 0}
        isSelected={isMultiSelected}
        onMaterialSelect={handleMaterialSelect}
        multiselectIds={multiselectIds}
        imageSize={252}
        projects={structuredProjects}
        // @ts-expect-error // TODO: fix types
        selectedProject={selectedProject}
        cartItemsIds={cartItemsIds}
        onProductClick={handleProductClick}
        // @ts-expect-error // TODO: fix types
        onCreateProjectClick={onCreateProjectClick}
        // @ts-expect-error // TODO: fix types
        onProjectClick={handleProjectClick}
        shouldShowActionBar={false}
        isMobile={isMobile}
        onContextMenuClick={handleContextMenuClick}
        itemListId={`contentblocks_catalog_${blockId}_${indexLocation}`}
        itemListName={`contentblocks catalog ${blockId} ${indexLocation}`}
        // @ts-expect-error // TODO: fix types
        analyticsEventLocation={'Catalog'}
        onInViewChange={(itemInView) =>
          // @ts-expect-error // TODO: fix types
          onProductInViewChange(material, itemInView)
        }
        onProjectCreate={onProjectCreate}
        // @ts-expect-error // TODO: fix types
        onProjectSelect={onProjectSelect}
        userLang={'en'}
        // @ts-expect-error // TODO: fix types
        selectedVariants={selectedVariants}
        shouldShowVariants={false}
        additionalSplitProperties={{ srpCBATC: 'cb-atc' }}
        customSplitEvents={[]}
        onColorVariantsClick={handleOnColorVariantsClick}
        isSelectboxDisabled={false}
        isCompareLimitReached={false}
        showSelectOnHover={true}
        onAddToCart={handleAddToCart}
        onAddToFavorites={handleBoards}
        loadingCartItemIds={loadingIds}
      />
    );
  };

  return (
    <div className={styles.productGridWrapper}>
      <ul className={styles.productGrid}>
        {products.map((product, index) => (
          <li key={product.entityId} className={styles.productGridItem}>
            {renderItem(index)}
          </li>
        ))}
      </ul>
    </div>
  );
};
