import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { triggerPreviewFileModal } from '../../../helpers/triggerPreviewFileModal';
import useAddToBag from '../../../hooks/useAddToBag';
import useSwitchCurrentUserProject from '../../../hooks/useSwitchCurrentUserProject';
import {
  isProjectModalOpenSelector,
  projectModalEventLocationSelector,
} from '../../../redux/selectors/projectsSelector';
import {
  materialsInQueueSelector,
  productWithFilePreviewInQueueSelector,
} from '../../../redux/selectors/searchSelector';
import {
  setCreatedProject,
  setIsProjectModalOpen,
  setIsProjectArchived,
  setProjectModalEventLocation,
} from '../../../redux/sliceCreators/projectsSlice';
import {
  setMaterialsInQueue,
  setProductWithFilePreviewInQueue,
} from '../../../redux/sliceCreators/searchSlice';
import { goToLogin } from '../../../utils/helpers';
import { getUserAttributes } from '../../../utils/localstorage';

const ProjectModalListener = () => {
  const isModalOpen = useSelector(isProjectModalOpenSelector);
  const projectModalEventLocation = useSelector(
    projectModalEventLocationSelector,
  );
  const materialsInQueue = useSelector(materialsInQueueSelector);
  const productWithFilePreviewInQueue = useSelector(
    productWithFilePreviewInQueueSelector,
  );
  const dispatch = useDispatch();
  const { addToBag } = useAddToBag();
  const { setCurrentUserProject } = useSwitchCurrentUserProject();
  const userData = getUserAttributes();

  useEffect(() => {
    window.addEventListener('res-create-project', handleSaveProject);
    window.addEventListener('close-mb-modal', handleCloseModal);
    window.addEventListener('onArchivationSuccess', handleArchiveProject);
    window.addEventListener('onUnarchivationSuccess', handleUnarchiveProject);
    return () => {
      window.removeEventListener('res-create-project', handleSaveProject);
      window.removeEventListener('close-mb-modal', handleCloseModal);
      window.removeEventListener('onArchivationSuccess', handleArchiveProject);
      window.removeEventListener(
        'onUnarchivationSuccess',
        handleUnarchiveProject,
      );
    };
  }, [materialsInQueue, isModalOpen]);

  useEffect(() => {
    if (isModalOpen) {
      if (userData.signin_token == null) {
        goToLogin();
        return;
      }
      window.dispatchEvent(
        new CustomEvent('open-create-project-modal', {
          detail: { eventLocation: projectModalEventLocation },
        }),
      );
    }
  }, [isModalOpen]);

  const handleArchiveProject = (e: CustomEvent) => {
    const { detail: projectId } = e;
    if (projectId != null) {
      dispatch(setIsProjectArchived({ id: projectId, isArchived: true }));
    }
  };

  const handleUnarchiveProject = (e: CustomEvent) => {
    const { detail: projectId } = e;
    if (projectId != null) {
      dispatch(setIsProjectArchived({ id: projectId, isArchived: false }));
    }
  };

  const handleSaveProject = async (e: CustomEvent) => {
    const { detail } = e;
    if (detail?.project_id != null) {
      dispatch(setCreatedProject(detail));
      dispatch(setIsProjectModalOpen(false));
      if (materialsInQueue?.length > 0) {
        await addToBag({
          materials: materialsInQueue,
          projectId: detail?.project_id,
          project: detail,
        });
        dispatch(setMaterialsInQueue([]));
      } else {
        window.dispatchEvent(new CustomEvent('onProjectCreated'));
      }
      await setCurrentUserProject(
        detail?.project_id,
        materialsInQueue?.length === 0,
        detail,
      );
      if (productWithFilePreviewInQueue != undefined) {
        await triggerPreviewFileModal({
          product: productWithFilePreviewInQueue,
        });
        dispatch(setProductWithFilePreviewInQueue(undefined));
      }
    }
  };

  const handleCloseModal = () => {
    dispatch(setIsProjectModalOpen(false));
    dispatch(setProjectModalEventLocation(''));
    dispatch(setProductWithFilePreviewInQueue(undefined));
  };

  return null;
};

export default ProjectModalListener;
