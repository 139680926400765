import React, {
  Fragment,
  useMemo,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import TableBody from '@mui/material/TableBody';
import jsPDF from 'jspdf';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import 'jspdf-autotable';
import MBSwitch from 'components/Elements/MBSwitch/MBSwitch';
import { MBMenuItem } from 'ui-widgets';
import * as Styled from './CompareTableWrapper.styled';
import { sendAnalytics } from '../../api/events';
import { getCompareMaterials } from '../../api/materials';
import { ResultCard } from '../../components/Cards/ResultCard';
import { ProductCartClickProps } from '../../components/Cards/ResultCard/types';
import { ProjectMenuDropdown } from '../../components/ProjectMenuDropdown/ProjectMenuDropdown';
import { pushCompareAnalyticsEvent } from '../../helpers';
import useAddToBagWithValidations from '../../hooks/useAddToBagWithValidations';
import useCurrentProject from '../../hooks/useCurrentProject';
import { definedTranslation } from '../../i18n';
import {
  cartItemsSelector,
  compareApiKeysSelector,
  compareApiResultsSelector,
  comparisonItemsSelector,
} from '../../redux/selectors/commonSelector';
import {
  appFiltersSelector,
  searchToggleSelector,
} from '../../redux/selectors/filtersSelector';
import { projectsListSelector } from '../../redux/selectors/projectsSelector';
import {
  searchOptionsSelector,
  xSessionIdSelector,
} from '../../redux/selectors/searchSelector';
import {
  removeCartItemFromQueue,
  removeCompareApiItem,
  setCompareApiKeys,
  setCompareApiResults,
  setIsCompareTableShown,
} from '../../redux/sliceCreators/commonSlice';
import { setIsProjectModalOpen } from '../../redux/sliceCreators/projectsSlice';
import { ProductSearchMaterial } from '../../types';
import { getUserLang } from '@mb/lib/i18n';
import { checkStockAvailability } from '../../utils';
import {
  getCompareIdsFromUrl,
  getTaxonomyAnalyticsDataFromFilters,
  isBannersPage,
  pushSearchAnalyticsDataLayer,
  updateCompareUrlParam,
  removeCompareParam,
  generateCompareUrl,
  isSharedComparePage,
} from '../../utils/helpers';
import { activeBagTime } from '../MaterialsContainer/MaterialsContainer';
import { AnalyticsEventLocation } from '@mb/lib';
import MBDropdown from 'components/Elements/MBDropdown/MBDropdown';
import html2canvas from 'html2canvas';
import { getProductCardLabels } from '../SearchResultsContainer/utilities/getProductCardLabels';
import { SPLIT_EVENTS, SplitTrafficType } from '@mb/lib/splitio';

const userLang = getUserLang();
const isBannersSearch = isBannersPage();
const isSharedPage = isSharedComparePage();

const CompareTableWrapper: React.FC = () => {
  const [showOnlyDifference, setShowOnlyDifference] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [exportAnchorEl, setExportAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const isExportOpen = Boolean(exportAnchorEl);
  const [shareModalButtonText, setShareModalButtonText] = useState<
    string | undefined
  >(undefined);
  const [isCopySuccess, setIsCopySuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const pdfRef = useRef<HTMLDivElement>(null);

  const comparisonItems = useSelector(comparisonItemsSelector);
  const comparisonApiItems = useSelector(compareApiResultsSelector);
  const comparisonApiKeys = useSelector(compareApiKeysSelector);
  const searchOptions = useSelector(searchOptionsSelector);
  const searchToggle = useSelector(searchToggleSelector);
  const appFilters = useSelector(appFiltersSelector);
  const cartItems = useSelector(cartItemsSelector);
  const xSessionid = useSelector(xSessionIdSelector);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const { currentProject } = useCurrentProject();
  const { handleAddToBag, loadingIds } = useAddToBagWithValidations();
  const projects = useSelector(projectsListSelector);
  const dispatch = useDispatch();
  const intl = useIntl();

  const cardStaticLabels = useMemo(() => {
    return getProductCardLabels(intl);
  }, [intl]);

  const labels = useMemo(() => {
    return {
      title: intl?.formatMessage(definedTranslation.comparePage.title),
      backButton: intl?.formatMessage(definedTranslation.filters.backButton),
      shareButton: intl?.formatMessage(
        definedTranslation.comparePage.shareButton,
      ),
      exportButton: intl?.formatMessage(
        definedTranslation.comparePage.exportButton,
      ),
      projectPreLabel: intl?.formatMessage(
        definedTranslation.card.projectPreLabel,
      ),
      createProjectLabel: intl?.formatMessage(
        definedTranslation.card.createProjectButtonLabel,
      ),
      seeDifference: intl?.formatMessage(
        definedTranslation.comparePage.seeDifferenceLabel,
      ),
      loading: intl?.formatMessage(definedTranslation.loadingLabel),
      removeButton: intl?.formatMessage(
        definedTranslation.comparePage.removeButton,
      ),
      shareModalTitle: intl?.formatMessage(
        definedTranslation.comparePage.shareModalTitle,
      ),
      shareModalButton: intl?.formatMessage(
        definedTranslation.comparePage.shareModalButton,
      ),
      shareModalButtonError: intl?.formatMessage(
        definedTranslation.comparePage.shareModalButtonError,
      ),
      shareModalButtonSuccess: intl?.formatMessage(
        definedTranslation.comparePage.shareModalButtonSuccess,
      ),
    };
  }, [intl]);

  useEffect(() => {
    let ids = getCompareIdsFromUrl();
    if (comparisonItems?.length > 0) {
      ids = comparisonItems.map((item: ProductSearchMaterial) =>
        String(item.entityId),
      );
    }
    if (ids?.length > 0) {
      handleGetCompareData(ids);
    }
    window.scrollTo(0, 0);
    return () => {
      removeCompareParam();
    };
  }, []);

  useEffect(() => {
    if (comparisonApiItems?.length > 0) {
      const ids = comparisonApiItems?.map(
        (item: ProductSearchMaterial) => item.entityId,
      );
      updateCompareUrlParam(ids);
    }
  }, [comparisonApiItems]);

  // If something was added to Cart, show isAdded icon for X secconds,
  // and then remove icon
  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const oldCartItems = cartItems?.filter(
        // @ts-expect-error // TODO: fix types
        (item) => now - item.time >= activeBagTime,
      );
      if (oldCartItems?.length > 0) {
        dispatch(removeCartItemFromQueue(oldCartItems));
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [cartItems]);

  const { id: itemListId, title: itemListName } = useMemo(() => {
    return getTaxonomyAnalyticsDataFromFilters(appFilters);
  }, [appFilters]);

  const cartItemsIds = useMemo(() => {
    return cartItems?.map((item: ProductSearchMaterial) => item.entityId) || [];
  }, [cartItems]);

  const currentProjectData = {
    id: currentProject?.project_id,
    label: currentProject?.project_title ?? 'Select project..',
  };

  const structuredProjects = useMemo(() => {
    return [...projects].map((project) => ({
      id: project.project_id,
      label: project.project_title,
    }));
  }, [projects]);

  const handleGeneratePDF = () => {
    if (!pdfRef.current) return;
    handleCloseExportModal();
    setIsExportLoading(true);

    setTimeout(() => {
      html2canvas(pdfRef.current!, {
        scale: 2, // Higher resolution
        useCORS: true, // Allow cross-origin images if any
      })
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'a4');
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
          pdf.save('products-comparison.pdf');
        })
        .finally(() => {
          setIsExportLoading(false);
        });
    }, 500);
  };

  const handleGenerateCSV = () => {
    handleCloseExportModal();
    setIsExportLoading(true);

    const productNames = {
      value: comparisonApiItems?.map(
        (material: ProductSearchMaterial) =>
          material?.name || material?.originalMaterial?.name || '-',
      ),
      propertyName: 'Product Name',
    };
    const mappedComparisonApiKeys = comparisonApiKeys?.map(
      ([key, propertyName]) => {
        const value = comparisonApiItems?.map(
          (material: ProductSearchMaterial) => {
            const property = material?.originalMaterial?.[key];
            return Array.isArray(property)
              ? property.join(', ')
              : property || '-';
          },
        );

        return {
          value,
          propertyName,
        };
      },
    );
    const filteredComparisonApiKeys = mappedComparisonApiKeys.filter(
      (valuesObj) =>
        !valuesObj.value.every((val: string) => val === '-' || !val),
    );

    // Add product names the csv columns
    const valuesList = [productNames, ...filteredComparisonApiKeys];

    // Convert object array into a horizontal CSV structure
    const csvArray = valuesList.map(({ propertyName, value }) => [
      propertyName,
      ...value,
    ]);

    // Remove duplicate property names by keeping only the first occurrence
    const seen = new Set();
    const filteredCsvArray = csvArray.filter(([propertyName]) => {
      if (seen.has(propertyName)) {
        return false;
      }
      seen.add(propertyName);
      return true;
    });

    // Convert array to CSV string
    const csvContent = filteredCsvArray.map((row) => row.join(',')).join('\n');

    // Create a Blob (CSV file)
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    // Create a temporary link to download the file
    const link = document.createElement('a');
    link.href = url;
    link.download = 'compare_materials_download.csv';
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    setIsExportLoading(false);
  };

  const handleGetCompareData = async (ids: string[]) => {
    setIsLoading(true);
    const response = await getCompareMaterials(ids, xSessionid);
    if (response?.fields != null) {
      dispatch(setCompareApiKeys(response?.fields));
    }
    if (response?.results != null) {
      dispatch(setCompareApiResults(response?.results));
    }
    setIsLoading(false);
    if (response?.results != null) {
      const stockResults = await checkStockAvailability(response?.results);
      dispatch(setCompareApiResults(stockResults));
    }
  };

  const handleProjectCreate = useCallback(() => {
    dispatch(setIsProjectModalOpen(true));
  }, []);

  const handleProductClick = (product: ProductSearchMaterial) => {
    sendAnalytics(
      {
        id: [String(product?.entityId)],
        type: 'view',
        customRequestId: product?.originRequestId,
        impressions: product?.analyticsData?.impressions,
        class: product?.analyticsData?.analyticsClass,
      },
      1200,
    );
    window.open(product.url, '_blank');
  };

  const handleContextMenuClick = (product: ProductSearchMaterial) => {
    // Analytics
    window.splitFactory
      ?.client()
      ?.track(SplitTrafficType.USER, SPLIT_EVENTS.Open_PDP, undefined, {
        pdpOpenRef: 'pc right click',
      });
    pushSearchAnalyticsDataLayer({
      payload: [product],
      listId: product?.isDigital ? 'digital_sampling' : itemListId,
      listTitle: product?.isDigital ? 'Digital sampling' : itemListName,
      eventName: 'select_item',
      eventLocation: isBannersSearch ? 'Collection' : 'Catalog',
    });
  };

  const toggleDifference = () => {
    setShowOnlyDifference((prev) => !prev);
  };

  const handleCloseTable = () => {
    removeCompareParam();
    dispatch(setIsCompareTableShown(false));
    //dispatch(resetComparisonFeature());
  };

  const handleRemoveItem = (material: ProductSearchMaterial) => {
    dispatch(removeCompareApiItem(material.entityId));
  };

  const handleCloseModal = () => {
    setIsCopySuccess(false);
    setIsShareModalOpen(false);
  };

  const handleOpenShareModal = () => {
    setIsShareModalOpen(true);
  };

  const handleOpenExportModal = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    // Close if already opened
    if (isExportOpen) {
      handleCloseExportModal();
      return;
    }

    setExportAnchorEl(event.currentTarget);
  };

  const handleAddToCart = ({
    product,
    productEventData,
  }: ProductCartClickProps) => {
    handleAddToBag({
      product,
      productEventData,
      isMobile,
      analyticsEventLocation: isBannersSearch
        ? AnalyticsEventLocation.COLLECTION
        : AnalyticsEventLocation.CATALOG,
      relatedProductData: undefined,
      shouldTriggerRecModal: undefined,
      additionalSplitProperties: {},
      listId: itemListId,
      listName: itemListName,
      customSplitEvents: [],
    });
  };

  const handleCloseExportModal = () => {
    setExportAnchorEl(null);
  };

  const handleCopyLink = () => {
    if (comparisonApiItems?.length > 0) {
      const ids = comparisonApiItems?.map(
        (item: ProductSearchMaterial) => item.entityId,
      );
      const newUrl = generateCompareUrl(ids);
      navigator.clipboard
        ?.writeText(newUrl)
        .then(() => {
          setIsCopySuccess(true);
          setShareModalButtonText(labels?.shareModalButtonSuccess);
          setTimeout(() => {
            setIsCopySuccess(false);
            setShareModalButtonText(undefined);
          }, 2000);
        })
        .catch(() => {
          setShareModalButtonText(labels?.shareModalButtonError);
          setTimeout(() => {
            setShareModalButtonText(undefined);
          }, 2000);
        });
    }
    pushCompareAnalyticsEvent({
      eventName: 'comparison_tool_comparison_table_share',
      projectData: {
        name: String(currentProject?.project_title),
        id: String(currentProject?.project_id),
      },
      additionalProps: {
        event_items_selected: comparisonApiItems?.length || 0,
        location: 'comparison_tool_comparison_table',
        event_location: 'Comparison tool comparison table',
        event_item_list_id: 'comparison_tool_comparison_table',
        event_item_list_name: 'Comparison tool comparison table',
      },
    });
  };

  const renderItemValue = (material: ProductSearchMaterial, key: string) => {
    const property = material?.originalMaterial?.[key];
    const value = Array.isArray(property)
      ? property.join(', ')
      : property || '-';
    return (
      <Styled.StyledTableCell key={material.entityId}>
        {value}
      </Styled.StyledTableCell>
    );
  };

  const renderRow = ([key, value]: any) => {
    const valuesList = comparisonApiItems?.map(
      (material: ProductSearchMaterial) => {
        const property = material?.originalMaterial?.[key];
        return Array.isArray(property) ? property.join(', ') : property || '-';
      },
    );
    const isSameValues = valuesList.every(
      (item: string) => item === valuesList[0],
    );
    const isAllEmpty = valuesList.every((item: string) => item === '-');

    if ((showOnlyDifference && isSameValues) || isAllEmpty) {
      return <Fragment />;
    }
    return (
      <Styled.StyledTableRow key={key}>
        <Styled.StyledTableCell>
          <strong>{value}</strong>
        </Styled.StyledTableCell>
        {comparisonApiItems?.map((material: ProductSearchMaterial) =>
          renderItemValue(material, key),
        )}
      </Styled.StyledTableRow>
    );
  };

  const isDeleteItemAllowed = comparisonApiItems?.length > 2 && !isSharedPage;

  return (
    <Fragment>
      <Styled.TopbarWrapper>
        <Styled.BackButtonWrapper>
          <Styled.BackButton
            variant="text"
            size="medium"
            color="primary"
            startIcon="back"
            onClick={handleCloseTable}
          >
            {labels.backButton}
          </Styled.BackButton>
        </Styled.BackButtonWrapper>
        <Styled.Headline component="h1" variant="h3">
          {labels.title}
        </Styled.Headline>
        <Styled.RightSideBar>
          <Styled.ProjectsSelectorWrapper>
            <Styled.PreLabel variant="body" component="span">
              {labels.projectPreLabel}
            </Styled.PreLabel>
            <ProjectMenuDropdown
              entityId=""
              createProjectButtonLabel={labels.createProjectLabel}
            />
          </Styled.ProjectsSelectorWrapper>
          <Styled.ShareButton
            variant="contained"
            color="secondary"
            startIcon="share"
            onClick={handleOpenShareModal}
          >
            {labels.shareButton}
          </Styled.ShareButton>

          <Styled.ExportButton
            variant="contained"
            color="primary"
            startIcon={isExportLoading ? undefined : 'download'}
            onClick={handleOpenExportModal}
            disabled={isExportLoading}
          >
            {isExportLoading ? 'Exporting' : labels.exportButton}
          </Styled.ExportButton>
          <MBDropdown
            id="export-menu"
            anchorEl={exportAnchorEl}
            open={isExportOpen}
            onClose={handleCloseExportModal}
          >
            <MBMenuItem onClick={handleGeneratePDF}>Export as a PDF</MBMenuItem>
            <MBMenuItem onClick={handleGenerateCSV}>Export as a CSV</MBMenuItem>
          </MBDropdown>
        </Styled.RightSideBar>
      </Styled.TopbarWrapper>
      <Styled.SwitchWrapper>
        <MBSwitch checked={showOnlyDifference} onChange={toggleDifference} />
        <Styled.DifferenceLabel
          component="span"
          variant="body"
          onClick={toggleDifference}
        >
          {labels.seeDifference}
        </Styled.DifferenceLabel>
      </Styled.SwitchWrapper>
      <Styled.PdfWrapper ref={pdfRef}>
        {isLoading ? (
          <div>{labels.loading}...</div>
        ) : (
          <Styled.StyledTable sx={{ minWidth: 700, tableLayout: 'fixed' }}>
            <TableBody>
              <Styled.StyledTableRow>
                <Styled.StyledTableCell></Styled.StyledTableCell>
                {comparisonApiItems?.map((material: ProductSearchMaterial) => (
                  <Styled.StyledTableCell key={material.entityId}>
                    <ResultCard
                      shouldHideActionButton={isExportLoading}
                      shouldHideFavoritesButton={!isDeleteItemAllowed}
                      customTopRightButtonCallback={() =>
                        handleRemoveItem(material)
                      }
                      customTopRightButtonLabel={labels.removeButton}
                      shouldShowHoverSelect={false}
                      showOnHoverOnlyOvernight
                      isOutlined={false}
                      key={material?.sku}
                      isSearchPage
                      material={material}
                      shouldShowVariants={false}
                      searchOptions={searchOptions}
                      searchToggle={searchToggle}
                      isMultiselect={false}
                      isSelected={false}
                      multiselectIds={[]}
                      imageSize={252}
                      projects={structuredProjects}
                      // @ts-expect-error // TODO: fix types
                      selectedProject={currentProjectData}
                      cartItemsIds={cartItemsIds}
                      onProductClick={handleProductClick}
                      isMobile={isMobile}
                      onContextMenuClick={handleContextMenuClick}
                      onCreateProjectClick={handleProjectCreate}
                      itemListId={itemListId}
                      itemListName={itemListName}
                      analyticsEventLocation={
                        isBannersSearch
                          ? AnalyticsEventLocation.COLLECTION
                          : AnalyticsEventLocation.CATALOG
                      }
                      userLang={userLang}
                      additionalSplitProperties={{}}
                      customSplitEvents={[]}
                      cardStaticLabels={cardStaticLabels}
                      onAddToCart={handleAddToCart}
                      loadingCartItemIds={loadingIds}
                    />
                  </Styled.StyledTableCell>
                ))}
              </Styled.StyledTableRow>
              {comparisonApiKeys?.map(([key, value]) =>
                renderRow([key, value]),
              )}
            </TableBody>
          </Styled.StyledTable>
        )}
      </Styled.PdfWrapper>
      <Styled.ShareModal
        dialogWidth={480}
        open={isShareModalOpen}
        onClose={handleCloseModal}
        mobileFullscreen={true}
        showCloseIcon={true}
        title={labels.shareModalTitle}
      >
        <Styled.ModalWrapper>
          <Styled.ShareButton
            fullWidth
            variant="contained"
            color="primary"
            sx={{ height: '52px !important' }}
            startIcon={isCopySuccess ? 'check' : 'link'}
            onClick={handleCopyLink}
          >
            {shareModalButtonText || labels.shareModalButton}
          </Styled.ShareButton>
        </Styled.ModalWrapper>
      </Styled.ShareModal>
    </Fragment>
  );
};

export { CompareTableWrapper };
