import React from 'react';

const SortingIcon = () => {
  return (
    <svg
      width="19"
      height="17"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6245 4.23018C17.9329 4.50663 18.4071 4.48068 18.6835 4.17222C18.96 3.86375 18.934 3.38959 18.6256 3.11314L15.3656 0.19148C15.08 -0.0644119 14.6475 -0.0637501 14.3627 0.193014L11.1227 3.11468C10.8151 3.39207 10.7906 3.86631 11.068 4.17392C11.3454 4.48154 11.8197 4.50604 12.1273 4.22865L14.115 2.43621V15.5833C14.115 15.9975 14.4508 16.3333 14.865 16.3333C15.2792 16.3333 15.615 15.9975 15.615 15.5833V2.42929L17.6245 4.23018ZM2.12556 12.1032C1.8171 11.8267 1.34294 11.8527 1.06649 12.1612C0.790038 12.4696 0.81599 12.9438 1.12445 13.2202L4.38445 16.1419C4.66998 16.3978 5.10253 16.3971 5.38727 16.1404L8.62727 13.2187C8.93488 12.9413 8.95938 12.4671 8.68199 12.1594C8.4046 11.8518 7.93036 11.8273 7.62275 12.1047L5.63501 13.8972V0.750038C5.63501 0.335825 5.29922 3.81496e-05 4.88501 3.81496e-05C4.47079 3.81496e-05 4.13501 0.335825 4.13501 0.750038V13.9041L2.12556 12.1032Z"
        fill="#4D4E58"
      />
    </svg>
  );
};

export default SortingIcon;
