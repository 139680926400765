const arrayToObject = (arr: number[]): { [key: number]: number } => {
  return arr.reduce((acc, curr, index) => {
    acc[curr] = index;
    return acc;
  }, {} as { [key: number]: number });
};

export const getPositionMap = (
  isMobile: boolean,
  resultsLength: number,
  contentBlocksLength: number,
): Record<number, number> => {
  const desktopPositionMap = [2, 7, 23, 28, 39, 49, 60, 65];
  const mobilePositionMap = [3, 11, 15, 27, 35, 47, 65, 71];

  const positionMap = isMobile ? mobilePositionMap : desktopPositionMap;
  const trimmedPositionMap = positionMap.slice(0, contentBlocksLength);
  let calculatedArray = [];

  if (resultsLength > Math.max(...trimmedPositionMap)) {
    calculatedArray = trimmedPositionMap;
  } else {
    calculatedArray = [
      Math.max(...trimmedPositionMap.filter((num) => num < resultsLength)),
    ];
  }

  return arrayToObject(calculatedArray);
};
