type SearchConfigType = {
  siteId: string;
  siteUrl: string;
  context: string;
};

export const getSearchConfig = (modifier?: string): SearchConfigType => {
  if (modifier) {
    if (SEARCH_CONFIG?.product) {
      const newConfig = { ...SEARCH_CONFIG?.product };

      newConfig.siteUrl = newConfig.siteUrl.replace('v2', modifier);

      return newConfig;
    }

    return {
      siteId: 'uat2-materialbank_product',
      siteUrl: `https://searchuat.materialbank.com/${modifier}`,
      context: 'catalog',
    };
  } else {
    return (
      SEARCH_CONFIG?.product || {
        siteId: 'materialbank_product',
        siteUrl: 'https://search.materialbank.com/v2',
        context: 'catalog',
      }
    );
  }
};
