// @ts-nocheck TODO: fix types for the entire file

import styled from '@emotion/styled';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { IconButton } from 'components/Buttons';
import MBTextField from 'components/Forms/MBTextField/MBTextField';
import { pxToEm } from 'theme';
import palette from 'theme/palette';
import { Typography } from '../../Typography';

interface AccordionProps {
  maxwidth?: number;
  maxheight?: number;
  showScrollbar?: boolean;
}

export const Accordion = styled(MuiAccordion)<AccordionProps>`
  border: 0;
  background: transparent;
  box-shadow: none;
  width: 100%;
  max-width: ${(props) =>
    props.maxwidth != null ? props.maxwidth + 'px' : 'none'};
  margin: 0 !important;
  &:before,
  &:after {
    display: none;
  }
  &.Mui-expanded {
    border-bottom: 1px solid ${palette.grey[50]};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .MuiOutlinedInput-notchedOutline {
    border-radius: 10px;
    border-color: ${palette.grey[400]} !important;
  }
  .MuiInputBase-input {
    font-size: 16px;
    line-height: 20px;
    height: 40px !important;
    padding: 0 !important;
  }
`;

export const AccordionTitle = styled(Typography)`
  display: flex;
  max-width: ${pxToEm(210)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  svg {
    margin-left: 4px;
    width: 18px;
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  border: 0;
  width: 100%;
  font-weight: 400;
  padding: ${pxToEm(6)} 0;
  font-size: 16px;
  line-height: 24px;
  color: ${palette.primary.main};
  margin: 0 !important;
  min-height: 36px;
  &.Mui-expanded {
    min-height: 36px;
  }
  & .MuiAccordionSummary-content {
    margin: 0 !important;
    align-content: center;
    align-items: center;
  }

  &:hover {
    & .MuiAccordionSummary-expandIconWrapper {
      color: ${palette.primary.main};
    }
  }
` as any;

export const AccordionDetails = styled(MuiAccordionDetails, {
  shouldForwardProp: (prop) =>
    !['showScrollbar', 'maxheight', 'maxwidth'].includes(String(prop)),
})<AccordionProps>`
  border: 0;
  padding: 0;
  padding: ${pxToEm(16)} ${pxToEm(4)} 0 0;
  ${({ showScrollbar }) =>
    showScrollbar
      ? `
    scrollbar-color: ${palette.grey[600]} ${palette.grey[400]};
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: ${palette.grey[400]};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.grey[600]};
      border-radius: 4px;
      border: 3px none ${palette.common.white};
    }

  `
      : `
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  `}
  ${(props) =>
    props.maxheight
      ? `max-height: ${props.maxheight}px;
      overflow: auto;
    `
      : 'max-height: none'};
`;

export const Badge = styled.div`
  background: ${palette.success.main};
  border-radius: 8px;
  font-size: 10px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  min-width: 16px;
  padding: 1px 2px 0 2px;
  color: ${palette.common.white};
  margin-left: auto;
  margin-right: 6px;
`;

export const TextField = styled(MBTextField)`
  *::-webkit-input-placeholder {
    transition: none;
  }
  *::-moz-placeholder {
    transition: none;
  }
  *::placeholder {
    transition: none;
  }

  .MuiInputBase-root {
    position: relative;
    background: transparent;
    padding-left: 10px;
    width: 103%;
    margin-left: -3%;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 18px;
      height: 100%;
      z-index: -1;
      pointer-events: none;
      transition: width 0.2s ease-in-out;
      width: 100%;
    }
    &:hover {
      &:before {
        background: ${palette.grey[200]};
      }
    }
    &:not(.Mui-focused) {
      *::-webkit-input-placeholder {
        color: ${({ theme: { palette } }) => palette.primary.main} !important;
        opacity: 1 !important;
      }
      *::-moz-placeholder {
        color: ${({ theme: { palette } }) => palette.primary.main} !important;
        opacity: 1 !important;
      }
      *::placeholder {
        color: ${({ theme: { palette } }) => palette.primary.main}!important;
        opacity: 1 !important;
      }
    }
    &.Mui-focused {
      &:before {
        width: 100%;
        background: ${palette.grey[200]};
      }
  }
  fieldset {
    border: 0;
    background: transparent;
  }
`;

export const BackButton = styled(IconButton)`
  font-size: 1em !important;
  padding: 0;
  margin-right: 0.5em;
`;
