import { dispatchSetCurrentProjectId } from '@material-bank/projects-utils';
import {
  Project,
  setProjectDataToMageStorage,
  updateSelectProjectTimestamp,
} from '@mb/lib';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { config } from '../config';
import { useAPICallStateHandler } from './customHooks';
import { definedTranslation } from '../i18n';
import {
  projectPhasesSelector,
  projectsListSelector,
} from '../redux/selectors/projectsSelector';
import { setSnackbarInfo } from '../redux/sliceCreators/commonSlice';
import { setCurrentProjectId } from '../redux/sliceCreators/projectsSlice';
import { IProject, SnackbarStatusEnum } from '../types';
import { getUserAttributes } from '../utils/localstorage';

const postCurrentUserProject = async (projectId: number) => {
  const userData = getUserAttributes();

  const response = await fetch(config.endpoints.postCurrentUserProject, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${userData.signin_token}`,
    },
    body: JSON.stringify({
      project_id: projectId,
    }),
  });

  return response.json();
};

export const useSwitchCurrentUserProject = () => {
  const dispatch = useDispatch();
  const projects = useSelector(projectsListSelector);
  const projectPhases = useSelector(projectPhasesSelector);
  const { state, setState } = useAPICallStateHandler();
  const intl = useIntl();

  const labels = {
    success: intl?.formatMessage(
      definedTranslation.projectMessages.projectUpdated,
    ),
    error: intl?.formatMessage(
      definedTranslation.projectMessages.errorProjectSet,
    ),
  };

  const setCurrentUserProject = async (
    projectId: number,
    showSnackbar = true,
    project: IProject,
  ) => {
    try {
      setState({ isLoading: true });
      await postCurrentUserProject(projectId);

      dispatch(setCurrentProjectId(projectId));
      updateSelectProjectTimestamp();

      // Update project data in Mage-cache-storage
      const currentProject =
        project ||
        projects?.find(
          (project: Project) => Number(project.project_id) === projectId,
        );
      if (currentProject != undefined) {
        setProjectDataToMageStorage(currentProject as Project, projectPhases);
      }

      /**
       * dispatch an event to update the current project;
       * temporary solution for syncing the current project id until we implement the common projects cache
       * TODO: remove this once we have the common projects cache
       */
      dispatchSetCurrentProjectId(projectId.toString());

      if (showSnackbar) {
        dispatch(
          setSnackbarInfo({
            isOpen: true,
            message: labels.success,
            status: SnackbarStatusEnum.SUCCESS,
          }),
        );
      }
      setState({ isLoading: false });
    } catch (error) {
      console.error('cannot set current project id', error);
      dispatch(
        setSnackbarInfo({
          isOpen: true,
          message: error.message ?? labels.error,
          status: SnackbarStatusEnum.DANGER,
        }),
      );
      setState({ isLoading: false, error });
    }
  };

  return { ...state, setCurrentUserProject };
};

export default useSwitchCurrentUserProject;
