import React, { FC, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { Placeholder } from './Placeholder';
import * as Styled from './ResultCardWrapper.styles';

import { ResultCard } from '../../../components/Cards/ResultCard';
import { ResultCardProps } from '../../../components/Cards/ResultCard/types';

interface ResultCardWrapperProps extends ResultCardProps {
  onInViewChange?(inView: boolean): void;
  isVirtualized?: boolean;
}

export const ResultCardWrapper: FC<ResultCardWrapperProps> = (props) => {
  const { material, onInViewChange, isVirtualized, ...resultCardProps } = props;

  const { ref: inViewRef, inView } = useInView({
    threshold: 0,
    onChange: onInViewChange,
    triggerOnce: true,
  });

  useEffect(() => {
    return () => {
      onInViewChange?.(false);
    };
  }, []);

  if (!inView && !isVirtualized) {
    return (
      <div ref={inViewRef} style={{ width: '100%' }}>
        <Placeholder />
      </div>
    );
  }

  return (
    <Styled.CardWrapper
      ref={inViewRef}
      id={`card-${material?.sku}`}
      data-id={material.entityId}
    >
      <ResultCard material={material} {...resultCardProps} />
    </Styled.CardWrapper>
  );
};
