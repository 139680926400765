import React, { useEffect, useState } from 'react';
import { MBSnackbar } from 'components/components/MBSnackbar';
import { useMediaQuery } from 'react-responsive';
import { getUserAttributes } from '../../utils/localstorage';

const BrandSnackbar = () => {
  const [isActive, setIsActive] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  useEffect(() => {
    window.addEventListener('show-brand-snackbar', handleShow);
    return () => {
      window.removeEventListener('show-brand-snackbar', handleShow);
    };
  }, [isMobile, isActive]);

  const handleShow = () => {
    const userData = getUserAttributes();
    const shouldShowSnackbar =
      userData?.groupId === 1 || userData?.groupId === 2;
    if (shouldShowSnackbar && !isActive && !isMobile) {
      setIsActive(true);
    }
  };

  const handleClose = () => {
    setIsActive(false);
  };

  return (
    <MBSnackbar
      id="product_catalog_save_package_message_box"
      open={isActive}
      startIconSize={0.92}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={handleClose}
      startIcon="carbon-neutral"
      // @ts-expect-error // TODO: fix types
      status="success"
      message="Add a brand = Save a package"
    />
  );
};

export default BrandSnackbar;
