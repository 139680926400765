// @ts-nocheck TODO: fix types for the entire file

import styled from '@emotion/styled';
import { Grid, MenuItem as MuiMenuItem } from '@mui/material';
import { Button as MuiButton } from 'components/Buttons';
import { IconButton as ThemeIconButton } from 'components/Buttons/IconButton';
import { CustomIcon } from 'components/CustomIcon';
import MBTextField from 'components/Forms/MBTextField/MBTextField';

export const ResultsOuterWrapper = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  padding: 0 0.5em 0 0.75em;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 4px;
    width: 1px;
    height: 20px;
    background: #f2f2f2;
  }
`;

interface ButtonProps {
  width?: number;
  isActive?: boolean;
  isWithValue?: boolean;
}

export const Button = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'isFilterShown',
})<ButtonProps>`
  background: ${({ isActive, theme }) =>
    isActive ? theme.palette.secondary.main : 'transparent'};
  width: ${({ width }) => width || 'auto'};
  padding-inline: 20px 8px !important;

  & .MuiButton-endIcon {
    pointer-events: none;
    margin-left: 0;
  }
  b:not(:empty) {
    display: inline-flex;
    margin-left: 0.25em;
  }
  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
  &:focus,
  &:active,
  &:hover {
    box-shadow: none !important;
  }
`;

export const MenuItem = styled(MuiMenuItem)`
  /* Hack to reduce !important usage */
  &.MuiMenuItem-root.MuiMenuItem-root.MuiMenuItem-root {
    display: flex;
    padding: 4px 8px;
    border-radius: 4px;
    min-width: 200px;
    &:hover {
      background: ${({ theme }) => theme.palette.secondary.main};
    }
  }
`;

export const MenuIconContainer = styled.div`
  min-width: 32px;
  svg {
    width: 1.25em;
  }
`;

export const ColorWheelWrapper = styled.div`
  display: flex;
  align-items: center;
  .colorwheelIcon {
    display: flex;
    width: 34px;
    height: 34px;
    margin-left: 2px;
    border-radius: 50px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
`;

export const CloseRainbowButton = styled(ThemeIconButton)`
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  &:hover {
    background: ${({ theme }) => theme.palette.grey[200]} !important;
    color: ${({ theme }) => theme.palette.primary.main} !important;
  }
`;

export const IconButton = styled(ThemeIconButton)`
  margin: 0 12px 0 0;
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  font-size: 0.65em;
  background: ${({ theme }) => theme.palette.secondary.main} !important;
`;

export const SearchButton = styled(ThemeIconButton, {
  shouldForwardProp: (prop) => !['isWithValue'].includes(String(prop)),
})<ButtonProps>`
  margin: 0 12px 0 0;
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  font-size: 14px;
  background: ${({ theme }) => theme.palette.secondary.main} !important;
  ${({ isWithValue, theme }) =>
    isWithValue &&
    `
    &:after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 3;
      background: ${theme.palette.yellow.main} !important;
    }
  `};
`;

export const SearchIcon = styled(CustomIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.grey.A400};
  margin-right: 12px;
`;

export const SearchField = styled(MBTextField)`
  width: 220px;
  display: flex;
  margin-right: 12px;
  font-size: 14px;
  .MuiOutlinedInput-root {
    border-radius: 24px;
    background-color: ${({ theme }) => theme.palette.grey[50]};
    border-color: ${({ theme }) => theme.palette.grey[50]};
    height: 36px;
    padding-left: 0;

    &:hover,
    &.Mui-focused {
      .MuiInputAdornment-root:first-of-type svg {
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }

    & fieldset {
      border: none !important;
    }
  }
`;
