import { adjustIndex } from './adjustedIndex';
import { formatNumberWithThreshold } from './formatNumberWithThreshold';

export const calculateProductRow = (
  index: number,
  cbCount: Record<number, number>,
  mobile: boolean,
): string => {
  const itemsPerRow = mobile ? 2 : 5;
  const cbKeys = Object.keys(cbCount);
  const calculatedRow = Math.ceil(
    adjustIndex(index, cbKeys.map(Number)) / itemsPerRow,
  );
  return `${mobile ? 'm' : 'd'}-${formatNumberWithThreshold(
    calculatedRow,
    mobile ? 7 : 5,
  )}`;
};
