import React from 'react';
import { createRoot } from 'react-dom/client';
//Components
import ProductSearchContainer from '../../../pages/ProductSearchContainer/ProductSearchContainer';

const container = document.getElementById('searchContent');
if (container !== null) {
  createRoot(container).render(<ProductSearchContainer />);
  window.dispatchEvent(new CustomEvent('prepare-open-products-menu-mobile'));
}
