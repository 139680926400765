import React, { FC } from 'react';
import { config } from '../../config';
import * as Styled from '../../containers/MaterialsContainer/MaterialsContainer.styles';
import { Placeholder } from '../../containers/MaterialsContainer/ResultCardWrapper/Placeholder';
import { gridColumnNumber } from '../../redux/sliceCreators/searchSlice';

interface Props {
  itemsNumber?: number;
  columnSize?: number;
}

const GridSkeleton: FC<Props> = ({
  itemsNumber = config.search.resultsPerPage,
}) => {
  return (
    <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
      {Array.from({ length: itemsNumber }).map((_, index) => {
        return (
          <Styled.ResultItem
            item
            container
            key={index}
            columnSize={gridColumnNumber}
          >
            <Placeholder />
          </Styled.ResultItem>
        );
      })}
    </div>
  );
};

export default GridSkeleton;
