import React, { FC } from 'react';

interface ButtonOrLinkProps
  extends React.HTMLProps<HTMLButtonElement | HTMLAnchorElement> {
  children: React.ReactNode;
  linkValue: string | null;
}

export const ButtonOrLinkComponent: FC<ButtonOrLinkProps> = ({
  linkValue,
  children,
  ...props
}) => {
  if (linkValue) {
    return (
      // @ts-expect-error // TODO: fix types
      <a target="_blank" rel="noopener noreferrer" href={linkValue} {...props}>
        {children}
      </a>
    );
  }
  return (
    // @ts-expect-error // TODO: fix types
    <button type="button" {...props}>
      {children}
    </button>
  );
};
