import { getAnalyticsItemType } from '@mb/lib';
import { getAnalyticsItemCategoriesByTaxonomy } from './analytics';
import { ProductSearchMaterial } from '../../types';

export const mapMaterialsForAnalytics = (
  data: ProductSearchMaterial[] = [],
  // @ts-expect-error // TODO: fix types
  currentColor,
  // @ts-expect-error // TODO: fix types
  itemListId,
  // @ts-expect-error // TODO: fix types
  itemListName,
) => {
  const configLang =
    typeof ENVIRONMENT_REGION !== 'undefined' ? ENVIRONMENT_REGION : null;
  const currency = configLang === 'EU' ? 'EUR' : 'USD';

  const gaType = data?.map((item, index) => {
    const colorway = item.ssFacetColorFilter?.find(
      (color) => color.image_url === item.thumbnailImageUrl?.split('?')?.[0],
    );
    let itemColor = null;
    if (colorway != null) {
      itemColor = colorway?.color_name;
    }
    const finishesColor = item?.configData?.color;
    const isDigital =
      // @ts-expect-error // TODO: fix types
      item?.isDigital || item?.is_digital || item?.originalMaterial?.isDigital;

    let itemId;
    try {
      itemId = item?.configurations?.[0]?.items?.[0]?.sku ?? item.sku;
    } catch (e) {
      console.log(e.message);
    }

    const finalObj = {
      item_id: itemId,
      item_name: item.name,
      item_brand: item.manufacturer,
      item_type: getAnalyticsItemType(item),
      ...getAnalyticsItemCategoriesByTaxonomy(item.taxonomy ?? []),
      index: item.gridIndex || index + 1,
      price: 0,
      quantity: 1,
      item_list_id: isDigital ? 'digital_sampling' : itemListId,
      item_list_name: isDigital ? 'digital sampling' : itemListName,
      item_variant: finishesColor || currentColor || itemColor || '',
    };

    if (item.associatedItem != null) {
      // @ts-expect-error // TODO: fix types
      finalObj['associated_item'] = item.associatedItem || '';
    }

    return finalObj;
  });

  return { gaType, currency };
};
