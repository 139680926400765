// @ts-nocheck TODO: fix types for the entire file

import styled from '@emotion/styled';
import { Button } from 'components/Buttons/Button';
import palette from 'theme/palette';

export const ButtonWrapper = styled.div`
  margin-left: 6px;
`;
export const ToogleButton = styled(Button)`
  border-color: ${palette.secondary.light};
  background: none;
  gap: 8px;
  min-width: 102px;
  padding: 6px 12px;
  &:hover,
  &:focus,
  &:active {
    border-color: ${palette.secondary.light};
    background: none;
  }
`;

export const NewLabel = styled.span`
  font-weight: 400;
  border-radius: 24px;
  background-color: ${palette.lime.main};
  color: ${palette.primary.main};
  font-size: 10px;
  padding: 0 8px;
  line-height: 16px;
  border: 2px solid #fff;
  position: absolute;
  top: -11px;
  right: 0;
`;
