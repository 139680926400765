import React, { useMemo } from 'react';
import DownloadModal from 'components/Modals/DownloadModal';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { exportToCSV, exportToPDF } from '../../../api/export';
import { definedTranslation } from '../../../i18n';
import { isExportModalOpenSelector } from '../../../redux/selectors/commonSelector';
import { selectedMaterialsSelector } from '../../../redux/selectors/filtersSelector';
import { setIsExportModalOpen } from '../../../redux/sliceCreators/commonSlice';

const ExportModal: React.FC = () => {
  const isModalOpen = useSelector(isExportModalOpenSelector);
  const selectedMaterials = useSelector(selectedMaterialsSelector);
  const dispatch = useDispatch();
  const intl = useIntl();

  const labels = {
    title: intl?.formatMessage(definedTranslation.downloadModal.title),
    selectedLabel: intl?.formatMessage(
      definedTranslation.downloadModal.selectedLabel,
    ),
    materialsListButtonLabel: intl?.formatMessage(
      definedTranslation.downloadModal.infoButton,
    ),
    relatedDocumentsButtonLabel: intl?.formatMessage(
      definedTranslation.downloadModal.docsButton,
    ),
  };

  const selectedPreviews = useMemo(
    () =>
      // @ts-expect-error // TODO: fix types
      selectedMaterials.map((item) => ({
        title: item.name,
        imageUrl: item.thumbnailImageUrl,
      })),
    [selectedMaterials],
  );

  const selectedIds = useMemo(
    // @ts-expect-error // TODO: fix types
    () => selectedMaterials.map((item) => item.entityId),
    [selectedMaterials],
  );

  const handleExportToCSV = async () => {
    try {
      await dispatch(exportToCSV(selectedIds));
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportToPDF = async () => {
    try {
      await dispatch(exportToPDF(selectedIds));
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    dispatch(setIsExportModalOpen(false));
  };

  return (
    isModalOpen && (
      // @ts-expect-error // TODO: fix types
      <DownloadModal
        open
        onExportToCSV={handleExportToCSV}
        onExportToPDF={handleExportToPDF}
        onClose={handleClose}
        previews={selectedPreviews}
        {...labels}
      />
    )
  );
};

export default ExportModal;
