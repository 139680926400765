import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CompareList } from '../../components/CompareList';
import { isComparisonActiveSelector } from '../../redux/selectors/commonSelector';
import { multiSelectToggleSelector } from '../../redux/selectors/filtersSelector';
import CategoriesOuterWrappper from '../CategoriesContainer/CategoriesOuterWrapper';
import { MultiSelectContainer } from '../MultiSelectContainer';

export type ResultsHeaderWrapperProps = {
  displayAddAllToCartButton?: boolean;
  displayHeaderFilterButton?: boolean;
  displayViewOptions?: boolean;
};

const ResultsHeaderWrapper: React.FC<ResultsHeaderWrapperProps> = ({
  displayAddAllToCartButton,
  displayHeaderFilterButton,
  displayViewOptions,
}) => {
  const isMultiselect = useSelector(multiSelectToggleSelector);
  const isComparisonActive = useSelector(isComparisonActiveSelector);
  const intl = useIntl();

  return isMultiselect ? (
    <MultiSelectContainer isWithCompareFeature />
  ) : (
    <Fragment>
      {isComparisonActive ? (
        <CompareList />
      ) : (
        <CategoriesOuterWrappper
          isWithCompareFeature
          displayAddAllToCartButton={displayAddAllToCartButton}
          displayHeaderFilterButton={displayHeaderFilterButton}
          displayViewOptions={displayViewOptions}
          intl={intl}
        />
      )}
    </Fragment>
  );
};

export default ResultsHeaderWrapper;
