import React, { useState, useEffect } from 'react';
import { IMAGE_QUALITY } from '@global/constants';
import { getUserLang, isEuEnv } from '@mb/lib/i18n';
import { useMediaQuery } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import * as Styled from './CampaignBanner.styles';
import { pushMiddleBannerAnalytics } from '../../utils/helpers';

export interface CampaignBannerProps {
  position: 'header' | 'content' | 'footer';
  imageUrl?: string;
  targetUrl?: string;
  mobileImageUrl?: string;
  altTag?: string;
}

const userLang = getUserLang();
const prefix = isEuEnv ? `/${userLang}` : '';

const CampaignBanner: React.FC<CampaignBannerProps> = ({
  imageUrl,
  targetUrl = '',
  mobileImageUrl,
  position,
  altTag = '',
}) => {
  const [link, setLink] = useState(targetUrl);
  const isMobile = useMediaQuery('(max-width: 767px)');
  const { ref: inViewRef } = useInView({
    threshold: 0.5,
    onChange: onInViewChange,
  });

  useEffect(() => {
    if (targetUrl?.includes('http') || targetUrl?.includes('www')) {
      setLink(targetUrl);
    } else {
      setLink(`${prefix}${targetUrl}`);
    }
  }, [targetUrl]);

  const imageSrc = isMobile
    ? `${mobileImageUrl}?width=654&quality=${IMAGE_QUALITY}` ||
      `${imageUrl}?width=654&quality=${IMAGE_QUALITY}`
    : `${imageUrl}?width=1600`;
  const imageSrc2x = isMobile
    ? `${mobileImageUrl}?width=654&quality=${IMAGE_QUALITY}` ||
      `${imageUrl}?width=654&quality=${IMAGE_QUALITY}`
    : imageUrl;

  const fileName = imageSrc?.split('/')?.pop();
  const isClickable = targetUrl?.length > 0;
  const wrapId = position === 'header' ? 'top_images_wrapper' : 'banner_wrap';

  function onInViewChange(isInView: boolean) {
    if (position === 'content' && isInView) {
      pushMiddleBannerAnalytics('view_promotion', fileName);
    }
  }

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (isClickable) {
      pushMiddleBannerAnalytics('select_promotion', fileName);
      if (position === 'content') {
        window?.open(link, '_blank')?.focus();
      } else {
        window.location.href = link;
      }
    }
  };

  return (
    <Styled.Wrapper id={wrapId} ref={inViewRef}>
      <Styled.Link href={link} isClickable={isClickable} onClick={handleClick}>
        <img
          src={imageSrc}
          srcSet={`${imageSrc} 1x, ${imageSrc2x} 2x`}
          alt={altTag}
        />
      </Styled.Link>
    </Styled.Wrapper>
  );
};

export default CampaignBanner;
