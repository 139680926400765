import React, { FC } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { useSelector } from 'react-redux';
import { isCMSModalOpenSelector } from '../../../redux/selectors/commonSelector';
import { CMSModal } from '../CMSModal';

const client = new ApolloClient({
  uri: '/graphql',
  cache: new InMemoryCache(),
});
export const CMSModalContainer: FC = () => {
  const CMSModalObject = useSelector(isCMSModalOpenSelector);

  if (CMSModalObject.status === false) {
    return null;
  }
  return (
    <ApolloProvider client={client}>
      {/* @ts-expect-error // TODO: fix types */}
      <CMSModal blockId={CMSModalObject.id} />
    </ApolloProvider>
  );
};
