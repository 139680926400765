import React, { FC } from 'react';
import { MultiselectNavbar } from 'components/components/MultiselectNavbar';
import style from './ProductGridControls.module.scss';

interface ProductGridControlsProps {
  isAddToBagDisabled: boolean;
  handleSelectAll: () => void;
  handleAddToBag: () => void;
  handleAddToBoard: () => void;
  handleCancel: () => void;
  handleOnDeskClick: () => void;
  handleOpenDeleteConfirmationModal: () => void;
  handleShareExportTo: () => void;
  handleOnlySamplesSwitch: () => void;
  shouldShowDeleteButton: boolean;
  isMobileDevice: boolean;
  currentView: 'grid' | 'list';
  numberOfSelectedItems: number;
  isSelectAllDisabled: boolean;
  addToBagTooltip?: string;
}

export const ProductGridControls: FC<ProductGridControlsProps> = ({
  isAddToBagDisabled,
  handleSelectAll,
  handleAddToBag,
  handleAddToBoard,
  handleCancel,
  handleOnDeskClick,
  handleOpenDeleteConfirmationModal,
  handleShareExportTo,
  handleOnlySamplesSwitch,
  shouldShowDeleteButton,
  isMobileDevice,
  numberOfSelectedItems = 0,
  isSelectAllDisabled = false,
  addToBagTooltip,
}) => {
  return (
    <div className={style.productGridControls}>
      <MultiselectNavbar
        isAddToBagDisabled={isAddToBagDisabled}
        isSelectAllDisabled={isSelectAllDisabled}
        onSelectAll={handleSelectAll}
        onAddToBag={handleAddToBag}
        onAddToBoard={handleAddToBoard}
        onCancel={handleCancel}
        // @ts-expect-error // TODO: fix types
        onDeskClick={handleOnDeskClick}
        onDeleteClick={handleOpenDeleteConfirmationModal}
        onExportClick={handleShareExportTo}
        selectedNumber={numberOfSelectedItems}
        onSampleSwitchClick={handleOnlySamplesSwitch}
        isSamplesSwitchEnabled={false}
        addToBagTooltip={addToBagTooltip}
        shouldShowSamplesSwitch={false}
        showAddToCartButton={!isMobileDevice}
        showSelectActionBar={!isMobileDevice}
        shouldShowDeleteButton={!!shouldShowDeleteButton}
        isCompareButtonDisabled={true}
        shouldShowNumberItemsSelected={true}
        shouldShowExportImport={false}
      />
    </div>
  );
};
