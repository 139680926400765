import React from 'react';
import MBDialog from 'components/Elements/MBDialog';
import { DownloadModalProps } from './DownloadModal.types';
import DownloadForm from '../../components/DownloadForm/DownloadForm';

const DownloadModal: React.FC<DownloadModalProps> = ({
  previews = [],
  onExportToPDF,
  onExportToCSV,
  title = 'Download',
  ...props
}) => {
  return (
    <MBDialog
      id="download_modal"
      title={title}
      dialogWidth={292}
      showCloseIcon
      titleBottomPadding={16}
      {...props}
    >
      <DownloadForm
        previews={previews}
        onExportToPDF={onExportToPDF}
        onExportToCSV={onExportToCSV}
        {...props}
      />
    </MBDialog>
  );
};

export default DownloadModal;
