import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  getUserAttributes,
  shouldTriggerProjectSelectionDialog,
  CustomEventName,
  Project,
} from '@mb/lib/';
import { postFetcher, getSearchConfig } from '@mb/lib/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import useSWRMutation from 'swr/mutation';
import { sendAnalytics } from '../../../api/events';
import { getEventsMaterialProps } from '../../../helpers';
import { extraFieldsList } from '../../../hooks';

import useAddToBag from '../../../hooks/useAddToBag';
import useCurrentProject from '../../../hooks/useCurrentProject';
import { activeContentBlockDataSelector } from '../../../redux/selectors/commonSelector';
import { xRequestIdSelector } from '../../../redux/selectors/searchSelector';
import {
  setContentBlocksModalOpen,
  setActiveContentBlock,
} from '../../../redux/sliceCreators/commonSlice';
import { setCreatedProject } from '../../../redux/sliceCreators/projectsSlice';
import { setContentBlockXRequestId } from '../../../redux/sliceCreators/searchSlice';
import { ProductSearchMaterial } from '../../../types';
import {
  checkStockAvailability,
  transformSearchspringResponseToAppFormat,
} from '../../../utils';
import {
  parseSsFacetColorFilter,
  getActiveSSFacetColorFilter,
  triggerBoardsModal,
} from '../../../utils/helpers';
import { mapProductToAnalyticsEcommerceItem } from '../../../utils/helpers/analytics';
import { ContentBlockModal } from '../ContentBlockModal';
import { ContentBlockModalDisplay } from '../ContentBlockModalDisplay';
import { CategoryType, OnClickCardInterface } from '../types';
import { getTaxonomies, getL2FromTaxonomy } from '../utilities';

async function postBlockData(
  url: string,
  {
    arg,
  }: {
    arg: {
      siteId: string;
      lang: string;
      customer: {
        id: string;
        email: string;
        projectId: number;
      };
      sessionId?: string;
      referrerUrl: string;
      id: string;
      mock: boolean;
      extraFields: string[];
    };
  },
) {
  return postFetcher(url, arg);
}
interface ContentBlocksModalContainerProps {
  handleProjectClick: (projectId: number) => void;
  onProjectSelect?: (projectId: number) => void;
  selectedProject?: string;
  onCreateProjectClick?: (projectId: number) => void;
}
export const ContentBlocksModalContainer: FC = ({
  handleProjectClick,
  onProjectSelect,
  selectedProject,
  onCreateProjectClick,
}: ContentBlocksModalContainerProps) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const activeContentBlockData: OnClickCardInterface = useSelector(
    activeContentBlockDataSelector,
  );
  const xRequestId = useSelector(xRequestIdSelector);
  const searchConfig = getSearchConfig();
  const customer = getUserAttributes();
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const [selectedMaterials, setSelectedMaterials] = useState<
    ProductSearchMaterial[]
  >([]);
  const analyticsEventLocation = 'Content_Blocks';

  const { addToBag, isLoading: isBagLoading } = useAddToBag();

  const { currentProject } = useCurrentProject();

  const { trigger, isMutating, data } = useSWRMutation(
    `${searchConfig.siteUrl}/contentblock/children?${activeContentBlockData?.id}`,
    postBlockData,
    { revalidate: false },
  );

  const [filteredResults, setFilteredResults] = useState<
    ProductSearchMaterial[]
  >([]);

  const [serverResults, setServerResults] = useState<ProductSearchMaterial[]>(
    [],
  );

  const categoryList: CategoryType[] = useMemo(
    () => getTaxonomies(data?.results),
    [data],
  );

  const handleClose = useCallback(() => {
    dispatch(setActiveContentBlock(null));
    dispatch(setContentBlockXRequestId(undefined));
    setActiveFilters([]);
    setFilteredResults([]);
    setSelectedMaterials([]);
    dispatch(setContentBlocksModalOpen(false));
  }, [dispatch]);

  useEffect(() => {
    if (activeContentBlockData) {
      trigger({
        siteId: searchConfig.siteId,
        // hardcoded for now
        lang: 'en',
        customer: {
          id: customer.customer_id,
          email: customer.email,
          projectId: parseInt(customer.current_project, 10) || 0,
        },
        sessionId: xRequestId,
        referrerUrl: `${window.location.hostname}${window.location.pathname}`,
        id: activeContentBlockData.id,
        mock: false,
        extraFields: extraFieldsList,
      });
    }
  }, [activeContentBlockData]);

  const onFilterResults = useCallback(
    (filterKey: string) => {
      // unselect all the selected materials
      setSelectedMaterials([]);
      const updatedFilters = activeFilters.includes(filterKey)
        ? activeFilters.filter((filter) => filter !== filterKey)
        : [...activeFilters, filterKey];

      setActiveFilters(updatedFilters);

      if (updatedFilters.length === 0) {
        setFilteredResults(serverResults || []);
        return;
      }

      const tempResults = serverResults.filter((result) => {
        if (result.taxonomyL2Display) {
          return updatedFilters.includes(result.taxonomyL2Display);
        }
        return false;
      });

      setFilteredResults(tempResults);
    },
    [serverResults, activeFilters],
  );

  const handleAssociatedFinishProductsModal = useCallback(() => {
    const itemsAssociatedFinishProducts: any[] = [];

    selectedMaterials.forEach((item) => {
      if ((item?.associatedFinishProducts || []).length > 0) {
        const finishItems = {
          associatedProducts: item.associatedFinishProducts,
          parentProduct: {
            id: item.id,
            manufacturerName: item.brand,
            name: item.title || item.name,
            productImageUrl: item.imageUrl,
          },
        };
        itemsAssociatedFinishProducts.push(finishItems);
      }
    });

    if (itemsAssociatedFinishProducts.length > 0) {
      window.dispatchEvent(
        new CustomEvent('open-associated-finishes-products-modal', {
          detail: [...itemsAssociatedFinishProducts],
        }),
      );
    }
  }, [filteredResults]);

  const onProjectCreate = useCallback((project: Project) => {
    dispatch(setCreatedProject(project));
  }, []);

  const handleAddToBag = () => {
    const eligibleProducts = selectedMaterials.filter(
      (item: ProductSearchMaterial) => !item?.isProduct && !item?.isDigital,
    );

    if (shouldTriggerProjectSelectionDialog()) {
      window.dispatchEvent(
        new CustomEvent(CustomEventName.OPEN_PROJECT_SELECTION_DIALOG, {
          detail: {
            // @ts-expect-error // TODO: fix types
            currentProjectId: selectedProject?.id,
            analyticsEventLocation,
            onCurrentProjectSave: (project: any) => {
              onProjectSelect?.(project?.externalId || project?.project_id);
              addToBag({
                materials: eligibleProducts,
                projectId:
                  project?.externalId?.toString() ||
                  String(project?.project_id),
                triggerLocation: 'Catalog',
                isFinishMaterial: false,
                additionalAnalyticsProperties: {
                  listId: `contentblocks_catalog_${activeContentBlockData.id}_${activeContentBlockData.indexLocation}`,
                  listName: `contentblocks catalog ${activeContentBlockData.id} ${activeContentBlockData.indexLocation}`,
                  itemListId: `contentblocks_catalog_${activeContentBlockData.id}_${activeContentBlockData.indexLocation}`,
                  itemListName: `contentblocks catalog ${activeContentBlockData.id} ${activeContentBlockData.indexLocation}`,
                  eventLocation: 'Catalog',
                },
              });
            },
            onProjectCreate: (project: Project) => {
              onProjectCreate?.(project);
            },
          },
        }),
      );
      return;
    }

    addToBag({
      materials: eligibleProducts,
      projectId: String(currentProject?.project_id),
      additionalAnalyticsProperties: {
        listId: `contentblocks_catalog_${activeContentBlockData.id}_${activeContentBlockData.indexLocation}`,
        listName: `contentblocks catalog ${activeContentBlockData.id} ${activeContentBlockData.indexLocation}`,
        itemListId: `contentblocks_catalog_${activeContentBlockData.id}_${activeContentBlockData.indexLocation}`,
        itemListName: `contentblocks catalog ${activeContentBlockData.id} ${activeContentBlockData.indexLocation}`,
        eventLocation: 'Catalog',
      },
    });
    handleAssociatedFinishProductsModal();
  };

  const handleProductSelect = (product: ProductSearchMaterial) => {
    setSelectedMaterials((prevSelectedMaterials: ProductSearchMaterial[]) => {
      const isProductSelected = prevSelectedMaterials.some(
        (selectedMaterial) => selectedMaterial.entityId === product.entityId,
      );

      if (isProductSelected) {
        return prevSelectedMaterials.filter(
          (selectedMaterial) => selectedMaterial.entityId !== product.entityId,
        );
      }

      return [...prevSelectedMaterials, product];
    });
  };

  const handleAddToBoard = useCallback(async () => {
    const ids =
      selectedMaterials?.map((item: ProductSearchMaterial) => item.entityId) ??
      [];
    const analyticsProps = getEventsMaterialProps(selectedMaterials?.[0]);

    const addToWishlistEventItems =
      selectedMaterials?.map((selectedMaterial: ProductSearchMaterial) =>
        mapProductToAnalyticsEcommerceItem({
          product: selectedMaterial,
          itemListId: `contentblocks_catalog_${activeContentBlockData.id}_${activeContentBlockData.indexLocation}`,
          itemListName: `contentblocks catalog ${activeContentBlockData.id} ${activeContentBlockData.indexLocation}`,
        }),
      ) ?? [];

    const currencies = {
      US: 'USD',
      EU: 'EUR',
      JP: 'JPY',
    };

    const itemsData = selectedMaterials.map((item: any) => {
      const type: 'finish' | 'material' = item.productFinishType
        ?.toLowerCase()
        ?.includes('finish material')
        ? 'finish'
        : 'material';

      return {
        id: item.entityId,
        type,
      };
    });

    if (ids.length > 0) {
      triggerBoardsModal({
        itemsData,
        onSuccessAnalyticsEvents: [
          { ecommerce: null },
          {
            event: 'add_to_wishlist',
            list_id: `contentblocks_catalog_${activeContentBlockData.id}_${activeContentBlockData.indexLocation}`,
            list_name: `contentblocks catalog ${activeContentBlockData.id} ${activeContentBlockData.indexLocation}`,
            item_list_id: `contentblocks_catalog_${activeContentBlockData.id}_${activeContentBlockData.indexLocation}`,
            item_list_name: `contentblocks catalog ${activeContentBlockData.id} ${activeContentBlockData.indexLocation}`,
            method:
              filteredResults.length === selectedMaterials.length
                ? 'Add all'
                : 'Multi-select',
            type: 'Materials',
            name: null,
            action: null,
            experiment_name: null,
            experiment_version: null,
            event_location: 'Catalog',
            ecommerce: {
              currency: currencies[ENVIRONMENT_REGION],
              value: 0,
              items: addToWishlistEventItems,
            },
          },
        ],
      });
      const stringIds = ids.map((id: any) => String(id));
      const value = {};
      await sendAnalytics({
        id: stringIds,
        type: 'favorite',
        ...value,
        ...analyticsProps,
      });
    }
  }, [selectedMaterials, activeContentBlockData]);

  const handleSelectAll = useCallback(() => {
    setSelectedMaterials(
      filteredResults.map((product: any) => {
        if (product?.selectedColorFilterEntityId) {
          return {
            ...product,
            entityId: product.selectedColorFilterEntityId,
          };
        }

        return product;
      }),
    );
  }, [filteredResults]);

  const handleSelectMaterial = useCallback(
    (material: ProductSearchMaterial) => {
      setSelectedMaterials((prev) => {
        const isMaterialSelected = prev.some(
          (selectedMaterial) => selectedMaterial.id === material.id,
        );

        if (isMaterialSelected) {
          return prev.filter(
            (selectedMaterial) => selectedMaterial.id !== material.id,
          );
        }

        return [...prev, material];
      });
    },
    [],
  );

  const onHandleCancel = () => {
    setSelectedMaterials([]);
  };

  useEffect(() => {
    if (!isMutating && data) {
      const mappedResults = data.results.map(
        transformSearchspringResponseToAppFormat,
      );

      // @ts-expect-error // TODO: fix types
      const updatedResults = mappedResults.map((item) => {
        const colorFilterParsed = parseSsFacetColorFilter(
          item.ssFacetColorFilter,
        );

        const selectedColorFilter = getActiveSSFacetColorFilter(
          // @ts-expect-error // TODO: fix types
          colorFilterParsed,
          {
            searchQuery: '',
            selectedColor: data?.selectedColor,
          },
        );

        const taxonomyL2 = getL2FromTaxonomy(item);

        return {
          ...item,
          selectedColorFilterEntityId: selectedColorFilter?.entity_id,
          taxonomyL2Display: taxonomyL2,
          analyticsData: {
            analyticsClass: 'recommend',
            impressions: mappedResults?.map((item: ProductSearchMaterial) =>
              String(item.entityId),
            ),
          },
          originRequestId: data?.headers,
        };
      });
      setFilteredResults(updatedResults);
      setServerResults(updatedResults);

      checkStockAvailability(updatedResults)
        .then((response) => {
          setServerResults(response);
          setFilteredResults(response);
        })
        .catch((error) => {
          console.error('Error checking stock availability:', error);
        });
      dispatch(setContentBlockXRequestId(data?.headers));
      dataLayer.push({
        event: 'view_item_list',
        list_id: `contentblocks_catalog_${activeContentBlockData.id}_${activeContentBlockData.indexLocation}`,
        list_name: `contentblocks catalog ${activeContentBlockData.id} ${activeContentBlockData.indexLocation}`,
        event_location: 'Catalog',
        item_list_id: `contentblocks_catalog_${activeContentBlockData.id}_${activeContentBlockData.indexLocation}`,
        item_list_name: `contentblocks catalog ${activeContentBlockData.id} ${activeContentBlockData.indexLocation}`,
        ecommerce: {
          items: updatedResults?.map(
            (selectedMaterial: ProductSearchMaterial) =>
              mapProductToAnalyticsEcommerceItem({
                product: selectedMaterial,
                itemListId: `contentblocks_catalog_${activeContentBlockData.id}_${activeContentBlockData.indexLocation}`,
                itemListName: `contentblocks catalog ${activeContentBlockData.id} ${activeContentBlockData.indexLocation}`,
              }) ?? [],
          ),
        },
      });
    }
  }, [data, isMutating]);

  return (
    <ContentBlockModal
      open={!isMutating}
      visible
      onClose={handleClose}
      isMobile={isMobile}
    >
      <ContentBlockModalDisplay
        id={activeContentBlockData.id}
        indexLocation={activeContentBlockData.indexLocation}
        isLoading={isMutating}
        cardAttributes={data?.cardAttributes}
        results={filteredResults}
        activeFilters={activeFilters}
        // @ts-expect-error // TODO: fix types
        categories={categoryList}
        // @ts-expect-error // TODO: fix types
        filterResults={onFilterResults}
        handleAddToBag={handleAddToBag}
        onSelectAll={handleSelectAll}
        handleAddToBoard={handleAddToBoard}
        handleSelectMaterial={handleSelectMaterial}
        onHandleCancel={onHandleCancel}
        // @ts-expect-error // TODO: fix types
        handleProductSelect={handleProductSelect}
        // @ts-expect-error // TODO: fix types
        selectedProducts={selectedMaterials}
        isMobile={isMobile}
        isBagLoading={isBagLoading}
        // @ts-expect-error // TODO: fix types
        handleProjectClick={handleProjectClick}
        // @ts-expect-error // TODO: fix types
        onProjectSelect={onProjectSelect}
        selectedProject={selectedProject}
        // @ts-expect-error // TODO: fix types
        onCreateProjectClick={onCreateProjectClick}
      />
    </ContentBlockModal>
  );
};
