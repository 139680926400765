import React, { Fragment, useEffect } from 'react';
import FilterOption from 'components/Filters/FilterOption';
import { IntlShape } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { searchActionsEnum } from '../../../config/constants';
import { useSearchContext } from '../../../contexts';
import { definedTranslation } from '../../../i18n';
import {
  isBrandSwitchSelector,
  isRepToolEnabledSelector,
} from '../../../redux/selectors/filtersSelector';
import { searchOptionsSelector } from '../../../redux/selectors/searchSelector';
import {
  setIsBrandSwitch,
  setIsRepToolEnabled,
} from '../../../redux/sliceCreators/filtersSlice';
import { getUserAttributes } from '../../../utils/localstorage';

interface BrandSwitchProps {
  intl: IntlShape;
}

const BrandSwitch: React.FC<BrandSwitchProps> = ({ intl }) => {
  // @ts-expect-error // TODO: fix types
  const { search } = useSearchContext();
  const isBrandSwitch = useSelector(isBrandSwitchSelector);
  const searchOptions = useSelector(searchOptionsSelector);
  const isRepToolEnabled = useSelector(isRepToolEnabledSelector);
  const dispatch = useDispatch();
  const userData = getUserAttributes();
  const isTier13 = String(userData?.groupId) === '13';
  const shouldShowBrandFilter =
    userData?.hasAssociatedBrands && isTier13 && isRepToolEnabled;

  const textLabel = intl.formatMessage(
    definedTranslation.filters.promoItemsLabel,
  );

  useEffect(() => {
    window.addEventListener('active-brand', handleRepTools);
    return () => {
      window.removeEventListener('active-brand', handleRepTools);
    };
  }, []);

  const handleRepTools = (e: CustomEvent) => {
    const isRepTools = e?.detail?.activeBrand?.rep_tools;
    if (isRepTools != null) {
      dispatch(setIsRepToolEnabled(typeof isRepTools == 'boolean'));
    }
  };

  const triggerSearch = (isBrandSwitchSelected: boolean) => {
    search(
      {
        ...searchOptions,
        action: searchActionsEnum.filter,
        page: 1,
      },
      false,
      null,
      isBrandSwitchSelected,
    );
  };

  const handleClick = () => {
    dispatch(setIsBrandSwitch(!isBrandSwitch));
    triggerSearch(!isBrandSwitch);
  };

  if (!shouldShowBrandFilter) {
    return <Fragment />;
  }

  return (
    // @ts-expect-error // TODO: fix types
    <FilterOption
      isSwitch
      label={textLabel}
      isSelected={isBrandSwitch}
      onClick={handleClick}
    />
  );
};

export default BrandSwitch;
