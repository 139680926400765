import React from 'react';

import * as Styled from './DownloadForm.styles';

import { DownloadFormExportProps } from './DownloadForm.types';

const DownloadForm: React.FC<DownloadFormExportProps> = ({
  previews,
  onExportToCSV,
  onExportToPDF,
  selectedLabel = 'Selected',
  materialsListButtonLabel = 'Material Information',
  relatedDocumentsButtonLabel = 'Material Documents',
  ...props
}) => {
  return (
    <>
      <Styled.Previews>
        {previews.slice(0, 5).map((item, index) => (
          <Styled.Preview key={index} imageUrl={item.imageUrl} />
        ))}
      </Styled.Previews>
      <Styled.PreviewsNumber variant="tiny">
        {previews.length} {selectedLabel}
      </Styled.PreviewsNumber>
      <Styled.DialogActions>
        <Styled.Button
          id="download_csv_button"
          role="button"
          fullWidth
          size="small"
          variant="contained"
          disabled={props.exportToCSVDisabled}
          onClick={() => onExportToCSV?.(previews)}
        >
          {materialsListButtonLabel}
        </Styled.Button>
        <Styled.Button
          id="download_pdf_button"
          role="button"
          fullWidth
          size="small"
          variant="contained"
          disabled={props.exportToPDFDisabled}
          onClick={() => onExportToPDF?.(previews)}
        >
          {relatedDocumentsButtonLabel}
        </Styled.Button>
      </Styled.DialogActions>
    </>
  );
};

export default DownloadForm;
