import React, { useMemo, Fragment } from 'react';
import MBChip from 'components/Elements/MBChip/MBChip';
import omit from 'lodash/omit';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import * as Styled from './SelectedFilters.styles';
import { searchActionsEnum } from '../../../config/constants';
import { definedTranslation } from '../../../i18n';
import { appFiltersSelector } from '../../../redux/selectors/filtersSelector';
import {
  searchOptionsSelector,
  searchResultsSelector,
} from '../../../redux/selectors/searchSelector';
import {
  removeFilter,
  clearFilters,
  setAppFilters,
  setIsRainbowClicked,
} from '../../../redux/sliceCreators/filtersSlice';
import { setSearchOptions } from '../../../redux/sliceCreators/searchSlice';
import { FilterData } from '../../../types';
import { getFiltersWithoutCheckboxValues } from '../../../utils';
import { getCharsNumber } from '../../../utils/helpers';

const hiddenLabels = [
  'category_ids',
  'residential_commercial',
  'visibility',
  'is_new',
  'just_new',
  'quick_filters',
  'ss_product_toggle',
];
const overrideFilters = [
  { filter: 'is_new', userLabel: 'New' },
  { filter: 'just_new', userLabel: 'New' },
  { filter: 'is_materials', userLabel: 'Materials' },
  { filter: 'is_paints', userLabel: 'Paints' },
  { filter: 'is_products', userLabel: 'Products' },
  { filter: 'ss_product_toggle', userLabel: 'Products' },
];
const virtualColorLabel = 'virtual_color';
const virtualColorGroupLabel = 'virtual_color_group';
const taxonomyLabel = 'taxonomy';

const isColorShade = (label: string) =>
  label.includes(virtualColorLabel) && !label.includes('group');
const isColorGroup = (label: string) => label.includes(virtualColorGroupLabel);

const SelectedFilters = () => {
  const appFilters = useSelector(appFiltersSelector);
  const results = useSelector(searchResultsSelector);
  const searchOptions = useSelector(searchOptionsSelector);
  const dispatch = useDispatch();
  const intl = useIntl();
  const noCheckboxFilters = useMemo(
    () => getFiltersWithoutCheckboxValues(appFilters),
    [appFilters],
  );
  const resultsExist = results?.length > 0;
  // Show Switches, in case applied Switch returned 0 Results, and there is no API switches,
  // because response is empty, and user can't untoggle it
  const filters =
    Object.values(resultsExist ? noCheckboxFilters : appFilters) ?? [];
  const shadesFilters = filters?.filter(
    (item: FilterData) => item.field === virtualColorLabel,
  );
  const isLongShadeList = shadesFilters?.length > 2;
  const shadesLabel = shadesFilters
    ?.filter((item, index) => index < 2)
    ?.map((item: FilterData) => item.label)
    ?.join(', ');

  const labels = {
    clearAll: intl?.formatMessage(definedTranslation.filters.clearAll),
  };

  const getLabel = (id: string, label: string) => {
    if (id.includes('taxonomy') && label?.includes('>')) {
      const taxonomyFilter = label.split('>');
      taxonomyFilter.shift();
      return taxonomyFilter.join('>');
    }
    return label;
  };

  const removeAllShades = () => {
    // Clear all Shade filters
    const filterKeys = Object.keys(appFilters || {})?.filter((key) => {
      return isColorShade(key);
    });
    const filteredFilters = omit(appFilters, filterKeys);
    dispatch(setAppFilters(filteredFilters));
    window.dispatchEvent(new CustomEvent('update-gradient-shades', {}));
  };

  const filteredFilters = filters
    ?.filter(({ field }) => !hiddenLabels.includes(field))
    ?.map((filter: FilterData) => {
      const filterToChange = overrideFilters.find(
        (item) => item.filter === filter.field,
      );
      if (filterToChange != null) {
        return {
          ...filter,
          label: filterToChange?.userLabel,
        };
      } else {
        return filter;
      }
    })
    .filter(
      (item) =>
        item.field !== virtualColorLabel &&
        !(
          item.field === taxonomyLabel && getCharsNumber(item.label, '>') === 1
        ),
    );

  const handleRemoveFilter = (filterId: string, filter: FilterData) => {
    if (filteredFilters.length === 1) {
      // Reset if just 1 filter
      handleClearFilters();
      return;
    }
    if (filterId != null) {
      if (isColorGroup(filterId)) {
        dispatch(removeFilter([filter, ...shadesFilters]));
        dispatch(setIsRainbowClicked(false));
      } else {
        dispatch(removeFilter(filterId));
      }
    }
  };

  const handleClearFilters = () => {
    dispatch(
      setSearchOptions({
        ...searchOptions,
        action: searchActionsEnum.resetFilters,
      }),
    );
    dispatch(clearFilters(null));
  };

  if (filteredFilters.length === 0) {
    return <Fragment />;
  }

  return (
    <Styled.Wrapper>
      {filteredFilters.map(
        (filter) =>
          filter?.label != null &&
          !filter?.id?.includes('brand_product') && (
            <MBChip
              variant="filled"
              color="secondary"
              key={filter?.id}
              // @ts-expect-error // TODO: fix types
              id={filter?.id}
              labelText={getLabel(filter?.id, filter?.label)}
              onDelete={() => handleRemoveFilter(filter?.id, filter)}
              removeIcon="close"
            />
          ),
      )}
      {shadesFilters?.length > 0 && (
        <span className="shadesFilter">
          <MBChip
            variant="filled"
            color="secondary"
            key="shade_filter"
            // @ts-expect-error // TODO: fix types
            id="shade_filter"
            labelText={isLongShadeList ? `${shadesLabel},...` : shadesLabel}
            onDelete={() => removeAllShades()}
            removeIcon="close"
          />
        </span>
      )}
      {filteredFilters.length > 1 && (
        <MBChip
          color="primary"
          variant="filled"
          labelText={labels.clearAll}
          onClick={() => handleClearFilters()}
        />
      )}
    </Styled.Wrapper>
  );
};

export { SelectedFilters };
