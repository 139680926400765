import React, { useState, useEffect } from 'react';
import { App } from '@mb/App';

import { getLocaleData, isEuEnv, LocaleData } from '@mb/lib/i18n';
import { useTrackSplitTimeOnPage } from '@mb/lib/splitio';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { ThemeContainer } from 'ui-widgets';
import { AppContainer } from './containers/AppContainer';
import { SearchContextProvider, FiltersContextProvider } from './contexts';
import store from './redux/store';
import { isCuratedCollectionPage as helperIsCuratedCollectionPage } from './utils/helpers';

const ProductSearch = () => {
  const isCuratedCollectionPage = helperIsCuratedCollectionPage();
  useTrackSplitTimeOnPage({ pageName: 'Search' });
  const [langData, setLangData] = useState<LocaleData>({
    currentLocale: 'en',
    messages: {},
  });

  useEffect(() => {
    if (isEuEnv) {
      handleLangData();
    }
  }, []);

  const handleLangData = async () => {
    const data = await getLocaleData();
    setLangData(data);
  };

  return (
    <App>
      <IntlProvider
        messages={langData?.messages}
        locale={langData?.currentLocale}
        defaultLocale="en"
      >
        <ThemeContainer>
          {/* @ts-expect-error // TODO: fix types */}
          <Provider store={store}>
            <FiltersContextProvider>
              <SearchContextProvider>
                <AppContainer
                  displayAddAllToCartButton={isCuratedCollectionPage}
                  displayHeaderFilterButton
                  displayHeaderFilters
                  displayViewOptions={!isCuratedCollectionPage}
                />
              </SearchContextProvider>
            </FiltersContextProvider>
          </Provider>
        </ThemeContainer>
      </IntlProvider>
    </App>
  );
};

export default ProductSearch;
