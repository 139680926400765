import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { IconButton } from 'components/Buttons/IconButton';
import { Typography } from 'components/Typography';

export const FiltersOuterWrapper = styled(Box)`
  min-width: 210px;
`;

export const CloseButton = styled(IconButton)`
  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: none;
  }
`;

export const Header = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey['A400']};
`;
