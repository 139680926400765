export const adjustIndex = (index: number, mapping: number[]): number => {
  let adjustedIndex = index;

  mapping.reverse().forEach((mapIndex) => {
    if (index >= mapIndex) {
      adjustedIndex += 1;
    }
  });

  return adjustedIndex;
};
