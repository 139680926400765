// @ts-nocheck TODO: fix types for the entire file

import styled from '@emotion/styled';
import Tooltip from '@mui/material/Tooltip';
import { Button } from 'components/Buttons/Button';
import { IconButton } from 'components/Buttons/IconButton';
import { theme } from 'theme';
import palette from 'theme/palette';

interface Color {
  color: string;
  showShadow?: boolean;
  isDisabled?: boolean;
  isActive?: boolean;
}

export const OuterWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;

export const Wrapper = styled.div<{
  isShown?: boolean;
  isGradientShown?: boolean;
}>`
  width: 100%;
  margin: 1em 0 0.25em;

  ${theme.breakpoints.down('md')} {
    margin-left: -1rem;
    overflow-x: ${({ isGradientShown }) => (isGradientShown ? 'none' : 'auto')};
    width: 100vw;
    padding-top: 0.2rem;
    padding-left: 0.5rem;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  ${({ isShown }) =>
    !isShown &&
    `
        display: none;
    `}
`;

export const CirclesWrapper = styled.div<{ isRainbowEnabled?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  ${theme.breakpoints.up('md')} {
    gap: 29px;
  }

  .colorwheelIcon {
    margin: 0 3px;
    display: flex;
    flex-basis: 48px;
    flex-grow: 0;
    aspect-ratio: 1/1;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 50%;
    transform: scale(1);
    pointer-events: ${({ isRainbowEnabled }) =>
      isRainbowEnabled ? 'auto' : 'none'};
    opacity: ${({ isRainbowEnabled }) => (isRainbowEnabled ? '1' : '0.5')};
    &:hover {
      transform: scale(1.1);
    }
  }

  ${theme.breakpoints.down('md')} {
    width: fit-content;
    margin-right: 8px;

    .colorwheelIcon {
      flex-basis: auto;
      min-width: 48px;
      max-width: 48px;
      height: 48px;
      margin: 0 4px;
    }
  }
`;

export const ColorCircle = styled.div<Color>`
  margin: 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 48px;
  flex-grow: 0;
  aspect-ratio: 1/1;
  background-color: ${({ color }) => color};
  border-radius: 50px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  box-shadow: ${({ showShadow }) =>
    showShadow ? `0px 0px 0px 1px ${palette.secondary.light}` : 'none'};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.15' : '1')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  transform: scale(1);
  font-size: 0.85em;
  color: ${({ theme, showShadow }) =>
    showShadow ? theme.palette.primary.main : theme.palette.common.white};
  ${theme.breakpoints.up('md')} {
    &:hover {
      transform: scale(1.1);
    }
  }
  ${theme.breakpoints.down('md')} {
    flex-basis: auto;
    width: 48px;
    height: 48px;
    margin: 0 4px;
  }
`;

export const RangePickerWrapper = styled.div`
    display: flex;
    width: 100%;
    align-center;

    ${theme.breakpoints.down('md')} {
        padding: 0 1rem;
    }

    ${theme.breakpoints.down('sm')} {
        border-top: 1px solid ${palette.grey[50]};
        flex-direction: column;
        align-items: center;
    }
`;

export const CloseRangeIconButton = styled(IconButton)`
  margin-right: 1em;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  display: inline-flex;

  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const CloseRangeButton = styled(Button)`
  display: none;
  ${theme.breakpoints.down('sm')} {
    display: flex;
  }
`;

export const Info = styled(Tooltip)`
  & ~ .MuiTooltip-popper {
    .MuiTooltip-tooltip {
      margin-top: -2px !important;
      background: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.common.white};
      font-size: 12px;
      padding: 7px 10px;
      border-radius: 20px;
      text-align: center;
      min-height: 24px;
    }
  }
`;
