import { createDeepEqualSelector } from './';

export const commonSelector = (state: any) => state.common;

export const errorMessageSelector = createDeepEqualSelector(
  commonSelector,
  (common) => common.errorMessage,
);

export const cartItemsSelector = createDeepEqualSelector(
  commonSelector,
  (common) => common.cartItems || [],
);

export const snackbarInfoSelector = createDeepEqualSelector(
  commonSelector,
  (common) => common.snackbarInfo,
);

export const cartInstanceSelector = createDeepEqualSelector(
  commonSelector,
  (common) => common.cartInstance,
);

export const openedPDPListSelector = createDeepEqualSelector(
  commonSelector,
  (common) => common.openedPDPList,
);

export const isSearchLiteSelector = createDeepEqualSelector(
  commonSelector,
  (common) => common.isSearchLite,
);

export const isCreatePaletteModalOpened = createDeepEqualSelector(
  commonSelector,
  (common) => common.createPaletteModalOpened,
);

export const isExportModalOpenSelector = createDeepEqualSelector(
  commonSelector,
  (common) => common.isExportModalOpen,
);

export const currentCollectionTitleSelector = createDeepEqualSelector(
  commonSelector,
  (common) => common.currentCollectionTitle,
);

export const comparisonItemsSelector = createDeepEqualSelector(
  commonSelector,
  (common) => common.comparisonItems,
);

export const isComparisonActiveSelector = createDeepEqualSelector(
  commonSelector,
  (common) => common.isComparisonActive,
);

export const isCompareTableShownSelector = createDeepEqualSelector(
  commonSelector,
  (common) => common.isCompareTableShown,
);

export const compareApiResultsSelector = createDeepEqualSelector(
  commonSelector,
  (common) => common.compareApiResults || [],
);

export const compareApiKeysSelector = createDeepEqualSelector(
  commonSelector,
  (common) => Object.entries(common.compareApiKeys || {}),
);

export const isContentBlocksModalOpenSelector = createDeepEqualSelector(
  commonSelector,
  (common) => common.isContentBlocksModalOpen,
);

export const activeContentBlockDataSelector = createDeepEqualSelector(
  commonSelector,
  (common) => common.activeContentBlock,
);

export const isCMSModalOpenSelector = createDeepEqualSelector(
  commonSelector,
  (common) => common.isCMSModalOpen,
);
