import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { searchActionsEnum } from '../../config/constants';
import { useSearchContext } from '../../contexts';
import {
  appFiltersSelector,
  searchToggleSelector,
} from '../../redux/selectors/filtersSelector';
import { searchOptionsSelector } from '../../redux/selectors/searchSelector';
import { setOverrideParams } from '../../redux/sliceCreators/filtersSlice';
import { setSearchOptions } from '../../redux/sliceCreators/searchSlice';
import { isHomePage } from '../../utils/helpers';

const SearchFormListener = () => {
  // @ts-expect-error // TODO: fix types
  const { search } = useSearchContext();
  const searchToggle = useSelector(searchToggleSelector);
  const searchOptions = useSelector(searchOptionsSelector);
  const appFilters = useSelector(appFiltersSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener('megaMenuClick', handleMegaMenuClick);
    document.addEventListener('searchFormSubmit', handleFormSubmit);
    return () => {
      document.removeEventListener('searchFormSubmit', handleFormSubmit);
      document.removeEventListener('megaMenuClick', handleMegaMenuClick);
    };
  }, [searchToggle, appFilters]);

  // @ts-expect-error // TODO: fix types
  const handleFormSubmit = (event) => {
    const { detail } = event;
    if (detail != null && !isHomePage()) {
      dispatch(setOverrideParams(null));
      search(detail, true);
    }
  };

  const handleMegaMenuClick = () => {
    dispatch(
      setSearchOptions({
        ...searchOptions,
        action: searchActionsEnum.reset,
      }),
    );
  };

  return null;
};

export default SearchFormListener;
