import React, { PropsWithChildren, useRef } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Box } from '@mui/system';
import { CustomIcon } from 'components/CustomIcon';
import { FilterGroupProps } from 'components/Filters/Filters.types';
import { InformationIcon } from 'icons';
import * as Styled from './FilterGroup.styles';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    maxWidth: 300,
    borderRadius: '20px',
    boxShadow: '0 10px 40px 0 rgba(0, 0, 0, 0.08)',
    position: 'relative',
    left: '24px',
  },
}));

const FilterGroup: React.FC<PropsWithChildren<FilterGroupProps>> = ({
  isExpanded,
  expandIcon,
  groupTitle,
  mountOnEnter = true,
  unmountOnExit = true,
  children,
  onCollapseChange,
  onBackArrowClick,
  maxWidth,
  maxHeight,
  selectedInGroupNumber,
  filterInputValue,
  onFilterInputValueChange,
  shouldShowFilter = false,
  showScrollbar = false,
  shouldBackArrow = false,
  filterInputId = undefined,
  searchPlaceholder = 'Search',
  titleTooltipContent = undefined,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const collapseIcon = (
    <CustomIcon name={expandIcon ?? 'down'} dense size={0.6} />
  );
  const handleChange = (event: React.SyntheticEvent, expanded: boolean) => {
    onCollapseChange?.(event, expanded);
  };
  const handleIconClick = () => {
    if (inputRef?.current != null) {
      inputRef.current.focus();
    }
  };

  const handleBackArrowClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    onBackArrowClick?.();
  };

  return (
    <Styled.Accordion
      expanded={isExpanded}
      TransitionProps={{ mountOnEnter, unmountOnExit }}
      onChange={handleChange}
      maxwidth={maxWidth}
    >
      <Box display="flex">
        {shouldBackArrow && (
          <Styled.BackButton
            dense
            iconName="arrow-left"
            color="inherit"
            onClick={handleBackArrowClick}
          />
        )}
        <Styled.AccordionSummary expandIcon={collapseIcon}>
          <Styled.AccordionTitle>
            {groupTitle}
            {titleTooltipContent != undefined && (
              <CustomTooltip
                title={<React.Fragment>{titleTooltipContent}</React.Fragment>}
                placement="bottom"
              >
                <span>
                  <InformationIcon />
                </span>
              </CustomTooltip>
            )}
          </Styled.AccordionTitle>
          {(selectedInGroupNumber ?? 0) > 0 && (
            <Styled.Badge>{selectedInGroupNumber}</Styled.Badge>
          )}
        </Styled.AccordionSummary>
      </Box>
      {shouldShowFilter && (
        <Styled.TextField
          inputRef={inputRef}
          fullWidth
          InputProps={{
            id: filterInputId,
            startAdornment: (
              <InputAdornment position="start">
                <CustomIcon name="search" dense onClick={handleIconClick} />
              </InputAdornment>
            ),
          }}
          autoComplete="off"
          // @ts-expect-error // TODO: fix types
          variant="outlined"
          placeholder={searchPlaceholder}
          size="medium"
          className="filtersInput"
          value={filterInputValue}
          onChange={onFilterInputValueChange}
        />
      )}
      <Styled.AccordionDetails
        maxheight={maxHeight}
        showScrollbar={showScrollbar}
      >
        {children}
      </Styled.AccordionDetails>
    </Styled.Accordion>
  );
};

export default FilterGroup;
