export const getPartnersPageItemListData = () => {
  const searchContentTag = document.getElementById('searchContent');

  if (searchContentTag != null) {
    const itemListId = searchContentTag?.dataset?.item_list_id
      ?.toLowerCase()
      ?.replaceAll(' ', '_');
    const itemListName = searchContentTag?.dataset?.item_list_name;
    return { itemListId, itemListName };
  }

  return null;
};
