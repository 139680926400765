import React from 'react';

export const Placeholder = React.memo(() => {
  // Placeholder with the same aspect ratio as the card
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        borderRadius: '20px',
        paddingBottom: '136px',
        backgroundColor: '#fbfbfc',
      }}
    >
      <div
        style={{
          display: 'flex',
          top: 0,
          left: 0,
          width: '100%',
          paddingTop: '100%',
        }}
      ></div>
    </div>
  );
});
