import { FilterData } from '../../../types';
import { isDivider, splittedCategoriesFields } from '../../../utils';

export const processLabel = (label: string) => {
  const separators = ['>'];
  let processedLabel = label;

  separators.forEach((separator) => {
    if (label.includes(separator)) {
      const parts = label.split(separator);
      processedLabel = parts[parts.length - 1].trim();
    }
  });

  return processedLabel;
};

export const filterRedundantFacets = (item: FilterData) => {
  return (
    !item.active &&
    (item.count || 0) > 0 &&
    // hide Level 0 Taxonomy
    !item.id?.includes('taxonomy')
  );
};

export const getParent = (item: FilterData) => {
  const divider = isDivider(item.label);
  if (divider) {
    if (splittedCategoriesFields.includes(item.field)) {
      const [parent] = item.label.split(divider);
      return { parent };
    }
    return {};
  }
  return {};
};
