import { useEffect } from 'react';
import {
  DispatchSetCurrentProjectIdArgs,
  setCurrentProjectIdEvent,
  projectCreatedEvent,
} from '@material-bank/projects-utils';
import useCurrentProject from './useCurrentProject';
import { getUserProjects } from '../api/projects';
import { setCurrentProjectId } from '../redux/sliceCreators/projectsSlice';
import { useAppDispatch } from '../redux/store';

export function useCurrentProjectEventListeners() {
  const dispatch = useAppDispatch();
  const { currentProject } = useCurrentProject();

  useEffect(() => {
    function projectCreatedHandler() {
      dispatch(getUserProjects());
    }

    function projectChangeHandler(
      event: CustomEvent<DispatchSetCurrentProjectIdArgs>,
    ) {
      const projectId = event?.detail?.projectId;

      if (projectId !== currentProject?.project_id?.toString()) {
        dispatch(setCurrentProjectId(projectId));
      }
    }

    window.addEventListener(
      projectCreatedEvent,
      projectCreatedHandler as EventListener,
    );

    window.addEventListener(
      setCurrentProjectIdEvent,
      projectChangeHandler as EventListener,
    );

    return () => {
      window.removeEventListener(
        projectCreatedEvent,
        projectCreatedHandler as EventListener,
      );

      window.removeEventListener(
        setCurrentProjectIdEvent,
        projectChangeHandler as EventListener,
      );
    };
  }, [currentProject?.project_id]);
}
