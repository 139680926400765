import { useCallback, useEffect, useRef, useState } from 'react';

interface UseIsVisibleProps {
  options?: IntersectionObserverInit;
}

export interface Observe<T> {
  (element?: T | null): void;
}

export const useIsVisible = <T extends HTMLElement | null>({
  options,
}: UseIsVisibleProps) => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const elementRef = useRef<T | null>(null);

  const [isVisible, setIsVisible] = useState(false);

  const observerCallback: IntersectionObserverCallback = useCallback(
    (entries) => {
      const entry = entries[0];
      setIsVisible(entry.isIntersecting);
    },
    [],
  );

  const unobserve = useCallback(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }
  }, []);

  const observe = useCallback<Observe<T>>((element) => {
    if (elementRef.current && element && elementRef.current !== element) {
      unobserve();
    }

    if (element) {
      elementRef.current = element;
    }

    if (observerRef.current && elementRef.current) {
      observerRef.current.observe(elementRef.current as HTMLElement);
    }
  }, []);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(observerCallback, options);

    return () => {
      unobserve();
    };
  }, []);

  return { ref: elementRef, isVisible, observe, unobserve };
};
