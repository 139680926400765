import styled from '@emotion/styled';
import { Button } from 'components/Buttons/Button';

export const SubList = styled.div`
  padding-left: 1em;
  margin-left: 0.5em;
`;

export const BackButton = styled(Button)`
  padding: 0;
  margin-top: 0.75em;
  font-size: 12px;
`;

export const Item = styled.div<{ isParentChecked?: boolean }>`
  ${({ isParentChecked }) =>
    isParentChecked
      ? `
    & ~ * {
      display: none;
    }
  `
      : ''};
`;

export const ItemWrapper = styled.div`
  position: relative;
  [data-virtuoso-scroller] {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
