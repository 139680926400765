import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { useSelector } from 'react-redux';
// @ts-expect-error // TODO: fix types
import Sticky from 'react-stickynode';
import { FilterContainer } from './FilterContainer';
import { FiltersSkeleton } from '../../components/FiltersSkeleton';
import { useUpdateEffect } from '../../hooks/customHooks';
import { isSearchLiteSelector } from '../../redux/selectors/commonSelector';
import {
  appFiltersSelector,
  isFilterShownSelector,
  currentFiltersSelector,
  isColorCircleTopbarShownSelector,
  isBrandSwitchSelector,
} from '../../redux/selectors/filtersSelector';
import { currentLaunchesSelector } from '../../redux/selectors/launchesSelector';
import {
  campaignTopBannerSelector,
  isSearchingSelector,
} from '../../redux/selectors/searchSelector';
import { isCuratedCollectionPage } from '../../utils/helpers';
import * as Styled from '../AppContainer/AppContainer.styles';

const isCuratedCollection = isCuratedCollectionPage();

const FilterWrapper = () => {
  const [isStickyEnabled, setIsStickyEnabled] = useState(false);
  const [topPosition, setTopPosition] = useState(0);
  const stickyRef = useRef(null);
  const appFilters = useSelector(appFiltersSelector);
  const filters = useSelector(currentFiltersSelector);
  const isFilterShown = useSelector(isFilterShownSelector);
  const isColorTopbarShown = useSelector(isColorCircleTopbarShownSelector);
  const isSearching = useSelector(isSearchingSelector);
  const isSearchLite = useSelector(isSearchLiteSelector);
  const launches = useSelector(currentLaunchesSelector);
  const campaignTopBanner = useSelector(campaignTopBannerSelector);
  const isBrandSwitch = useSelector(isBrandSwitchSelector);
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;
  const stickyHeight = 158;
  const stickyHeightWithColors = 200;

  /**
   * Updates the top position for sticky elements based on whether the color top bar is shown.
   * @returns {void}
   */
  const handleWindowScrollUpdate = useCallback(() => {
    setTopPosition(isColorTopbarShown ? stickyHeightWithColors : stickyHeight);
  }, [isColorTopbarShown]);

  /**
   * Handles the collapse state change of filters, updating the sticky instance dimensions.
   * @returns {void}
   */
  const handleCollapseChange = () => {
    const stickyInstance = stickyRef.current;

    if (stickyInstance === null) {
      return;
    }
    setTimeout(() => {
      // @ts-expect-error // TODO: fix types
      stickyInstance?.updateInitialDimension?.();
      // @ts-expect-error // TODO: fix types
      stickyInstance?.update?.();
    }, 500);
  };
  /**
   * Handles window resize to enable or disable sticky based on window width.
   * @returns {void}
   */
  const handleResize = () => {
    setIsStickyEnabled(window.innerWidth > 1024);
  };

  /**
   * Increases the top position by 30.
   * @returns {void}
   */
  const addToTopPos = () => setTopPosition((prev) => prev + 30);

  /**
   * Decreases the top position by 30.
   * @returns {void}
   */
  const removeFromTopPos = () => setTopPosition((prev) => prev - 30);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('announcementAdded', addToTopPos);
    window.addEventListener('announcementClosed', removeFromTopPos);
    window.addEventListener('windowScrollUpdated', handleWindowScrollUpdate);
    return () => {
      window.removeEventListener('announcementAdded', addToTopPos);
      window.removeEventListener('announcementClosed', removeFromTopPos);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener(
        'windowScrollUpdated',
        handleWindowScrollUpdate,
      );
    };
  }, [handleWindowScrollUpdate, isStickyEnabled]);

  useEffect(() => {
    setTopPosition(isColorTopbarShown ? stickyHeightWithColors : stickyHeight);
  }, [
    isColorTopbarShown,
    launches,
    campaignTopBanner,
    handleWindowScrollUpdate,
  ]);

  useUpdateEffect(() => {
    handleCollapseChange();
    setIsStickyEnabled(true);
  }, [filters, campaignTopBanner]);

  const appliedFilters = Object.values(appFilters ?? {});
  const isNoFilters =
    appliedFilters?.length === 0 && filters?.length === 0 && !isBrandSwitch;
  const showFilter = isFilterShown && !isNoFilters && isDesktop;

  return (
    <Styled.FilterWrapper
      id="sidebar_filters_products"
      // @ts-expect-error // TODO: fix types
      isFilterShown={showFilter || isSearching}
      isFilterRemoved={isSearchLite && !isCuratedCollection}
    >
      {isSearching ? (
        <FiltersSkeleton />
      ) : (
        <Sticky
          enabled={isStickyEnabled}
          top={topPosition}
          bottomBoundary="#paginationWrapper"
          innerZ={3}
          ref={stickyRef}
        >
          <FilterContainer onCollapseChange={handleCollapseChange} />
        </Sticky>
      )}
    </Styled.FilterWrapper>
  );
};

export default FilterWrapper;
