import React from 'react';

import FilterOption from 'components/Filters/FilterOption';
import { SWITCHES_FIELDS } from '../../../utils';
import { areComponentPropsEqual } from '../../../utils';

const FilterSwitch = React.memo(
  // @ts-expect-error // TODO: fix types
  ({ data: filter, appFilters, onToggleSwitch }) => {
    const isOriginalSwitch = SWITCHES_FIELDS.includes(filter?.field);
    const filterId = isOriginalSwitch
      ? `filter.${filter?.field}=true`
      : filter?.id;
    const isFilterActive = Boolean(appFilters[filterId]);

    const handleChange = () => {
      if (filter == null) {
        return;
      }

      onToggleSwitch?.(
        {
          label: filter?.label,
          value: isOriginalSwitch ? !isFilterActive : filter.value,
          id: filterId,
          field: filter?.field,
        },
        isFilterActive,
      );
    };

    return (
      // @ts-expect-error // TODO: fix types
      <FilterOption
        isSwitch
        label={filter?.label}
        isSelected={isFilterActive}
        onClick={handleChange}
      />
    );
  },
  areComponentPropsEqual,
);

FilterSwitch.displayName = 'FilterSwitch';

export { FilterSwitch };
