// @ts-nocheck TODO: fix types for the entire file

import styled from '@emotion/styled';

export const CirlcesWrapper = styled.div<{ isRainbow?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .colorwheelIcon {
    margin: 0 6px 16px 6px;
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    transform: scale(1);
    &:hover {
      transform: scale(1.1);
    }
    box-shadow: ${({ isRainbow, theme }) =>
      isRainbow
        ? `0 0 0 2px #fff, 0px 0px 0px 4px ${theme.palette.primary.main}`
        : 'none'};
  }
`;

export const ColorCircle = styled.div<{
  hexColor: string;
  showShadow?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
}>`
  margin: 0 6px 16px 6px;
  display: flex;
  width: 36px;
  height: 36px;
  background-color: ${({ hexColor }) => hexColor};
  border-radius: 50px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  opacity: ${({ isDisabled }) => (isDisabled ? '0.15' : '1')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  box-shadow: ${({ theme, showShadow, isActive }) =>
    showShadow
      ? `0px 0px 0px 1px ${theme.palette.secondary.light}`
      : isActive
      ? `0 0 0 2px #fff, 0px 0px 0px 4px ${theme.palette.primary.main}`
      : 'none'};
  transform: scale(1);
  &:hover {
    transform: scale(1.1);
  }
`;
