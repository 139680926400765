import { useRef, useEffect } from 'react';
import isEqual from 'lodash/isEqual';

export function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  //if the items are equal, keep the reference to the new one to avoid extra rerenders in case of arrays or objects
  if (isEqual(value, ref.current)) {
    return value;
  }
  return ref.current;
}
