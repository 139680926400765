import styled from '@emotion/styled';
import MBDropdown from 'components/Elements/MBDropdown/MBDropdown';
import TypographyComponent from 'components/Typography/Typography';

export const Dropdown = styled(MBDropdown)`
  position: absolute;
  z-index: 6;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const OptionName = styled(TypographyComponent)`
  margin-right: auto;
`;

export const SelectedIcon = styled(TypographyComponent, {
  shouldForwardProp: (prop) => !['show'].includes(String(prop)),
})<{ show: boolean }>`
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
`;
