import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getUserAttributes } from '@mb/lib/customer';
import { IntlShape } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import CategoriesContainer from './CategoriesContainer';
import * as Styled from './CategoriesContainer.styles';
import { CompareButton } from '../../components/CompareButton';
import { defaultMaxAmountOfProductsToAdd } from '../../config/constants';
import useAddToBag from '../../hooks/useAddToBag';
import useCurrentProject from '../../hooks/useCurrentProject';
import { definedTranslation } from '../../i18n';
import { appFiltersSelector } from '../../redux/selectors/filtersSelector';
import { searchResultsSelector } from '../../redux/selectors/searchSelector';
import { toggleFiltersSidebar } from '../../redux/sliceCreators/filtersSlice';
import { ProductSearchMaterial, SnackbarMessageType } from '../../types';
import { ResultsHeader } from '../ResultsHeader';

export type CategoriesOuterWrapperProps = {
  displayAddAllToCartButton?: boolean;
  displayHeaderFilterButton?: boolean;
  displayViewOptions?: boolean;
  intl: IntlShape;
  isWithCompareFeature?: boolean;
};

const CategoriesOuterWrapper: FC<CategoriesOuterWrapperProps> = ({
  displayAddAllToCartButton: incomingDisplayAddAllToCartButton = false,
  displayHeaderFilterButton = true,
  displayViewOptions = true,
  intl,
  isWithCompareFeature,
}) => {
  const addAllToCartButtonRef = useRef<HTMLButtonElement>(null);
  const { addToBag, isLoading: isBagLoading } = useAddToBag();
  const { currentProject } = useCurrentProject();
  const appFilters = useSelector(appFiltersSelector);
  const results = useSelector(searchResultsSelector);
  const dispatch = useDispatch();

  const cartLabels = {
    addAll: intl.formatMessage(definedTranslation.infoNavbar.addAllToCart),
    added: intl.formatMessage(definedTranslation.card.addedToCartLabel),
  };

  const maxAmountOfProductsToAdd = useMemo(() => {
    const customerData = getUserAttributes();

    return (
      customerData?.add_to_cart_max_limit || defaultMaxAmountOfProductsToAdd
    );
  }, []);

  const productsToAdd = useMemo(
    () =>
      results
        .filter(
          (product: ProductSearchMaterial) =>
            !product?.productFinishType?.includes('Finish') &&
            !product?.isDigital,
        )
        .slice(0, maxAmountOfProductsToAdd),
    [maxAmountOfProductsToAdd, results],
  );

  const resultsHaveProducts = useMemo(
    () => productsToAdd.length < results.length,
    [productsToAdd.length, results.length],
  );

  const totalAmountOfItemsCanAdded = useMemo(
    () => productsToAdd.length,
    [productsToAdd.length],
  );

  const displayAddAllToCartButton = useMemo(
    () =>
      incomingDisplayAddAllToCartButton &&
      totalAmountOfItemsCanAdded !== 0 &&
      results.length <= maxAmountOfProductsToAdd &&
      results.length !== 0,
    [
      incomingDisplayAddAllToCartButton,
      maxAmountOfProductsToAdd,
      results.length,
      totalAmountOfItemsCanAdded,
    ],
  );

  const selectedCategoryName = useMemo(
    () =>
      Object.keys(appFilters).length === 0
        ? ''
        : // @ts-expect-error // TODO: fix types
          Object.values(appFilters)[0]?.label.split('>')[1],
    [appFilters],
  );

  const addToCartButtonContentInitialState = useMemo(
    () => (
      <>
        {cartLabels.addAll}
        <Styled.AddAllToCartButtonBadge>
          {totalAmountOfItemsCanAdded}
        </Styled.AddAllToCartButtonBadge>
      </>
    ),
    [selectedCategoryName, totalAmountOfItemsCanAdded, intl],
  );

  const [addToCartButtonContent, setAddToCartButtonContent] = useState(
    addToCartButtonContentInitialState,
  );
  const [addToCartButtonRect, setAddToCartButtonRect] =
    useState<Record<string, number>>();

  const handleFilterToggle = () => {
    dispatch(toggleFiltersSidebar(null));
  };

  const handleSuccess = useCallback(() => {
    setAddToCartButtonContent(
      <>
        <Styled.AddAllToCartCheckIcon />
        {cartLabels.added}
      </>,
    );
    setTimeout(() => {
      setAddToCartButtonContent(addToCartButtonContentInitialState);
    }, 2000);
  }, [addToCartButtonContentInitialState, intl]);

  const handleAddToAllCartButtonClick = useCallback(() => {
    const customSuccessMessage = resultsHaveProducts
      ? {
          messagePayload: {
            totalAmountOfAddedItems: totalAmountOfItemsCanAdded,
          },
          messageType:
            SnackbarMessageType.ALL_ITEMS_ADDED_TO_CART_EXCEPT_FINISHES,
        }
      : {
          messagePayload: {
            totalAmountOfAddedItems: totalAmountOfItemsCanAdded,
          },
          messageType: SnackbarMessageType.ALL_ITEMS_ADDED_TO_CART,
        };

    addToBag({
      materials: productsToAdd,
      projectId: String(currentProject?.project_id),
      triggerLocation: 'Curated Collection',
      isFinishMaterial: false,
      shouldTriggerModal: true,
      customSuccessMessage,
      onSuccess: handleSuccess,
    });

    window.splitFactory
      ?.client()
      ?.track('user', 'mb_monolith_add_to_cart', undefined, {
        'ATC-collections': 'ATC-add-all',
        'ATC-location': 'collections',
      });
  }, [
    addToBag,
    currentProject?.project_id,
    handleSuccess,
    productsToAdd,
    resultsHaveProducts,
    totalAmountOfItemsCanAdded,
  ]);

  useEffect(() => {
    const element = addAllToCartButtonRef.current;

    if (!element) {
      setAddToCartButtonRect(undefined);

      return;
    }

    const rect = element.getBoundingClientRect();

    setAddToCartButtonRect({
      height: rect.height,
      left: element.offsetLeft,
      top: element.offsetTop,
      width: rect.width,
    });
  }, [addToCartButtonContent, displayAddAllToCartButton]);

  useEffect(() => {
    setAddToCartButtonContent(addToCartButtonContentInitialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalAmountOfItemsCanAdded]);

  const allButtonText = intl.formatMessage(
    definedTranslation.filters.filtersButton,
  );

  return (
    <Styled.OuterWrapper>
      {displayHeaderFilterButton && (
        <Styled.FilterButton
          color="inherit"
          iconDense
          label={allButtonText}
          onClick={handleFilterToggle}
          size="medium"
          startIcon="filters"
          variant="outlined"
        />
      )}
      <CategoriesContainer
        addToCartButtonWidth={addToCartButtonRect?.width}
        fullWidth={incomingDisplayAddAllToCartButton}
      />
      {displayViewOptions && <ResultsHeader intl={intl} />}
      {isWithCompareFeature && <CompareButton />}
      {displayAddAllToCartButton && (
        <Styled.AddAllToCartButtonWrapper>
          <Styled.AddAllToCartButton
            color="primary"
            disabled={isBagLoading}
            onClick={handleAddToAllCartButtonClick}
            ref={addAllToCartButtonRef}
            size="medium"
            variant="contained"
          >
            {addToCartButtonContent}
          </Styled.AddAllToCartButton>
        </Styled.AddAllToCartButtonWrapper>
      )}
    </Styled.OuterWrapper>
  );
};

export default CategoriesOuterWrapper;
