// @ts-nocheck TODO: fix types for the entire file
import styled from '@emotion/styled';
import { Button } from 'components/Buttons/Button';
import { Check } from 'icons';

export const CatagoriesWrapper = styled.div<{
  addToCartButtonWidth?: number;
  fullWidth: boolean;
}>`
  position: relative;
  width: 100%;
  flex-shrink: 1;
  min-width: 0px;
  ${({ theme }) => theme.breakpoints.down(767)} {
    padding-top: 0;
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const OuterWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  ${({ theme }) => theme.breakpoints.down(767)} {
    flex-wrap: wrap;
  }
`;

export const FilterButton = styled(Button)`
  margin-right: 1em;
  flex-shrink: 0;
  border-radius: 8px;
  & > span:first-of-type {
    margin-right: 10px;
  }
  ${({ theme }) => theme.breakpoints.down(1025)} {
    display: none;
  }
`;

export const AddAllToCartButton = styled(Button)`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  @media (max-width: 767px) {
    padding: 18px 0;
    width: 80%;
  }
`;

export const AddAllToCartCheckIcon = styled(Check)`
  width: 22px;
  height: 22px;
`;

export const AddAllToCartButtonBadge = styled.div`
  border-radius: 9999px;
  background-color: white;
  color: black;
  padding-left: 0.35rem;
  padding-right: 0.35rem;
  padding-top: 0.25rem;
  padding-bottom: 0.2rem;
  font-size: 10px;
  line-height: 1;
  font-weight: bold;
`;

export const AddAllToCartButtonWrapper = styled.div`
  display: flex;
  width: auto;
  justify-content: flex-end;
  padding-left: 1rem;
  @media (max-width: 767px) {
    display: block;
    position: fixed;
    width: 100%;
    bottom: 20px;
    left: 0;
    padding: 0 20px;
  }
`;
