import orderBy from 'lodash/orderBy';

export const getSortedItems = (items: any = []) => {
  return orderBy(items, ['active', 'count'], ['desc', 'desc']);
};

export const getLabel = (label: string = '', position = 0) => {
  if (label.includes('>')) {
    return label.split('>')?.[position];
  } else {
    return label.split('/')?.[position];
  }
};
