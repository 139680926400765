import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

export const BackWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const TextLink = styled.a`
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;
