// @ts-nocheck TODO: fix types for the entire file

import styled from '@emotion/styled';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Button } from 'components/Buttons/Button';
import MBDialog from 'components/Elements/MBDialog';
import TypographyComponent from 'components/Typography/Typography';
import palette from 'theme/palette';

export const ShareModal = styled(MBDialog)`
  .MuiDialogContent-root {
    margin-bottom: 0;
  }
`;

export const StyledTableRow = styled(TableRow)`
  &:nth-of-type(even) {
    background-color: ${palette.secondary.main};
  }
`;

export const StyledTable = styled(Table)`
  table-layout: fixed;
  min-width: 768px;
`;

export const DifferenceLabel = styled(TypographyComponent)`
  cursor: pointer;
  user-select: none;
`;

export const CompareButton = styled(Button)``;
export const BackButton = styled(Button)`
  height: 36px;
  &:hover,
  &:active,
  &:focus {
    background-color: ${palette.secondary.light} !important;
    color: ${palette.primary.main};
  }
`;
export const ExportButton = styled(Button)`
  height: 36px;
`;
export const ShareButton = styled(Button)`
  height: 36px;
  &:hover {
    background-color: ${palette.grey[200]};
  }
  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
  }
`;

export const StyledTableCell = styled(TableCell)`
  position: relative;
  border-right: 1px solid ${palette.secondary.light};
  border-bottom: 0;
  &:first-of-type {
    font-weight: 600;
  }
  &:last-of-type {
    border-right: 0;
  }
  & > div:only-of-type {
    max-width: 300px;
    margin: auto;
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px 0;
`;

export const TopbarWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Headline = styled(TypographyComponent)`
  font-weight: 600;
  width: 20%;
  text-align: center;
`;

export const ProjectsSelectorWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  & > *:first-of-type {
    position: relative;
    width: auto;
    padding: 0;
  }
`;

export const BackButtonWrapper = styled.div`
  width: 40%;
  display: flex;
`;

export const RightSideBar = styled.div`
  position: relative;
  width: 40%;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
`;

export const PreLabel = styled(TypographyComponent)`
  font-size: 16px;
  color: ${palette.grey.A400};
  top: -2px;
  right: 2px;
  position: relative;
`;

export const ModalWrapper = styled.div`
  border-radius: 32px;
`;

export const PdfWrapper = styled.div`
  p .MuiTypography-body1 {
    height: 24px !important;
    line-height: 12px !important;
    display: flex !important;
    align-items: center !important;
  }

  p .MuiTypography-noWrap {
    height: 24px !important;
    line-height: 12px !important;
    display: flex !important;
    align-items: center !important;
  }

  .product-title {
    height: 24px !important;
    line-height: 6px !important;
    display: flex !important;
    align-items: center !important;
  }

  .product-category-name {
    height: 18px !important;
    line-height: 9px !important;
    display: flex !important;
    align-items: center !important;
  }

  .product-info-container {
    height: 64px !important;
  }
`;
