import React from 'react';
import { Box } from '@mui/system';
import { FilterSkeleton } from 'components/Filters/FilterSkeleton';
import { useMediaQuery } from 'react-responsive';
import * as Styled from '../../containers/AppContainer/AppContainer.styles';

const FiltersSkeleton = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  return (
    <Box mt="1em">
      {/* @ts-expect-error // TODO: fix types */}
      <Styled.FilterWrapper isFilterShown={!isMobile}>
        {Array.from({ length: 3 }).map((_, index) => {
          return (
            <Box mb="1.5em" key={index} maxWidth="180px">
              <FilterSkeleton animation={false} filterItemsCount={4} />
            </Box>
          );
        })}
      </Styled.FilterWrapper>
    </Box>
  );
};

export default FiltersSkeleton;
