import React, { useCallback } from 'react';
import { Sorting, SortItem } from '../Sorting';
import * as Styled from './MobileSort.styles';

type MobileSortProps = {
  open: boolean;
  onSort?: (sortItem: SortItem) => void;
  onClose: () => void;
};

const MobileSort = (props: MobileSortProps) => {
  const onSort = useCallback(
    (sortItem: SortItem) => {
      props.onSort?.(sortItem);
      props.onClose?.();
    },
    [props.onSort, props.onClose],
  );

  return (
    <Styled.SortDialog
      open={props.open}
      onClose={props.onClose}
      mobileFullscreen={true}
      showCloseIcon={true}
      title="Sort by"
    >
      <Sorting onSort={onSort} />
    </Styled.SortDialog>
  );
};

export default MobileSort;
