import { config } from '../../config';
import { DefaultSnackbarMessage } from '../../constants/notification';
import { setSnackbarInfo } from '../../redux/sliceCreators/commonSlice';
import { ResponseStatusEnum, SnackbarStatusEnum } from '../../types';
import { client } from '../http';

// @ts-expect-error // TODO: fix types
export const exportToCSV = (productIds?: number[]) => async (dispatch) => {
  try {
    const { data } = await client.get(
      `${config.endpoints.exportToCSV}${
        productIds != null && productIds.length ? `/${productIds.join()}` : ''
      }`,
    );
    if (data.status === ResponseStatusEnum.FAILED) {
      dispatch(
        setSnackbarInfo({
          isOpen: true,
          status: SnackbarStatusEnum.DANGER,
          message: data?.message ?? DefaultSnackbarMessage.SOMETHING_WENT_WRONG,
        }),
      );
      return;
    }
    const url = new URL(data.file_ur_l);

    window.open(
      `${window.origin}${url.href.replace(url.origin, '')}`,
      '_blank',
    );
  } catch (error) {
    console.log('Error exportToCSV:', error);
    dispatch(
      setSnackbarInfo({
        isOpen: true,
        status: SnackbarStatusEnum.DANGER,
        message: error?.message ?? DefaultSnackbarMessage.SOMETHING_WENT_WRONG,
      }),
    );
  }
};

// @ts-expect-error // TODO: fix types
export const exportToPDF = (productIds: number[]) => async (dispatch) => {
  try {
    const { data } = await client.get(
      config.endpoints.exportToPDF(productIds, 'materials'),
    );
    if (data.status === ResponseStatusEnum.FAILED) {
      console.log('Error exportToPDF:', data);

      dispatch(
        setSnackbarInfo({
          isOpen: true,
          status: SnackbarStatusEnum.DANGER,
          message: data?.message ?? DefaultSnackbarMessage.SOMETHING_WENT_WRONG,
        }),
      );
      return;
    }
    window.open(data.zip_file_url, '_blank');
  } catch (error) {
    console.log('Error exportToPDF:', error);
    dispatch(
      setSnackbarInfo({
        isOpen: true,
        status: SnackbarStatusEnum.DANGER,
        message: error?.message ?? DefaultSnackbarMessage.SOMETHING_WENT_WRONG,
      }),
    );
  }
};
