// @ts-nocheck TODO: fix types for the entire file

import styled, { StyledComponent } from '@emotion/styled';
import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';
import { Button } from 'components/Buttons/Button';
import { IconButton } from 'components/Buttons/IconButton';
import TypographyComponent from 'components/Typography/Typography';
import { pxToEm } from 'theme';
import palette from 'theme/palette';

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const ItemsList = styled.div`
  display: flex;
  gap: 8px;
`;
export const DeleteButton = styled(IconButton)`
  position: absolute;
  z-index: 3;
  top: 8px;
  left: 8px;
  opacity: 0;
  width: 32px;
  height: 32px;
  &:hover {
    background: ${palette.common.white};
    color: ${palette.primary.main};
  }
`;

export const Cell = styled.div<{ isActive?: boolean; imageUrl?: string }>`
  display: flex;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  border: 1px dashed ${palette.primary.main};
  position: relative;
  &:hover {
    ${DeleteButton} {
      opacity: 1;
    }
  }
  ${({ isActive, imageUrl }) =>
    isActive &&
    `
        border: 0;
        background-image: url('${imageUrl}');
        background-position: center;
        background-size: cover;
        &:hover {
            &:before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: rgba(0,0,0,0.3);
                    pointer-event: none;
                    border-radius: 12px;
                }
        }
    `}
`;

export const CancelButton = styled(Button)`
  &:hover,
  &:active,
  &:focus {
    background-color: ${palette.secondary.light} !important;
    color: ${palette.primary.main};
  }
`;
export const CompareButton = styled(Button)``;

export const Header = styled(TypographyComponent)`
  font-size: 20px;
  line-height: 1.6;
  font-weight: 600;
`;

export const Tooltip: StyledComponent<TooltipProps> = styled(MuiTooltip)`
  & ~ .MuiTooltip-popper {
    .MuiTooltip-tooltip {
      margin-top: 0.5em;
      max-width: ${pxToEm(200, false, 12)};
      background: ${palette.primary.main};
      color: ${palette.common.white};
      font-size: 12px;
      padding: 7px 12px;
      min-height: ${pxToEm(24, false, 12)};
      border-radius: ${pxToEm(24, false, 12)};
      text-align: center;
      span {
        white-space: pre-wrap;
        font-size: 11px;
      }
    }
  }
`;
