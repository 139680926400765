// @ts-nocheck TODO: fix types for the entire file

import styled from '@emotion/styled';
import MuiDialogActions from '@mui/material/DialogActions';
import { Button as ThemeButton } from 'components/Buttons/Button';
import TypographyComponent from 'components/Typography/Typography';
import { theme } from 'theme';
import pxToEm from 'theme/pxToEm';

export interface StyledProps {
  imageUrl?: string;
}

export const Button = styled(ThemeButton)`
  font-size: ${pxToEm(12)};
  padding: 0 1.5em;
  min-height: 32px;
  height: 32px;
  small {
    font-size: 100%;
    margin-left: 0.25em;
    font-weight: 400;
  }
  &:not(:first-of-type) {
    margin: ${pxToEm(16, false, 12)} 0 0;
  }
`;

export const Previews = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const Preview = styled.div<Partial<StyledProps>>`
  width: ${pxToEm(124)};
  height: ${pxToEm(134)};
  border-radius: ${pxToEm(10)};
  background-image: url('${(props) => props.imageUrl}');
  background-color: ${theme.palette.grey[200]};
  border: 2px solid ${theme.palette.common.white};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 5%);
  &:nth-child(1) {
    z-index: 5;
  }
  &:not(:first-of-type) {
    margin-left: ${pxToEm(-100)};
  }
  &:nth-child(2) {
    z-index: 4;
  }
  &:nth-child(3) {
    z-index: 3;
  }
  &:nth-child(4) {
    z-index: 2;
  }
  &:nth-child(5) {
    z-index: 1;
  }
`;

export const PreviewsNumber = styled(TypographyComponent)`
  line-height: 2.5;
`;

export const DialogActions = styled(MuiDialogActions)`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 1em 0 0;
`;
