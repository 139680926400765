import { useEffect, useRef } from 'react';

import { ProductSearchMaterial } from '../types';

export const useViewScrollableProductsListEvent = (params: {
  sendAnalyticsEvent(products: ProductSearchMaterial[]): void;
}) => {
  const { sendAnalyticsEvent } = params ?? {};

  const productsRef = useRef<ProductSearchMaterial[]>([]);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    return () => {
      if (productsRef.current.length > 0) {
        sendAnalyticsEvent?.(productsRef.current);
      }

      clearTimeout(timeoutRef.current);
    };
  }, []);

  const onProductInViewChange = (
    product: ProductSearchMaterial,
    inView: boolean,
  ) => {
    if (inView) {
      productsRef.current = [...productsRef.current, product];

      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        sendAnalyticsEvent?.(productsRef.current);
        productsRef.current = [];
        timeoutRef.current = undefined;
      }, 300);
    }
  };

  return { onProductInViewChange };
};
