import React, { FC } from 'react';
import { useQuery, gql } from '@apollo/client';
import MBDialog from 'components/Elements/MBDialog';
import DOMPurify from 'dompurify';
import he from 'he';
import { useDispatch } from 'react-redux';
import { setCMSModalOpen } from '../../../redux/sliceCreators/commonSlice';

const GET_CMS_BLOCKS = gql`
  query GetCMSBlocks($identifiers: [String]) {
    cmsBlocks(identifiers: $identifiers) {
      items {
        identifier
        title
        content
      }
    }
  }
`;

const sanitizeData = (data: string) => {
  return DOMPurify.sanitize(data);
};
interface CMSModalProps {
  blockId: string;
}

export const CMSModal: FC = ({ blockId, ...props }: CMSModalProps) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setCMSModalOpen({ status: false, id: '' }));
  };
  const { loading, error, data } = useQuery(GET_CMS_BLOCKS, {
    variables: { identifiers: blockId },
  });

  if (loading) return null;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <MBDialog
      open={!loading}
      id="content_blocks_CMS_modal"
      title=""
      onClose={handleClose}
      showCloseIcon
      titleBottomPadding={16}
      fullScreenModal
      doNotScrollContent
      shouldDisableBottomPadding
      sx={{ zIndex: 10 }}
      {...props}
    >
      <div className="cms-modal">
        {data.cmsBlocks.items.map(
          (item: { identifier: string; title: string; content: string }) => (
            <div key={item.identifier} className="cms-item">
              <h2>{item.title}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeData(he.decode(item.content)),
                }}
              />
            </div>
          ),
        )}
      </div>
    </MBDialog>
  );
};
