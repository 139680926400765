import React from 'react';
import MBSwitch from 'components/Elements/MBSwitch/MBSwitch';
import TypographyComponent from 'components/Typography/Typography';
import * as Styled from './MultiselectNavbar.styles';
import { MultiselectNavbarProps } from './MultiselectNavbar.types';

export const compareButtonId = 'multiselect_compare_button';

const MultiselectNavbar: React.FC<MultiselectNavbarProps> = ({
  selectedNumber = 0,
  addToBagTooltip,
  selectAllTooltip,
  onAddToBoard,
  onAddToBag,
  onSelectAll,
  onCancel,
  onExportClick,
  onDeleteClick,
  onSampleSwitchClick,
  isAddToBagDisabled = false,
  shouldShowDeleteButton = false,
  isDeleteDisabled = false,
  isSamplesSwitchEnabled = false,
  shouldShowSamplesSwitch = false,
  isSelectAllDisabled = false,
  shouldShowExportImport = true,
  shouldShowNumberItemsSelected = true,
  shouldReverseSelectSection = false,
  shouldSeparateSelectButtons = false,
  showSaveButton = true,
  addToCartButtonStartIconName,
  showAddToCartButton = true,
  showSelectActionBar = true,
  addToCartButtonLabel = 'Add to Cart',
  saveButtonLabel = 'Add to Board',
  selectAllButtonLabel = 'Select All',
  cancelButtonLabel = 'Cancel',
  shareExportButtonTooltip = 'Share/Export',
  itemsSelectedLabel = 'items selected',
  onlySamplesLabel = 'Only Samples',
  compareButtonLabel = 'Compare',
  compareDisabledText,
  isWithCompareFeature,
  onCompareClick,
  isCompareButtonDisabled = false,
}) => {
  const handleSampleSwitchClick = () => {
    onSampleSwitchClick?.(isSamplesSwitchEnabled);
  };

  const addToCartBtn = showAddToCartButton ? (
    <Styled.Tooltip
      title={addToBagTooltip != null ? <span>{addToBagTooltip}</span> : ''}
      disableHoverListener={addToBagTooltip == null}
      PopperProps={{
        disablePortal: true,
      }}
    >
      {/* Span required to trigger tooltip, if Button is disabled */}
      <span style={{ display: 'inline-flex' }}>
        <Styled.Button
          id="multiselect_cart_button"
          startIcon={addToCartButtonStartIconName}
          label={addToCartButtonLabel}
          disabled={isAddToBagDisabled}
          variant="contained"
          size="medium"
          color="primary"
          onClick={onAddToBag}
        />
      </span>
    </Styled.Tooltip>
  ) : null;

  const numbersSelected = shouldShowNumberItemsSelected ? (
    <Styled.NavbarSection>
      <TypographyComponent variant="body">
        <span id="selected_items_number">{selectedNumber}</span>{' '}
        {itemsSelectedLabel}
      </TypographyComponent>
    </Styled.NavbarSection>
  ) : null;

  const actionBar =
    selectedNumber > 0 && showSelectActionBar ? (
      <Styled.WrapperActionBar justifyContent="center" px="1em" gap="0.5em">
        {shouldShowDeleteButton && (
          <Styled.Tooltip
            title="Remove"
            PopperProps={{
              disablePortal: true,
            }}
          >
            <Styled.IconButton
              id="multiselect_delete_button"
              dense
              iconName="delete"
              color="inherit"
              onClick={onDeleteClick}
              disabled={isDeleteDisabled}
            />
          </Styled.Tooltip>
        )}
        {shouldShowExportImport && (
          <Styled.Tooltip
            title={shareExportButtonTooltip}
            PopperProps={{
              disablePortal: true,
            }}
          >
            <Styled.IconButton
              id="multiselect_share_export_button"
              dense
              iconName="share"
              color="inherit"
              onClick={onExportClick}
            />
          </Styled.Tooltip>
        )}
        {showSaveButton && (
          <Styled.Button
            id="multiselect_save_button"
            label={saveButtonLabel}
            variant="contained"
            size="medium"
            color="secondary"
            onClick={onAddToBoard}
          />
        )}
        {isWithCompareFeature && (
          <Styled.Tooltip
            title={compareDisabledText}
            disableHoverListener={compareDisabledText == null}
            PopperProps={{
              disablePortal: true,
            }}
          >
            <span style={{ display: 'inline-flex' }}>
              <Styled.Button
                id={compareButtonId}
                label={compareButtonLabel}
                variant="contained"
                size="medium"
                color="secondary"
                onClick={onCompareClick}
                disabled={isCompareButtonDisabled}
              />
            </span>
          </Styled.Tooltip>
        )}
        {addToCartBtn}
      </Styled.WrapperActionBar>
    ) : null;

  const samplesSwitch = shouldShowSamplesSwitch ? (
    <Styled.SwitchWrapper
      id="multiselect_only_samples"
      onClick={handleSampleSwitchClick}
    >
      <MBSwitch checked={isSamplesSwitchEnabled} color="default" />
      <TypographyComponent variant="body" ml="0.35em" whiteSpace={'nowrap'}>
        {onlySamplesLabel}
      </TypographyComponent>
    </Styled.SwitchWrapper>
  ) : null;

  return (
    <Styled.NavbarWrapper>
      {numbersSelected}
      {actionBar}
      <Styled.NavbarSection
        justifyContent={
          shouldSeparateSelectButtons ? 'space-between' : 'flex-end'
        }
        gap="0.5em"
        flexDirection={shouldReverseSelectSection ? 'row-reverse' : 'initial'}
      >
        {samplesSwitch}
        <Styled.Tooltip
          title={selectAllTooltip || ''}
          disableHoverListener={selectAllTooltip == null}
          PopperProps={{
            disablePortal: true,
          }}
        >
          {/* Span required to trigger tooltip, if Button is disabled */}
          <span style={{ display: 'inline-flex' }}>
            <Styled.Button
              disabled={isSelectAllDisabled}
              label={selectAllButtonLabel}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={() => onSelectAll?.()}
            />
          </span>
        </Styled.Tooltip>
        <Styled.CancelButton
          label={cancelButtonLabel}
          variant="contained"
          size="medium"
          color="secondary"
          onClick={() => onCancel?.()}
        />
      </Styled.NavbarSection>
    </Styled.NavbarWrapper>
  );
};

export default MultiselectNavbar;
