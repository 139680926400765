import React, { FC } from 'react';
import { AnalyticsEventLocation } from '@mb/lib';
import { SplitFeatureFlag, ContentBlocksTreatment } from '@mb/lib/splitio';
import useCurrentProject from '../../../hooks/useCurrentProject';
import { CategoryPill } from '../CategoryPill';
import { DynamicIcon } from '../icons/DynamicIcon';
import { CardAttributesType } from '../types';
import styles from './modalSidebar.module.scss';

interface ModalSidebarProps {
  id: string;
  index: number;
  cardAttributes: CardAttributesType;
  categories: [
    {
      value: string;
      name: string;
    },
  ];
  activeFilters: string[];
  onCategoryClick: (value: string) => void;
}

export const ModalSidebar: FC<ModalSidebarProps> = ({
  id,
  index,
  cardAttributes,
  categories,
  activeFilters,
  onCategoryClick,
}) => {
  const { currentProject } = useCurrentProject();

  const handleCategoryClick = (value: string) => {
    dataLayer.push({
      event: 'contentblock_filter_click',
      contentblock_title: cardAttributes.title,
      contentblock_id: id,
      contentblock_type: cardAttributes.type,
      contentblock_template: cardAttributes.template,
      event_location: AnalyticsEventLocation.CATALOG,
      contentblock_location: index,
      event_project_id: currentProject?.project_id,
      interaction_element: value,
      experiment_name: SplitFeatureFlag.CONTENT_BLOCKS,
      experiment_version: ContentBlocksTreatment.ON,
    });
    onCategoryClick(value);
  };
  return (
    <div
      className={`${styles.modalSidebar} ${styles[cardAttributes.template]}`}
    >
      <div className={styles.modalSidebarIcon}>
        <DynamicIcon iconUrl={cardAttributes.iconType} />
      </div>
      <h2 className={styles.modalSidebarTitle}>{cardAttributes.title}</h2>
      <p className={styles.modalSidebarDescription}>
        {cardAttributes.description}
      </p>
      <ul className={styles.modalSidebarCategoryList}>
        {categories.map((category) => (
          <li key={category.value}>
            <CategoryPill
              value={category.value}
              name={category.name}
              selected={activeFilters.includes(category.value)}
              theme={cardAttributes.template}
              onHandleCategoryClick={handleCategoryClick}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
