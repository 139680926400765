import { useSelector } from 'react-redux';
import { currentProjectIdSelector } from '../redux/selectors/projectsSelector';
import { projectsListSelector } from '../redux/selectors/projectsSelector';
import { IProject } from '../types';
import { getUserAttributes } from '../utils/localstorage';

const useCurrentProject = () => {
  const userData = getUserAttributes();
  const reduxCurrentProjectId = useSelector(currentProjectIdSelector);
  const currentProjectId = reduxCurrentProjectId ?? userData?.current_project;
  const projects: IProject[] = useSelector(projectsListSelector);

  const currentProject: IProject | null | undefined = currentProjectId
    ? projects?.find(
        (project) => Number(project?.project_id) === Number(currentProjectId),
      )
    : projects?.length
    ? projects?.[0]
    : null;

  return { currentProject };
};

export default useCurrentProject;
