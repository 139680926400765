import { formatNumberWithThreshold } from './formatNumberWithThreshold';

export const calculateCBRows = (
  index: number,
  cbRows: Record<number, number>,
  isMobile: boolean,
): string => {
  const desktopMap = [1, 3, 6, 8, 10, 12, 14, 16];
  const mobileMap = [3, 8, 9, 10, 11, 12, 13, 14];
  let calculatedRow = 0;
  if (isMobile) {
    calculatedRow = mobileMap[cbRows[index]];
  } else {
    calculatedRow = desktopMap[cbRows[index]];
  }

  return `${isMobile ? 'm' : 'd'}-${formatNumberWithThreshold(
    calculatedRow,
    isMobile ? 7 : 5,
  )}`;
};
