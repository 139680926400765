import { useCallback, useEffect, useState } from 'react';

type FlagState<T> = {
  flagValue: T | null;
  isLoading: boolean;
};

// Retrieve feature flag value from AB Tasty by a unique flag name
export const useAbtFeatureFlag = <T>(flagName: string) => {
  const [flagState, setFlagState] = useState<FlagState<T>>({
    flagValue: null,
    isLoading: true,
  });
  // set flag value and finish loading, set flag only once
  const setFlagValue = useCallback((flagValue: T) => {
    setFlagState((prevState) =>
      prevState.isLoading ? { flagValue, isLoading: false } : { ...prevState },
    );
  }, []);

  // retrieves the fetched flag and sets it into the state
  const handleSetFetchedFlagValue = () => {
    const newFlagValue = window.mb?.abtConfig.getFlag(flagName);
    setFlagValue(newFlagValue as T);
  };

  useEffect(() => {
    if (window.mb?.abtConfig.areFlagsFetched) {
      handleSetFetchedFlagValue();
    } else {
      window.addEventListener(
        'abt_visitor_initialized',
        handleSetFetchedFlagValue,
      );
    }

    return () => {
      window.removeEventListener(
        'abt_visitor_initialized',
        handleSetFetchedFlagValue,
      );
    };
  }, []);

  return { flagState };
};
