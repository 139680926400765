import { IntlShape } from 'react-intl';
import { definedTranslation } from '../../../i18n';

interface CardLabels {
  addToCartLabel: string;
  seeSimilarLabel: string;
  addedToCartLabel: string;
  createProjectButtonLabel: string;
  digitalCatelogLabel: string;
  inStockLabel: string;
  learnMoreLabel: string;
  outOfStockLabel: string;
  projectPreLabel: string;
  saveButtonLabel: string;
  searchPlaceholderLabel: string;
  checkboxTooltipTitle: string;
}

interface ListViewLabels {
  shipping: string;
  price: string;
  usage: string;
  materials: string;
  sector: string;
}

interface InternalLabels {
  newLabel: string;
  digitalCatalogLabel: string;
  justAddedLabel: string;
  colorwaysLabel: string;
  orderNow: string;
  finishes: string;
  seeSimilar: string;
  listView: ListViewLabels;
  sampleFinishesLabel: string;
  previewFilesLabel: string;
}

export interface ProductCardLabels {
  cardLabels: CardLabels;
  internalLabels: InternalLabels;
}

// get product card labels for i18n
export const getProductCardLabels = (intl: IntlShape): ProductCardLabels => {
  const cardLabels = {
    addToCartLabel: intl?.formatMessage(definedTranslation.card.addToCartLabel),
    seeSimilarLabel: intl?.formatMessage(
      definedTranslation.card.seeSimilarLabel,
    ),
    addedToCartLabel: intl?.formatMessage(
      definedTranslation.card.addedToCartLabel,
    ),
    createProjectButtonLabel: intl?.formatMessage(
      definedTranslation.card.createProjectButtonLabel,
    ),
    digitalCatelogLabel: intl?.formatMessage(
      definedTranslation.card.digitalCatalogLabel,
    ),
    inStockLabel: intl?.formatMessage(definedTranslation.card.inStockLabel),
    learnMoreLabel: intl?.formatMessage(definedTranslation.card.learnMoreLabel),
    outOfStockLabel: intl?.formatMessage(
      definedTranslation.card.outOfStockLabel,
    ),
    projectPreLabel: intl?.formatMessage(
      definedTranslation.card.projectPreLabel,
    ),
    saveButtonLabel: intl?.formatMessage(
      definedTranslation.card.saveButtonLabel,
    ),
    searchPlaceholderLabel: intl?.formatMessage(
      definedTranslation.filters.searchPlaceholder,
    ),
    checkboxTooltipTitle: intl?.formatMessage(
      definedTranslation.compare.compareLimitReachedlabel,
    ),
  };
  const internalLabels = {
    newLabel: intl?.formatMessage(definedTranslation.card.newLabel),
    digitalCatalogLabel: intl?.formatMessage(
      definedTranslation.card.digitalCatalogLabel,
    ),
    justAddedLabel: intl?.formatMessage(definedTranslation.card.justAddedLabel),
    colorwaysLabel: intl?.formatMessage(definedTranslation.card.colorwaysLabel),
    orderNow: intl?.formatMessage(definedTranslation.card.orderNow),
    finishes: intl?.formatMessage(definedTranslation.card.finishes),
    seeSimilar: intl?.formatMessage(definedTranslation.card.seeSimilarLabel),
    previewFilesLabel: intl?.formatMessage(
      definedTranslation.card.previewFilesLabel,
    ),
    listView: {
      shipping: intl?.formatMessage(
        definedTranslation.card.listView.shippingLabel,
      ),
      price: intl?.formatMessage(definedTranslation.card.listView.pricelabel),
      usage: intl?.formatMessage(definedTranslation.card.listView.usageLabel),
      materials: intl?.formatMessage(
        definedTranslation.card.listView.materialsLabel,
      ),
      sector: intl?.formatMessage(definedTranslation.card.listView.sectorLabel),
    },
    sampleFinishesLabel: intl?.formatMessage(
      definedTranslation.card.sampleFinishesLabel,
    ),
  };

  return { cardLabels, internalLabels };
};
