import React from 'react';
import { TypographyComponent } from 'ui-widgets';
import * as Styled from './FilterItem.styles';

interface TooltipContentProps {
  title: string;
  subtitle: string;
}

// Tooltip Content for Filter Groups
const TooltipContent: React.FC<TooltipContentProps> = ({ title, subtitle }) => {
  return (
    <Styled.TooltipWrapper>
      <TypographyComponent fontSize="16px" lineHeight="1.5" fontWeight="600">
        {title}
      </TypographyComponent>
      <TypographyComponent fontSize="16px" lineHeight="1.5">
        {subtitle}
      </TypographyComponent>
    </Styled.TooltipWrapper>
  );
};

export { TooltipContent };
