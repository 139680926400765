import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Styled from './ColorCircles.styles';
import {
  colorShadesFilterSelector,
  isRainbowClickedSelector,
  primaryColorFilterSelector,
} from '../../../redux/selectors/filtersSelector';
import {
  addFilter,
  removeFilter,
  setIsColorCircleTopbarShown,
  setIsRainbowClicked,
} from '../../../redux/sliceCreators/filtersSlice';

const ColorCircles: React.FC<{ isShades?: boolean }> = ({ isShades }) => {
  const colorFacet = useSelector(primaryColorFilterSelector);
  const { allShades: shadesFacet } = useSelector(colorShadesFilterSelector);
  const isRainbowClicked = useSelector(isRainbowClickedSelector);
  const dispatch = useDispatch();
  const colorsList = isShades ? shadesFacet : colorFacet;

  // @ts-expect-error // TODO: fix types
  const handleCircleClick = (color, isRainbow) => {
    dispatch(setIsColorCircleTopbarShown(true));
    if (isShades) {
      if (color?.active) {
        dispatch(removeFilter(color));
      } else {
        dispatch(addFilter(color));
      }
      dispatch(setIsRainbowClicked(true));
      const shadeClickEvent = new CustomEvent('update-gradient-shades');
      window.dispatchEvent(shadeClickEvent);
    } else {
      if (!isRainbow && isRainbowClicked) {
        dispatch(setIsRainbowClicked(false));
      }
      const circleClickEvent = new CustomEvent('colorCircleClick', {
        detail: {
          color,
          isRainbow,
        },
      });
      window.dispatchEvent(circleClickEvent);
    }
  };

  return (
    <Styled.CirlcesWrapper isRainbow={isRainbowClicked}>
      {/* @ts-expect-error // TODO: fix types */}
      {colorsList?.map((item, index) => {
        return (
          <div key={index + item.hexcode}>
            <Styled.ColorCircle
              // Weird issue from React Spring/shared lib (01 March 2023),
              // if key is hexcode or label of actual shade color - it adding
              // additional shades
              key={index + item.hexcode}
              hexColor={item.hexcode}
              showShadow={item.label === 'White'}
              isActive={item.active}
              isDisabled={item.count === 0}
              onClick={() => handleCircleClick(item, false)}
            />
          </div>
        );
      })}
      {/* !isShades && (
                <ColorWheelIcon
                    wrapperClassName="colorwheelIcon"
                    onClick={() => handleCircleClick(null, true)}
                />
            )*/}
    </Styled.CirlcesWrapper>
  );
};

export default ColorCircles;
