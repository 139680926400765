import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import * as Styled from './FixedTopbar.styles';

import { isSearchPage } from '../../utils/helpers';

export type FixedTopbarProps = PropsWithChildren<ReactNode> & {
  displayHeaderFilters?: boolean;
};

/**
 * FixedTopbar is a functional component that renders a topbar
 * with optional header filters.
 *
 * @param {object} props - The properties object.
 * @param {React.ReactNode} props.children - The child elements to be displayed within the topbar.
 * @param {boolean} [props.displayHeaderFilters=false] - Determines whether header filters should be displayed.
 * @returns {JSX.Element} The rendered topbar component.
 */
const FixedTopbar: FC<FixedTopbarProps> = ({
  children,
  displayHeaderFilters = false,
}) => {
  const [stickyTop, setStickyTop] = useState(0);
  const windowWidth = useWindowWidth();
  const shouldSticky =
    (displayHeaderFilters || isSearchPage()) && windowWidth > 1024;

  const calcTopPosition = useCallback(() => {
    const headerHeight = (
      document.querySelectorAll('header.page-header')?.[0] as HTMLElement
    )?.offsetHeight;
    if (stickyTop !== headerHeight) {
      setStickyTop(headerHeight);
    }
  }, [stickyTop]);

  useEffect(() => {
    calcTopPosition();
    window.addEventListener('scroll', calcTopPosition);
    return () => {
      window.removeEventListener('scroll', calcTopPosition);
    };
  }, [calcTopPosition]);

  return (
    <Styled.Root
      enableStickyMode={shouldSticky}
      style={{
        ...(shouldSticky && { top: stickyTop }),
      }}
    >
      <Styled.Wrapper>{children}</Styled.Wrapper>
    </Styled.Root>
  );
};

export default FixedTopbar;
