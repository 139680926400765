import React, { useState, useEffect, useRef, useMemo, Fragment } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { CustomIcon } from 'components/CustomIcon';
import RangeColorPickerV2 from 'components/Elements/RangeColorPickerV2/RangeColorPickerV2';
import omit from 'lodash/omit';
import { useSelector, useDispatch } from 'react-redux';
import * as Styled from './ColorPickerContainer.styles';
import {
  familyToggle,
  individualToggle,
} from '../../components/Filter/FamilyViewSwitch';
import { constants } from '../../config';
import { useUpdateEffect } from '../../hooks/customHooks';
import { usePrevious } from '../../hooks/usePrevious';
import {
  isColorCircleTopbarShownSelector,
  primaryColorFilterSelector,
  colorShadesFilterSelector,
  appFiltersSelector,
  currentPrimaryColorSelector,
  appFiltersNoColorsKeysSelector,
  searchToggleSelector,
  isRainbowClickedSelector,
} from '../../redux/selectors/filtersSelector';
import { searchOptionsSelector } from '../../redux/selectors/searchSelector';
import {
  addFilter,
  setAppFilters,
  setIsColorCircleTopbarShown,
  setSearchToggle,
  setIsRainbowClicked,
  removeFilter,
} from '../../redux/sliceCreators/filtersSlice';
import { setSearchOptions } from '../../redux/sliceCreators/searchSlice';
import { isBannersPage } from '../../utils/helpers';
export interface Color {
  hex: string;
  position?: 'start' | 'center' | 'end';
  isBW?: boolean;
  showShadow?: boolean;
}

export interface FacetColor {
  active: boolean;
  count: number;
  field: string;
  hexcode: string;
  id: string;
  label: string;
}

export interface Sizes {
  width?: number;
  position?: number;
}

const { searchActionsEnum } = constants;

const virtualColorFacetLabel = 'virtual_color';

const isBannersSearch = isBannersPage();

const ColorPickerContainer = () => {
  const [rangePickerWidth, setRangePickerWidth] = useState(0);
  const [isShadesChanged, setIsShadesChanged] = useState(true);
  const [isFamilyToggleChanged, setIsFamilyToggleChanged] = useState(false);
  const [localShades, setLocalShades] = useState([]);
  const isRainbowClicked = useSelector(isRainbowClickedSelector);
  const searchOptions = useSelector(searchOptionsSelector);
  const isColorTopbarShown = useSelector(isColorCircleTopbarShownSelector);
  const colorFacet = useSelector(primaryColorFilterSelector);
  const currentColorFacet = useSelector(currentPrimaryColorSelector);
  const { noZeroShades: colorShadesFacet } = useSelector(
    colorShadesFilterSelector,
  );
  const appliedFilters = useSelector(appFiltersSelector);
  const searchToggle = useSelector(searchToggleSelector);
  const currentFilterKeys = useSelector(appFiltersNoColorsKeysSelector);
  const prevFilterKeys = usePrevious(currentFilterKeys);
  const windowWidth = useWindowWidth();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();

  const paddingOffset = windowWidth > 767 ? 64 : 28;
  const isRainbowEnabled =
    currentColorFacet == null &&
    colorShadesFacet?.length > 0 &&
    searchToggle === individualToggle;
  const shouldShowRainbowRange = isRainbowEnabled && isRainbowClicked;
  const showRangePicker =
    currentColorFacet != null && colorShadesFacet?.length > 0;

  const isNewRequestAction = useMemo(() => {
    // @ts-expect-error // TODO: fix types
    const action = searchOptions.action;
    const actionsToChangeTabs = [
      searchActionsEnum.init,
      searchActionsEnum.searchBar,
      searchActionsEnum.initPrevResults,
    ];
    return actionsToChangeTabs.includes(action);
  }, [searchOptions]);

  useEffect(() => {
    handleRangePickerWidthCalc();
    window.addEventListener('resize', handleResize);
    window.addEventListener('family-toggle-click', handleFamilyToggleClick);
    window.addEventListener(
      'update-gradient-shades',
      handleUpdateGradientShades,
    );
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener(
        'update-gradient-shades',
        handleUpdateGradientShades,
      );
      window.removeEventListener(
        'family-toggle-click',
        handleFamilyToggleClick,
      );
    };
  }, [colorShadesFacet]);

  useEffect(() => {
    window.addEventListener(
      'colorCircleClick',
      handleSidebarCircleClick as EventListenerOrEventListenerObject,
    );
    return () => {
      window.addEventListener(
        'colorCircleClick',
        handleSidebarCircleClick as EventListenerOrEventListenerObject,
      );
    };
  }, [searchToggle, appliedFilters]);

  useEffect(() => {
    if (isShadesChanged) {
      setIsShadesChanged(false);
      setLocalShades(colorShadesFacet);
    }
  }, [colorShadesFacet]);

  useEffect(() => {
    if (localShades?.length === 0 && colorShadesFacet?.length !== 0) {
      setLocalShades(colorShadesFacet);
      return;
    }
    if (localShades?.length !== 0 && colorShadesFacet?.length === 0) {
      setLocalShades([]);
    }
  }, [localShades, colorShadesFacet]);

  useEffect(() => {
    if (
      prevFilterKeys?.length !== currentFilterKeys?.length ||
      isNewRequestAction
    ) {
      setIsShadesChanged(true);
    }
  }, [prevFilterKeys, currentFilterKeys, isNewRequestAction]);

  useUpdateEffect(() => {
    setIsShadesChanged(true);
  }, [searchToggle, isColorTopbarShown]);

  useUpdateEffect(() => {
    handleRangePickerWidthCalc();
  }, [windowWidth]);

  // Hack, to reset gradient, when shades are deleted manually from Selected Filters
  const handleUpdateGradientShades = () => {
    setIsShadesChanged(true);
  };

  const handleFamilyToggleClick = () => {
    setIsFamilyToggleChanged(false);
  };

  const handleResize = () => {
    handleRangePickerWidthCalc();
  };

  const handleRangePickerWidthCalc = () => {
    if (containerRef?.current != null) {
      setRangePickerWidth(containerRef.current.offsetWidth - paddingOffset);
    }
  };

  const getAllAppliedColorFilters = () => {
    return Object.keys(appliedFilters || {}).filter((key) => {
      return key.includes(virtualColorFacetLabel);
    });
  };

  const resetAllColorFilters = () => {
    // Clear all Color Related filters
    const colorFacets = getAllAppliedColorFilters();
    const filteredFilters = omit(appliedFilters, colorFacets);
    dispatch(setAppFilters(filteredFilters));
  };

  const handleColorClick = (color: FacetColor | null) => {
    // @ts-expect-error TODO: fix types
    if (searchOptions?.action === searchActionsEnum.searchBar) {
      dispatch(
        setSearchOptions({
          ...searchOptions,
          action: searchActionsEnum.filter,
        }),
      );
    }
    if (color?.active) {
      dispatch(removeFilter(color));
    } else {
      dispatch(addFilter(color));
    }
    setIsFamilyToggleChanged(true);
    setIsShadesChanged(true);
  };

  const changeFamilyToggle = () => {
    if (searchToggle !== individualToggle) {
      dispatch(setSearchToggle(individualToggle));
      const triggerToggleSearch = new CustomEvent('triggerFamilyToggleSearch', {
        detail: individualToggle,
      });
      window.dispatchEvent(triggerToggleSearch);
      setIsFamilyToggleChanged(true);
    }
  };

  const handleRainbowClick = () => {
    changeFamilyToggle();
    dispatch(setIsRainbowClicked(true));
  };

  const handleSidebarCircleClick = (e: CustomEvent) => {
    if (e?.detail != null) {
      const appliedColors = getAllAppliedColorFilters();
      const filteredFilters = omit(appliedFilters, appliedColors);
      const newColor = e.detail?.color;
      if (newColor?.id != null) {
        filteredFilters[newColor?.id] = newColor;
      } else {
        if (Boolean(e.detail?.isRainbow)) {
          handleRainbowClick();
        }
      }
      dispatch(setAppFilters(filteredFilters));
      setIsShadesChanged(true);
    }
  };

  const handleCloseRange = () => {
    if (isFamilyToggleChanged) {
      dispatch(setSearchToggle(familyToggle));
      const colorFacets = getAllAppliedColorFilters();
      if (colorFacets?.length === 0) {
        const triggerToggleSearch = new CustomEvent(
          'triggerFamilyToggleSearch',
          {
            detail: familyToggle,
          },
        );
        window.dispatchEvent(triggerToggleSearch);
      }
    }
    resetAllColorFilters();
    dispatch(setIsColorCircleTopbarShown(false));
    setLocalShades([]);
    dispatch(setIsRainbowClicked(false));
  };

  // @ts-expect-error // TODO: fix types
  const handleColorChange = (newColors) => {
    // Clear all currently selected Shades
    const colorShades = Object.keys(appliedFilters || {}).filter((key) => {
      return key.includes(virtualColorFacetLabel) && !key.includes('group');
    });
    const filteredFilters = omit(appliedFilters, colorShades);
    // @ts-expect-error // TODO: fix types
    newColors?.forEach((item) => {
      filteredFilters[item.id] = item;
    });
    dispatch(setAppFilters(filteredFilters));
    setIsShadesChanged(false);
  };

  const filterColors = (color: FacetColor) => {
    return color.count !== 0;
  };

  const shouldShowGradient = false; //showRangePicker || shouldShowRainbowRange;

  if (isBannersSearch) {
    return <Fragment />;
  }

  return (
    <Styled.OuterWrapper ref={containerRef}>
      <Styled.Wrapper
        isShown={isColorTopbarShown}
        isGradientShown={shouldShowGradient}
        id="topbar_color_selector_products"
      >
        {isColorTopbarShown &&
          (shouldShowGradient ? (
            <Styled.RangePickerWrapper>
              <Styled.CloseRangeIconButton
                iconName="close"
                color="secondary"
                size="medium"
                onClick={handleCloseRange}
              />
              <Styled.CloseRangeButton
                startIcon="close"
                size="medium"
                label="Return to All Colors"
                onClick={handleCloseRange}
              />
              <RangeColorPickerV2
                canvasWidth={rangePickerWidth}
                colors={localShades}
                onChange={handleColorChange}
              />
            </Styled.RangePickerWrapper>
          ) : (
            <Styled.CirclesWrapper isRainbowEnabled>
              {colorFacet?.filter(filterColors)?.map((color: FacetColor) => (
                <Styled.Info
                  key={color.hexcode}
                  title={color.label}
                  PopperProps={{
                    disablePortal: true,
                  }}
                >
                  <Styled.ColorCircle
                    key={color.hexcode}
                    color={color.hexcode}
                    showShadow={color.label === 'White'}
                    isDisabled={color.count === 0}
                    onClick={() => handleColorClick(color)}
                  >
                    {color?.active && (
                      // @ts-expect-error // TODO: fix types
                      <CustomIcon dense name="tick" cssClassName="checkIcon" />
                    )}
                  </Styled.ColorCircle>
                </Styled.Info>
              ))}

              {/* colorFacet?.length > 0 && (
                                <ColorWheelIcon
                                    wrapperClassName="colorwheelIcon"
                                    onClick={() => handleRainbowClick()}
                                />
                            ) */}
            </Styled.CirclesWrapper>
          ))}
      </Styled.Wrapper>
    </Styled.OuterWrapper>
  );
};

export default ColorPickerContainer;
