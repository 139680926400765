import React, { useMemo } from 'react';
import * as Styled from './MaterialsContainer.styles';
import { CampaignBanner } from '../../components/CampaignBanner';
import { CampaignBannerProps } from '../../components/CampaignBanner/CampaignBanner';
import { gridColumnNumber } from '../../redux/sliceCreators/searchSlice';
// skipcq: JS-C1003 - Styled import.
import { isSearchPage } from '../../utils/helpers';

const ItemContainer = React.forwardRef(
  (
    props: {
      'data-index'?: number;
      itemIndex?: number;
      dataIndex?: number;
      contentBanner?: CampaignBannerProps;
      contentBlocksLength?: number;
      children?: React.ReactNode;
      currentPage: number;
      searchResultsLength: number;
    },
    ref,
  ) => {
    const page = props?.currentPage || 1;
    const searchResultsLength = props?.searchResultsLength || 75;
    const index: number =
      props['data-index'] || props.itemIndex || props.dataIndex || 0;
    const isFirstPage = page === 1;

    const isLastInThirdRow = useMemo(() => {
      if (props.contentBlocksLength) {
        if (props.contentBlocksLength === 1) {
          return index + 1 === 18;
        } else if (props.contentBlocksLength >= 2) {
          return index + 1 === 16;
        }
      } else {
        // Show after 4th row
        return window.innerWidth > 1024
          ? (index + 1) / gridColumnNumber === 4
          : index === 7;
      }
      return false;
    }, [props.contentBlocksLength]);

    const shouldRenderAfter =
      searchResultsLength > 20
        ? isLastInThirdRow
        : searchResultsLength <= 10
        ? searchResultsLength === index + 1
        : searchResultsLength < gridColumnNumber
        ? searchResultsLength === index + 1
        : index + 1 ===
          Math.floor(searchResultsLength / gridColumnNumber) * gridColumnNumber;

    const shouldShowMerchandising = useMemo(() => {
      return (
        props.contentBanner != null &&
        shouldRenderAfter &&
        isFirstPage &&
        isSearchPage()
      );
    }, [props.contentBanner, shouldRenderAfter, isFirstPage]);

    return (
      <>
        <Styled.ResultItem
          item
          container
          // @ts-expect-error // TODO: fix types
          ref={ref}
          columnSize={gridColumnNumber}
        >
          {props.children}
        </Styled.ResultItem>
        {shouldShowMerchandising &&
          props.contentBanner?.position != undefined && (
            <CampaignBanner {...props.contentBanner} />
          )}
      </>
    );
  },
);

ItemContainer.displayName = 'ItemContainer';
export { ItemContainer };
