import { ProductSearchMaterial } from '../../../types';
import { CategoryType } from '../types';
import { getL2FromTaxonomy } from './getL2FromTaxonomy';

/**
 * Function to get array of L2 taxonomies
 * @param results array of ProductSearchMaterial type
 * @returns CategoryType array that corresponds to L2 taxonomy
 */
export const getTaxonomies = (
  results: ProductSearchMaterial[],
): CategoryType[] => {
  const taxonomySet = new Set<string>();
  if (!results) {
    return [];
  }

  results.forEach((result) => {
    if (result.taxonomyL2Display) {
      taxonomySet.add(result.taxonomyL2Display);
    } else if (result.taxonomy && result.taxonomy.length > 0) {
      taxonomySet.add(getL2FromTaxonomy(result));
    }
  });

  return Array.from(taxonomySet).map((taxonomy) => ({
    name: taxonomy,
    value: taxonomy,
  }));
};
