import React, { FC, useState, useEffect } from 'react';
import style from './categoryPill.module.scss';
import { CloseIcon } from './close';

interface CategoryPillProps {
  name: string;
  value: string;
  selected: boolean;
  theme: string;
  onHandleCategoryClick: (value: string) => void;
}

export const CategoryPill: FC<CategoryPillProps> = ({
  name,
  value,
  selected,
  theme,
  onHandleCategoryClick,
}) => {
  const handleClick = (key: string) => {
    onHandleCategoryClick(key);
  };

  const [computedStyle, setComputedStyle] = useState('');

  useEffect(() => {
    if (selected) {
      setComputedStyle(`${theme}-inverted`);
    } else {
      setComputedStyle(theme);
    }
  }, [selected, theme]);

  return (
    <button
      type="button"
      className={`${style.categoryPill} ${style[computedStyle]}`}
      onClick={() => handleClick(value)}
    >
      {name} {selected ? <CloseIcon /> : null}
    </button>
  );
};
