export const pushMiddleBannerAnalytics = (
  eventName = 'view_promotion',
  fileName = '',
) => {
  try {
    const obj = {
      event: eventName,
      event_location: 'Catalog',
      list_id: 'banner_middle_results',
      list_name: 'Middle search banner',
      ecommerce: {
        promotion_id: 'banner_middle_results',
        promotion_name: 'Middle search banner',
        items: [
          {
            promotion_id: 'banner_middle_results',
            promotion_name: 'Middle search banner',
            creative_name: fileName,
            creative_slot: 'banner_middle_1',
          },
        ],
      },
    };
    mitGTM.sendEvent({ ecommerce: null }); // Clear the previous ecommerce object.
    mitGTM.sendEvent(obj);
    console.log(obj);
  } catch (e) {
    console.log(e);
  }
};
