// @ts-nocheck TODO: fix types for the entire file

import styled, { StyledComponent } from '@emotion/styled';
import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';
import { Box } from '@mui/system';
import {
  IconButton as ThemeIconButton,
  Button as ThemeButton,
} from 'components/Buttons';
import { pxToEm, theme } from 'theme';

export const NavbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-wrap: nowrap;
`;

export const NavbarSection = styled(Box)`
  display: flex;
  flex-basis: 100%;
  ${({ theme }) => theme.breakpoints.up(840)} {
    flex-basis: 33.33%;
  }
`;

export const WrapperActionBar = styled(NavbarSection)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    position: fixed;
    /* height of the navigation bar */
    bottom: ${pxToEm(69)};
    left: 0px;
    z-index: 40;
    background-color: ${theme.palette.common.white};
    width: 100%;
    display: grid;
    padding: ${pxToEm(12)} ${pxToEm(16)};
    border-top: ${pxToEm(1)} solid ${theme.palette.grey['50']};
    grid-template-columns: 1fr 1fr 1fr 3fr;
    justify-items: end;
    & > button {
      padding: ${pxToEm(16)} ${pxToEm(18)};
      background-color: ${theme.palette.secondary.main};
    }
  }
`;

export const Tooltip: StyledComponent<TooltipProps> = styled(MuiTooltip)`
  & ~ .MuiTooltip-popper {
    .MuiTooltip-tooltip {
      margin-top: 0.5em;
      max-width: ${pxToEm(200, false, 12)};
      background: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.common.white};
      font-size: 12px;
      padding: 7px 12px;
      min-height: ${pxToEm(24, false, 12)};
      border-radius: ${pxToEm(24, false, 12)};
      text-align: center;
      span {
        white-space: pre-wrap;
        font-size: 12px;
      }
    }
  }
`;

export const IconButton = styled(ThemeIconButton)`
  &:hover {
    background: ${({ theme }) => theme.palette.secondary?.main};
    &:active,
    &:focus {
      box-shadow: none;
    }
  }
`;

export const Button = styled(ThemeButton)`
  height: 36px;
  &:hover,
  &:focus {
    box-shadow: none !important;
    ${({ color, theme }) =>
      color !== 'primary' &&
      `
            color: ${theme.palette.primary.main};
            background: ${theme.palette.secondary?.light};
        `}
  }
`;

export const CancelButton = styled(Button)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    background: transparent;
    color: ${({ theme }) => theme.palette.primary.main};
    &:hover,
    &:focus {
      box-shadow: none !important;
      color: ${({ theme }) => theme.palette.primary.main};
      background: ${({ theme }) => theme.palette.secondary?.light};
    }
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.75em;
  cursor: pointer;
`;
