import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { IconButton } from 'components/Buttons/IconButton';
import { theme } from 'theme';
import pxToEm from 'theme/pxToEm';

export const ResultItem = styled(Grid, {
  shouldForwardProp: (prop: string) => !['columnSize'].includes(prop),
})<{ columnSize: number }>`
  display: inline-flex;
  flex-wrap: wrap;
  flex-grow: 0;
  position: relative;
  width: ${(props) => 100 / props.columnSize}%;
  flex-basis: ${(props) => 100 / props.columnSize}%;
  padding: 0.5em;
  ${theme.breakpoints.up(1026)} {
    &:first-of-type {
      padding-top: 0.5em;
    }
  }
  & > *:first-of-type {
    width: 100%;
  }
  ${theme.breakpoints.down(1025)} {
    width: ${(props) => (props.columnSize !== 3 ? '50%' : '100%')};
    flex-basis: ${(props) => (props.columnSize !== 3 ? '50%' : '100%')};
  }
  ${theme.breakpoints.between(1025, 1400)} {
    font-size: 14px;
  }
  ${theme.breakpoints.up(1401)} {
    font-size: 16px;
  }
  ${theme.breakpoints.down(500)} {
    padding: 0.5em;
  }
`;

export const ResultsList = styled.div<{
  isDefaultGrid?: boolean;
  isCentered?: boolean;
}>`
  ${({ isDefaultGrid }) =>
    isDefaultGrid &&
    `
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  `}
  .virtuoso-grid-list {
    margin: 0;
    width: 100%;
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    ${({ isCentered }) => isCentered && 'justify-content: center;'}
  }
`;

export const SimpleResultsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
`;

export const BackArrow = styled(IconButton)`
  position: fixed;
  left: 1em;
  bottom: 1em;
  border-radius: 50%;
  background: ${theme.palette.grey[400]};
  cursor: pointer;
  z-index: 10;
`;

export const ResultsWrapper = styled.div<{ withMargin: boolean }>`
  position: relative;
  ${({ withMargin }) => withMargin && 'margin-bottom: 200px;'}
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em 0;
  .MuiPagination-ul {
    flex-wrap: nowrap;
  }
  li {
    margin: 0;
    padding: 0;
    button {
      width: ${pxToEm(48)};
      height: ${pxToEm(48)};
      border-radius: 50%;
      color: ${theme.palette.primary.main};
      margin: 0 2px;
      font-size: ${pxToEm(16)};
      &:hover,
      &.Mui-selected {
        background-color: ${theme.palette.secondary.main};
      }
    }
  }
`;

export const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 900;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SeeAllBox = styled.div`
  background: ${theme.palette.grey[800]};
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  z-index: 3;
  width: calc(100% - 1em);
  aspect-ratio: 1/1;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${theme.breakpoints.down(500)} {
    top: 0.25em;
    left: 0.25em;
    width: calc(100% - 0.5em);
    min-height: 44.5vw;
  }
`;
