// @ts-nocheck TODO: fix types for the entire file
import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
  Fragment,
} from 'react';
import FilterOption from 'components/Filters/FilterOption';
import { Virtuoso } from 'react-virtuoso';
import * as Styled from './filterSubItems.styles';
import { useFiltersContext } from '../../../contexts';
import {
  areComponentPropsEqual,
  splittedCategoriesFields,
} from '../../../utils';

const FilterSubItems = React.memo((props) => {
  const {
    renderedFilters,
    onFilterClicked,
    loadingFilters,
    currentParentItems,
    isMobile,
    onMobileOpenFilter,
    mobileActiveFilter,
    appFilters,
  } = props;
  const [multilevelFilterSelected, setMultilevelFilterSelected] =
    useState(false);

  const { createFilter, getFiltersByPropertyValue } = useFiltersContext();

  const getIsParentChecked = useCallback(
    (parentLabel, field = '', isStarting = true) => {
      return Boolean(
        getFiltersByPropertyValue(
          'value',
          parentLabel,
          {
            isStarting: isStarting,
            field,
          },
          appFilters,
        ).length,
      );
    },
    [appFilters, renderedFilters],
  );

  const isAnyParentChecked = useMemo(
    () =>
      renderedFilters.some(([parentLabel, data]) => {
        return Boolean(data?.active);
      }),
    [renderedFilters, appFilters, multilevelFilterSelected],
  );

  useEffect(() => {
    if (isAnyParentChecked && !multilevelFilterSelected) {
      setMultilevelFilterSelected(true);
    }
  }, [isAnyParentChecked, multilevelFilterSelected]);

  // Function to filter filters, if sibling selected
  const handleFilterItems = ([parentLabel, data]) => {
    if (
      isAnyParentChecked &&
      multilevelFilterSelected &&
      data.multiple !== 'single' &&
      splittedCategoriesFields.includes(data?.field)
    ) {
      return Boolean(data?.active);
    } else {
      return true;
    }
  };

  const handleSelectFilter = (filter, isParent) => {
    if (isMobile && isParent) {
      return;
    }
    setMultilevelFilterSelected(isParent);
    onFilterClicked(filter, isParent);
  };

  const getButtonId = (item, label?: string) => {
    const labelText = label ?? item?.label;
    const labelChild = labelText?.includes('>')
      ? labelText?.split('>')?.[1]
      : labelText;
    const itemLabel = labelChild?.replace(' ', '_')?.toLowerCase();
    return `${item.field}_${itemLabel}_filter_checkbox`;
  };

  const handleParentCheckboxMobileClick = (filter) => {
    if (isMobile) {
      setMultilevelFilterSelected(true);
      onFilterClicked(filter, true);
    }
  };

  const handlePrentLabelMobileClick = (filter, values) => {
    if (isMobile) {
      onMobileOpenFilter({
        ...filter,
        values: values.map(([_, value]) => value),
      });
      if (!appFilters[filter.id]) {
        setMultilevelFilterSelected(true);
        onFilterClicked(filter, true);
      }
    }
  };

  const filteredFilters = renderedFilters.filter(handleFilterItems);
  const itemsLengthDesktop =
    currentParentItems?.length ?? filteredFilters.length;
  const itemsLengthMobile = filteredFilters.some(
    ([filter]) => filter === mobileActiveFilter?.label,
  )
    ? filteredFilters[0]?.[1]?.items?.length
    : filteredFilters.length;
  const itemsLength = isMobile ? itemsLengthMobile : itemsLengthDesktop;
  const desktopVirtuosoHeight = itemsLength > 10 ? 400 : itemsLength * 40;
  const mobileVirtuosoHeight =
    itemsLength > 10 ? 'calc(100vh - 243px)' : itemsLength * 40;

  return (
    <Styled.ItemWrapper>
      <Virtuoso
        data={filteredFilters}
        style={{
          height: isMobile ? mobileVirtuosoHeight : desktopVirtuosoHeight,
        }}
        itemContent={(
          index,
          [
            parentLabel,
            {
              count,
              items,
              type,
              originFilter,
              field,
              multiple,
              high = null,
              low = null,
              active,
            },
          ],
        ) => {
          const parentFilter = createFilter(field, parentLabel);
          const isParentChecked =
            getIsParentChecked(parentLabel, field) ||
            active ||
            items?.some(([label, item]) => item.active);
          const isChecked = getIsParentChecked(parentLabel, field, false);
          const checkboxIcon = multiple === 'single' ? 'circle' : 'square';

          const isChildChecked = items.some((item) => {
            const [filter] = item;
            return Boolean(appFilters[filter.id]);
          });

          const isRangeType = high != null || low != null;
          const filterItem = isRangeType ? originFilter : parentFilter;
          const isSelected = Boolean(appFilters[filterItem.id]);

          if (items.length === 0) {
            return (
              <FilterOption
                key={parentLabel + index}
                isLoading={false}
                onClick={(filter) => handleSelectFilter(filter, false)}
                isSelected={isSelected}
                item={filterItem}
                count={count}
                checkboxVariant={checkboxIcon}
                buttonId={getButtonId(filterItem)}
                maxVisibleWidth={137}
              />
            );
          }

          const numberOfCheckedItems = items.filter(([_, filter]) =>
            Boolean(appFilters[filter.id]),
          ).length;

          return (
            <Styled.Item
              key={parentLabel + index}
              isParentChecked={isParentChecked}
            >
              {(isMobile
                ? mobileActiveFilter?.label !== parentLabel
                : !isParentChecked) && (
                <FilterOption
                  onClick={(item) => handleSelectFilter(item, true)}
                  isSelected={isSelected}
                  item={parentFilter}
                  buttonId={getButtonId(parentFilter)}
                  count={count}
                  disabled={isChildChecked}
                  checkboxVariant={checkboxIcon}
                  rightIcon="arrow-right"
                  badgeNumber={isMobile ? numberOfCheckedItems : 0}
                  onCheckboxClick={handleParentCheckboxMobileClick}
                  onLabelClick={(filter) =>
                    handlePrentLabelMobileClick(filter, items)
                  }
                  maxVisibleWidth={137}
                />
              )}
              {(isMobile
                ? mobileActiveFilter?.label === parentLabel
                : isParentChecked) && (
                <Fragment>
                  {items?.map((subFilter) => {
                    const [label, filter] = subFilter;
                    return (
                      <FilterOption
                        key={filter?.label}
                        onClick={onFilterClicked}
                        isSelected={Boolean(appFilters[filter.id])}
                        label={label}
                        item={filter}
                        checkboxVariant={checkboxIcon}
                        buttonId={getButtonId(filter, label)}
                        maxVisibleWidth={137}
                      />
                    );
                  })}
                </Fragment>
              )}
            </Styled.Item>
          );
        }}
      />
    </Styled.ItemWrapper>
  );
}, areComponentPropsEqual);

FilterSubItems.displayName = 'FilterSubItems';

export { FilterSubItems };
