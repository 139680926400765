import React from 'react';
import { SkeletonAnimation } from 'components/Cards/SkeletonCard/SkeletonCard.types';
import * as Styled from './FilterSkeleton.styles';

interface FilterSkeletonProps {
  animation: SkeletonAnimation | false;
  filterItemsCount: number;
}

const FilterSkeleton: React.FC<FilterSkeletonProps> = ({
  filterItemsCount = 10,
  animation,
}) => {
  return (
    <Styled.SkeletonWrapper>
      {Array.from({ length: filterItemsCount }).map((_, index) => (
        <Styled.SkeletonLine
          animation={animation}
          height={16}
          key={index}
        ></Styled.SkeletonLine>
      ))}
    </Styled.SkeletonWrapper>
  );
};

export default FilterSkeleton;
