import React, { Fragment } from 'react';
import { Box } from '@mui/system';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import * as Styled from './CompareList.styles';
import { pushCompareAnalyticsEvent } from '../../helpers';
import useCurrentProject from '../../hooks/useCurrentProject';
import { definedTranslation } from '../../i18n';
import {
  isComparisonActiveSelector,
  comparisonItemsSelector,
} from '../../redux/selectors/commonSelector';
import {
  resetComparisonFeature,
  setIsCompareTableShown,
  updateComparisonItems,
} from '../../redux/sliceCreators/commonSlice';
import { ProductSearchMaterial } from '../../types';

const CompareList = () => {
  const isComparisonActive = useSelector(isComparisonActiveSelector);
  const comparisonItems = useSelector(comparisonItemsSelector);
  const { currentProject } = useCurrentProject();
  const intl = useIntl();
  const dispatch = useDispatch();

  const labels = {
    compareButtonLabel: intl?.formatMessage(
      definedTranslation.compare.startCompareButtonLabel,
    ),
    header: intl?.formatMessage(definedTranslation.compare.header),
    cancelButtonLabel: intl?.formatMessage(
      definedTranslation.compare.cancelCompareButtonLabel,
    ),
    tooltip: intl?.formatMessage(
      definedTranslation.compare.disabledButtonTooltip,
    ),
  };

  const removeComparisonItem = (item: ProductSearchMaterial) => {
    dispatch(updateComparisonItems(item));
  };

  const handleCancelClick = () => {
    pushCompareAnalyticsEvent({
      eventName: 'comparison_tool_cancel_cta_clicks',
      projectData: {
        name: String(currentProject?.project_title),
        id: String(currentProject?.project_id),
      },
      additionalProps: {
        event_items_selected: comparisonItems?.length || 0,
        interaction_location: 'compare_toolbar',
      },
    });
    dispatch(resetComparisonFeature());
  };

  const handleInitCompare = () => {
    pushCompareAnalyticsEvent({
      eventName: 'comparison_tool_compare_cta_clicks',
      projectData: {
        name: String(currentProject?.project_title),
        id: String(currentProject?.project_id),
      },
      additionalProps: {
        event_items_selected: comparisonItems?.length || 0,
      },
    });
    dispatch(setIsCompareTableShown(true));
  };

  if (!isComparisonActive) return <Fragment />;

  return (
    <Styled.MainWrapper>
      <Styled.Header variant="h5">{labels.header}</Styled.Header>
      <Styled.ItemsList>
        {comparisonItems?.map((item: ProductSearchMaterial) => (
          <Styled.Cell
            isActive
            imageUrl={item.thumbnailImageUrl}
            key={item.entityId}
          >
            <Styled.DeleteButton
              iconName="trash"
              color="secondary"
              size="medium"
              onClick={() => removeComparisonItem(item)}
            />
          </Styled.Cell>
        ))}
        {Array.from({ length: 5 - (comparisonItems?.length || 0) }).map(
          (_, index) => (
            <Styled.Cell key={index} />
          ),
        )}
      </Styled.ItemsList>
      <Box display="flex" gap="10px">
        <Styled.CancelButton
          variant="contained"
          size="medium"
          color="secondary"
          onClick={handleCancelClick}
        >
          {labels.cancelButtonLabel}
        </Styled.CancelButton>
        <Styled.Tooltip
          title={labels.tooltip}
          disableHoverListener={comparisonItems?.length > 1}
          PopperProps={{
            disablePortal: true,
          }}
        >
          {/* Span required to trigger tooltip, if Button is disabled */}
          <span style={{ display: 'inline-flex' }}>
            <Styled.CompareButton
              variant="contained"
              size="medium"
              color="primary"
              disabled={comparisonItems?.length < 2}
              onClick={handleInitCompare}
            >
              {labels.compareButtonLabel}
            </Styled.CompareButton>
          </span>
        </Styled.Tooltip>
      </Box>
    </Styled.MainWrapper>
  );
};

export { CompareList };
