import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { CampaignBanner } from '../../components/CampaignBanner';
import { campaignTopBannerSelector } from '../../redux/selectors/searchSelector';

const TopBannersWrapper: FC = () => {
  const campaignTopBanner = useSelector(campaignTopBannerSelector);
  const isBanner = campaignTopBanner != null;

  return (
    // Bring back height: 260px of container div, if A1 or Collections will be added back for CLS
    // @ts-expect-error // TODO: fix types
    <div>{isBanner && <CampaignBanner {...campaignTopBanner} />}</div>
  );
};

export default TopBannersWrapper;
