import { ProductSearchMaterial } from './types';
import { mapMaterialsForAnalytics } from './utils/helpers';

export const pushColorwaysClickAnalytics = (product: ProductSearchMaterial) => {
  const { currency, gaType } = mapMaterialsForAnalytics(
    [product],
    product?.exactColor,
    'srp_product_card',
    'srp product card',
  );
  const payload = {
    event: 'pccolorway_colorway_selected',
    list_id: 'srp_product_card',
    list_name: 'srp product card',
    ecommerce: {
      currency: currency,
      value: 0,
      items: gaType,
    },
    event_location: 'Catalog',
  };

  dataLayer.push(payload);
  console.log(payload);
};
