import React from 'react';
import WestIcon from '@mui/icons-material/West';
import { Box, useMediaQuery } from '@mui/material';
import TypographyComponent from 'components/Typography/Typography';
import { IntlShape } from 'react-intl';
import * as Styled from './EmptyResult.styles';
import { definedTranslation } from '../../i18n';

const EmptyResult: React.FC<{ intl: IntlShape }> = ({ intl }) => {
  const isMobile = useMediaQuery('(max-width: 1024px)');

  const labels = {
    title: intl?.formatMessage(definedTranslation.noResults.title),
    subtitle: intl?.formatMessage(definedTranslation.noResults.subtitle),
  };

  function onClickBack() {
    window.location.href = '/search';
  }
  return (
    <Styled.Wrapper>
      {isMobile ? (
        <Styled.BackWrapper onClick={onClickBack}>
          <WestIcon />
        </Styled.BackWrapper>
      ) : null}
      {/* @ts-expect-error // TODO: fix types */}
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={800}
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <TypographyComponent
          fontFamily="Graphik"
          fontSize={isMobile ? 36 : 80}
          fontWeight={600}
          variant={isMobile ? 'h3' : 'huge'}
          textAlign="center"
          mb="30px"
          maxWidth={isMobile ? '310px' : '680px'}
        >
          {labels.title}
        </TypographyComponent>
      </Box>
      <Box
        flexDirection="row"
        justifyContent="center"
        // @ts-expect-error // TODO: fix types
        maxWidth={isMobile ? '343px' : null}
      >
        <TypographyComponent
          fontFamily="Graphik"
          fontSize={isMobile ? 16 : 24}
          fontWeight={400}
          variant="body"
          align="center"
          maxWidth={isMobile ? '310px' : 'unset'}
        >
          {labels.subtitle}
        </TypographyComponent>
      </Box>
    </Styled.Wrapper>
  );
};

export default EmptyResult;
