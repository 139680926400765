import React, { useCallback, useMemo } from 'react';
import {
  CustomEventName,
  Project,
  shouldTriggerProjectSelectionDialog,
} from '@mb/lib';
import { MultiselectNavbar } from 'components/components/MultiselectNavbar';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { sendAnalytics } from '../../api/events';
import { config } from '../../config';
import {
  getEventsMaterialProps,
  pushCompareAnalyticsEvent,
} from '../../helpers';
import useAddToBag from '../../hooks/useAddToBag';
import useCurrentProject from '../../hooks/useCurrentProject';
import { definedTranslation } from '../../i18n';
import {
  selectedMaterialsSelector,
  multiSelectToggleSelector,
  appFiltersSelector,
} from '../../redux/selectors/filtersSelector';
import { searchResultsSelector } from '../../redux/selectors/searchSelector';
import {
  setIsCompareTableShown,
  setIsComparisonActive,
  setIsExportModalOpen,
  updateComparisonItems,
} from '../../redux/sliceCreators/commonSlice';
import {
  setSelectedMaterials,
  setMultiSelectToggle,
} from '../../redux/sliceCreators/filtersSlice';
import {
  setCreatedProject,
  setCurrentProjectId,
} from '../../redux/sliceCreators/projectsSlice';
import { ProductSearchMaterial } from '../../types';
import {
  triggerBoardsModal,
  getTaxonomyAnalyticsDataFromFilters,
  isBannersPage,
} from '../../utils/helpers';
import { mapProductToAnalyticsEcommerceItem } from '../../utils/helpers/analytics';

const isBannersSearch = isBannersPage();

interface MultiSelectContainerProps {
  isWithCompareFeature?: boolean;
}

const MultiSelectContainer: React.FC<MultiSelectContainerProps> = ({
  isWithCompareFeature,
}) => {
  const results = useSelector(searchResultsSelector);
  const appFilters = useSelector(appFiltersSelector);
  const selectedMaterials = useSelector(selectedMaterialsSelector);
  const isMultiselect = useSelector(multiSelectToggleSelector);
  const dispatch = useDispatch();
  const { addToBag, isLoading: isBagLoading } = useAddToBag();
  const { currentProject } = useCurrentProject();
  const intl = useIntl();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const analyticsEventLocation = isBannersSearch ? 'Collection' : 'Catalog';

  const selectedProject = {
    id: currentProject?.project_id,
    label: currentProject?.project_title ?? 'Select project..',
  };

  const {
    selectAllTooltip,
    addToBagTooltip,
    compareDisabledText,
    ...textLabels
  } = {
    addToCartButtonLabel: intl?.formatMessage(
      definedTranslation.selectMenu.addToCart,
    ),
    saveButtonLabel: intl?.formatMessage(definedTranslation.selectMenu.save),
    selectAllButtonLabel: intl?.formatMessage(
      definedTranslation.selectMenu.selectAll,
    ),
    cancelButtonLabel: intl?.formatMessage(
      definedTranslation.selectMenu.cancel,
    ),
    shareExportButtonTooltip: intl?.formatMessage(
      definedTranslation.selectMenu.shareExport,
    ),
    itemsSelectedLabel: intl?.formatMessage(
      definedTranslation.selectMenu.itemsSelected,
    ),
    onlySamplesLabel: intl?.formatMessage(
      definedTranslation.selectMenu.onlySamples,
    ),
    selectAllTooltip: intl?.formatMessage(
      definedTranslation.selectMenu.selectAllTooltip,
    ),
    addToBagTooltip: intl?.formatMessage(
      definedTranslation.selectMenu.addToBagTooltip,
    ),
    compareButtonLabel: intl?.formatMessage(
      definedTranslation.compare.toggleLabel,
    ),
    compareDisabledText: intl?.formatMessage(
      definedTranslation.compare.compareLimitReachedlabel,
    ),
  };

  const { id: itemListId, title: itemListName } = useMemo(() => {
    return getTaxonomyAnalyticsDataFromFilters(appFilters);
  }, [appFilters]);

  const handleMultiSelectToggle = () => {
    pushCompareAnalyticsEvent({
      eventName: 'comparison_tool_cancel_cta_clicks',
      projectData: {
        name: String(currentProject?.project_title),
        id: String(currentProject?.project_id),
      },
      additionalProps: {
        event_items_selected: selectedMaterials?.length || 0,
        interaction_location: 'multiselect_toolbar',
      },
    });
    dispatch(setSelectedMaterials([]));
    dispatch(setMultiSelectToggle(!isMultiselect));
  };

  const handleAssociatedFinishProductsModal = useCallback(() => {
    const itemsAssociatedFinishProducts: any[] = [];

    // @ts-expect-error // TODO: fix types
    selectedMaterials.forEach((item) => {
      if ((item?.associatedFinishProducts || []).length > 0) {
        const finishItems = {
          associatedProducts: item.associatedFinishProducts,
          parentProduct: {
            id: item.id,
            manufacturerName: item.brand,
            name: item.title || item.name,
            productImageUrl: item.imageUrl,
          },
        };
        itemsAssociatedFinishProducts.push(finishItems);
      }
    });

    if (itemsAssociatedFinishProducts.length > 0) {
      window.dispatchEvent(
        new CustomEvent('open-associated-finishes-products-modal', {
          detail: [...itemsAssociatedFinishProducts],
        }),
      );
    }
  }, [results]);

  const handleCompareClick = () => {
    const compareList = (selectedMaterials || []).slice(0, 5);
    dispatch(updateComparisonItems(compareList));
    dispatch(setSelectedMaterials([]));
    dispatch(setMultiSelectToggle(!isMultiselect));
    dispatch(setIsComparisonActive(true));
    if (compareList?.length > 1) {
      pushCompareAnalyticsEvent({
        eventName: 'comparison_tool_compare_cta_clicks',
        projectData: {
          name: String(currentProject?.project_title),
          id: String(currentProject?.project_id),
        },
        additionalProps: {
          event_items_selected: compareList?.length || 0,
        },
      });
      dispatch(setIsCompareTableShown(true));
    }
  };
  const onProjectCreate = useCallback((project: Project) => {
    dispatch(setCreatedProject(project));
  }, []);

  const onProjectSelect = useCallback((projectId: number) => {
    dispatch(setCurrentProjectId(projectId));
  }, []);

  const handleAddToBag = () => {
    if (shouldTriggerProjectSelectionDialog()) {
      window.dispatchEvent(
        new CustomEvent(CustomEventName.OPEN_PROJECT_SELECTION_DIALOG, {
          detail: {
            currentProjectId: selectedProject?.id,
            analyticsEventLocation,
            onCurrentProjectSave: (project: any) => {
              onProjectSelect?.(project?.externalId || project?.project_id);
              addToBag({
                materials: selectedMaterials,
                projectId: String(project?.project_id),
              });
            },
            onProjectCreate: (project: Project) => {
              onProjectCreate?.(project);
            },
          },
        }),
      );
      return;
    }
    addToBag({
      materials: selectedMaterials,
      projectId: String(currentProject?.project_id),
    });
    handleAssociatedFinishProductsModal();
  };

  const handleAddToBoard = async () => {
    const ids =
      selectedMaterials?.map((item: ProductSearchMaterial) => item.entityId) ??
      [];
    const analyticsProps = getEventsMaterialProps(selectedMaterials?.[0]);
    const addToWishlistEventItems =
      selectedMaterials?.map((selectedMaterial: ProductSearchMaterial) =>
        mapProductToAnalyticsEcommerceItem({
          product: selectedMaterial,
          itemListId,
          itemListName,
        }),
      ) ?? [];

    const currencies = {
      US: 'USD',
      EU: 'EUR',
      JP: 'JPY',
    };

    const itemsData = selectedMaterials.map((item: any) => {
      const type: 'finish' | 'material' = item.productFinishType
        ?.toLowerCase()
        ?.includes('finish material')
        ? 'finish'
        : 'material';

      return {
        id: item.entityId,
        type,
      };
    });

    if (ids.length > 0) {
      triggerBoardsModal({
        itemsData,
        onSuccessAnalyticsEvents: [
          { ecommerce: null },
          {
            event: 'add_to_wishlist',
            list_id: itemListId,
            list_name: itemListName,
            method:
              results.length === selectedMaterials.length
                ? 'Add all'
                : 'Multi-select',
            type: 'Materials',
            name: null,
            action: null,
            experiment_name: null,
            experiment_version: null,
            event_location: isBannersSearch ? 'Collection' : 'Catalog',
            ecommerce: {
              currency: currencies[ENVIRONMENT_REGION],
              value: 0,
              items: addToWishlistEventItems,
            },
          },
        ],
      });
      const stringIds = ids.map((id: any) => String(id));
      const value = {};
      await sendAnalytics({
        id: stringIds,
        type: 'favorite',
        ...analyticsProps,
        ...value,
      });
    }
  };

  const handleExportClick = () => {
    dispatch(setIsExportModalOpen(true));
  };

  const handleSelectAll = () => {
    dispatch(
      setSelectedMaterials(
        results.map((product: any) => {
          if (product?.selectedColorFilterEntityId) {
            return {
              ...product,
              entityId: product.selectedColorFilterEntityId,
            };
          }

          return product;
        }),
      ),
    );
  };

  const isSelectAllDisabled = results?.length >= config.maxSelectNumber;
  const isProductInSelected = selectedMaterials.find(
    (item: ProductSearchMaterial) => item?.isProduct,
  );
  const isDigitalInSelected = selectedMaterials.find(
    (item: ProductSearchMaterial) => item?.isDigital,
  );
  const isAddToBagDisabled =
    isBagLoading ||
    isProductInSelected ||
    isDigitalInSelected ||
    selectedMaterials?.length === 0;

  return (
    <MultiselectNavbar
      isWithCompareFeature={isWithCompareFeature && !isMobile}
      isCompareButtonDisabled={selectedMaterials?.length > 5}
      compareDisabledText={
        selectedMaterials?.length > 5 ? compareDisabledText : undefined
      }
      shouldShowExportImport={!isDigitalInSelected}
      isAddToBagDisabled={isAddToBagDisabled}
      shouldShowSamplesSwitch={false}
      shouldShowDeleteButton={false}
      isSamplesSwitchEnabled={false}
      selectedNumber={selectedMaterials?.length ?? 0}
      onCancel={handleMultiSelectToggle}
      onAddToBoard={handleAddToBoard}
      onAddToBag={handleAddToBag}
      onExportClick={handleExportClick}
      onSelectAll={handleSelectAll}
      onCompareClick={handleCompareClick}
      isSelectAllDisabled={isSelectAllDisabled}
      selectAllTooltip={isSelectAllDisabled ? selectAllTooltip : undefined}
      addToBagTooltip={isProductInSelected ? addToBagTooltip : undefined}
      {...textLabels}
    />
  );
};

export default MultiSelectContainer;
