export const isFeatureOn = async (
  featureName: string,
  customer: {
    isTestUser: boolean;
    groupId: number;
  } | null,
  onFlag: string = 'on',
): Promise<boolean> => {
  return new Promise((resolve) => {
    const client = window.splitFactory?.client();

    if (!customer) {
      console.warn(
        'isFeatureOn: customer is not provided, provide a customer object',
      );
      return resolve(false);
    }

    if (!client) {
      return resolve(false);
    }

    client.ready().then(() => {
      const treatment = client.getTreatment(featureName, {
        isTestUser: customer?.isTestUser ?? false,
        groupID: customer?.groupId ?? 100,
      });

      if (treatment === onFlag) {
        return resolve(true);
      } else {
        return resolve(false);
      }
    });
  });
};
