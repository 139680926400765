import React, { FC } from 'react';
import DOMPurify from 'dompurify';
import useSWR from 'swr';
import styles from './DynamicIcon.module.scss';
import { StartIcon, LoadingIcon } from './icons';
import { replaceFillColor } from '../utilities';
interface DynamicIconProps {
  iconUrl: string;
  [key: string]: string | number | boolean;
}

const fetcher = (url: string) => fetch(url).then((res) => res.text());

export const DynamicIcon: FC<DynamicIconProps> = ({ iconUrl, ...props }) => {
  const { data, error, isLoading } = useSWR(iconUrl, fetcher);

  if (error || (!data && !isLoading)) {
    return <StartIcon />;
  }
  if (isLoading) {
    return (
      <div className={styles['DynamicIcon--spin']}>
        <LoadingIcon />
      </div>
    );
  }

  const sanitizedData = DOMPurify.sanitize(replaceFillColor(data));

  return <div {...props} dangerouslySetInnerHTML={{ __html: sanitizedData }} />;
};
