import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import ColorWheelIcon from 'components/Elements/ColorWheelIcon/ColorWheelIcon';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as Styled from './MobileResultsHeader.styles';
import { MobileSort } from '../../../../components/Filter/MobileSort';
import { SortingIcon } from '../../../../components/Filter/SortingIcon';
import { definedTranslation } from '../../../../i18n';
import { resetMobileActiveFilters } from '../../../../redux/sliceCreators/filtersSlice';
import { MobileFilterContainer } from '../../../MobileFilterContainer';
import {
  ColorWheelWrapper,
  CloseRainbowButton,
} from '../../ResultsHeader.styles';

type MobileResultsHeaderProps = {
  isSortingDisabled: boolean;
  appFilters: Record<string, object>;
  isColorCircleTopbarShown: boolean;
  onColorsToggle: () => void;
};

const MobileResultsHeader = (props: MobileResultsHeaderProps) => {
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isSortOpen, setSortOpen] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();

  const labels = {
    filters: intl.formatMessage(definedTranslation.filters.filtersButton),
  };

  useEffect(() => {
    let timer: any;
    if (!isFilterOpen) {
      timer = setTimeout(() => {
        dispatch(resetMobileActiveFilters());
      }, 300);
    }
    return () => clearTimeout(timer);
  }, [isFilterOpen]);

  const toggleFiltersMobile = () => {
    setFilterOpen((prevOpen) => !prevOpen);
  };

  const toggleMobileSort = () => {
    setSortOpen((prevOpen) => !prevOpen);
  };

  const selectedFiltersNumber = Object.values(props.appFilters)?.length;

  return (
    <Styled.MobileResultsOuterWrapper>
      <Box
        display="flex"
        justifyContent="flex-end"
        flexGrow="1"
        alignItems="center"
      >
        <Styled.ButtonWithBadgeContainer>
          <Styled.FilterButton
            iconDense
            color="inherit"
            size="medium"
            variant="outlined"
            label={labels.filters}
            startIcon="filters"
            onClick={toggleFiltersMobile}
          />
          {Boolean(selectedFiltersNumber) && (
            <Styled.Badge>{selectedFiltersNumber}</Styled.Badge>
          )}
        </Styled.ButtonWithBadgeContainer>
        <Styled.Button
          onClick={toggleMobileSort}
          disabled={props.isSortingDisabled}
        >
          <SortingIcon />
        </Styled.Button>
        <ColorWheelWrapper>
          {!props?.isColorCircleTopbarShown ? (
            <ColorWheelIcon
              wrapperClassName="colorwheelIcon"
              onClick={() => props?.onColorsToggle?.()}
            />
          ) : (
            <CloseRainbowButton
              dense
              size="small"
              color="secondary"
              iconName="close"
              onClick={() => props?.onColorsToggle?.()}
            />
          )}
        </ColorWheelWrapper>
        <MobileFilterContainer
          open={isFilterOpen}
          onClose={toggleFiltersMobile}
          intl={intl}
        />
        <MobileSort open={isSortOpen} onClose={toggleMobileSort} />
      </Box>
    </Styled.MobileResultsOuterWrapper>
  );
};

export default MobileResultsHeader;
