import { parseSsFacetColorFilter } from './parseSsFacetColorFilter';

export const getActiveSSFacetColorFilter = (
  ssFacetColorFilter: string,
  params: {
    searchQuery: string;
    selectedColor: string;
  },
) => {
  const { searchQuery, selectedColor } = params;
  if (!ssFacetColorFilter || (!searchQuery && !selectedColor)) {
    return null;
  }

  const facetColorFilter = parseSsFacetColorFilter(ssFacetColorFilter);
  if (facetColorFilter.length === 0) {
    return null;
  }

  if (searchQuery) {
    const ssFacetsMatchingColor = facetColorFilter.filter((colorFilter) => {
      return searchQuery
        .toLowerCase()
        .includes(colorFilter.exact_color?.toLowerCase());
    });
    if (ssFacetsMatchingColor.length > 0) {
      return ssFacetsMatchingColor[ssFacetsMatchingColor.length - 1];
    }
  }

  if (selectedColor) {
    const imageFacetsByColor = facetColorFilter.filter((colorFilter) => {
      return colorFilter.color_name === selectedColor;
    });
    const imageFacetByColor = imageFacetsByColor[imageFacetsByColor.length - 1];
    if (imageFacetByColor?.image_url != null) {
      return imageFacetByColor;
    }
  }

  return null;
};
