import React, { FC, useEffect, useMemo, useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { CategoriesPickerV2 } from 'components/components/CategoriesPickerV2';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { getSortedItems, getLabel } from './CategoriesContainer.helpers';
import * as Styled from './CategoriesContainer.styles';
import { searchActionsEnum } from '../../config/constants';
import { useFiltersContext } from '../../contexts';
import { useUpdateEffect } from '../../hooks/customHooks';
import { definedTranslation } from '../../i18n';
import {
  isFilterShownSelector,
  appFiltersSelector,
  taxonomyChildrenFilterSelector,
  appFiltersKeysSelector,
  taxonomyParentsFilterSelector,
  taxonomySelector,
  taxonomyFilterValueSelector,
} from '../../redux/selectors/filtersSelector';
import {
  searchOptionsSelector,
  searchResultsSelector,
} from '../../redux/selectors/searchSelector';
import {
  clearFilters,
  setAppFilters,
  toggleFiltersSidebar,
  addFilter,
  removeFilter,
  setIsRainbowClicked,
} from '../../redux/sliceCreators/filtersSlice';
import { setSearchOptions } from '../../redux/sliceCreators/searchSlice';
import { FilterData } from '../../types';
import { getCharsNumber } from '../../utils/helpers';

const taxonomyFacetField = 'taxonomy';

interface CategoriesContainerProps {
  addToCartButtonWidth?: number;
  fullWidth?: boolean;
}

const CategoriesContainer: FC<CategoriesContainerProps> = ({
  addToCartButtonWidth,
  fullWidth = false,
}) => {
  // @ts-expect-error // TODO: fix types
  const { getNewFilters } = useFiltersContext();
  const results = useSelector(searchResultsSelector);
  const appliedFilters = useSelector(appFiltersSelector);
  const currentFilterKeys = useSelector(appFiltersKeysSelector);
  const searchOptions = useSelector(searchOptionsSelector);
  const taxonomyFacet = useSelector(taxonomySelector);
  const taxonomyParents = useSelector(taxonomyParentsFilterSelector);
  const taxonomyChildren = useSelector(taxonomyChildrenFilterSelector);
  const isFilterShown = useSelector(isFilterShownSelector);
  const taxonomySearchValue = useSelector(taxonomyFilterValueSelector);
  const [tabFilters, setTabFilters] = useState<FilterData[]>([]);
  const windowWidth = useWindowWidth();
  const dispatch = useDispatch();
  const intl = useIntl();
  const allButtonText = intl.formatMessage(
    definedTranslation.filters.allCategories,
  );
  const backButtonText = intl.formatMessage(
    definedTranslation.filters.backButton,
  );

  const isNewRequestAction = useMemo(() => {
    // @ts-expect-error // TODO: fix types
    const action = searchOptions.action;
    const actionsToChangeTabs = [
      searchActionsEnum.init,
      searchActionsEnum.searchBar,
      searchActionsEnum.initPrevResults,
      searchActionsEnum.reset,
      searchActionsEnum.resetFilters,
    ];
    const isCleanRequest = currentFilterKeys?.length === 0;
    return (
      actionsToChangeTabs.includes(action) || isCleanRequest || action == null
    );
  }, [searchOptions, currentFilterKeys]);

  const {
    isSubcategorySelected,
    parentLabel,
    isMultipleParents,
    isAnyParentInCurrentFilters,
  } = useMemo(() => {
    const firstSelectedChild = currentFilterKeys?.find((item) => {
      return (
        item.includes(taxonomyFacetField) && getCharsNumber(item, '>') === 2
      );
    });
    const taxonomyFilters = currentFilterKeys?.filter((item) => {
      return item.includes(taxonomyFacetField);
    });
    const isAnyParentInCurrentFilters = taxonomyFilters.some(
      (item) => getCharsNumber(item, '>') === 1,
    );
    const parentLabels = taxonomyFilters?.map((item) => item?.split('>')?.[1]);
    const uniqParents = uniq(parentLabels);
    return {
      parentLabel: firstSelectedChild?.split('>')?.[1],
      isSubcategorySelected: firstSelectedChild != null,
      isMultipleParents: uniqParents?.length > 1,
      isAnyParentInCurrentFilters,
    };
  }, [currentFilterKeys, isNewRequestAction]);

  const { isParentSelected, selectedParentLabel, sortedParents } =
    useMemo(() => {
      const selectedParent = taxonomyParents?.find(
        (item: FilterData) => item.active,
      );
      return {
        isParentSelected: Boolean(selectedParent) || isSubcategorySelected,
        selectedParentLabel: getLabel(selectedParent?.label, 1),
        selectedParentCount: selectedParent?.count,
        sortedParents: getSortedItems(taxonomyParents),
      };
    }, [taxonomyParents, isSubcategorySelected]);

  const appliedFiltersNumber = useMemo(() => {
    return (currentFilterKeys || []).length;
  }, [currentFilterKeys, isNewRequestAction]);

  useEffect(() => {
    if (isNewRequestAction) {
      resetChildrenTaxonomyState();
    }
  }, [taxonomyChildren, isNewRequestAction]);

  useUpdateEffect(() => {
    resetChildrenTaxonomyState();
    //setTotalResults(pagination?.totalResults);
  }, [isParentSelected]);

  useUpdateEffect(() => {
    const currentFilters = currentFilterKeys;
    if (isMultipleParents) {
      setTabFilters([]);
    }
    setTabFilters((prev) => {
      if (prev?.length === 0) return prev;

      const updatedList = prev.map((item) => {
        return !item.active
          ? item
          : { ...item, active: currentFilters?.includes(item.id) };
      });
      return updatedList;
    });
  }, [currentFilterKeys]);

  const resetChildrenTaxonomyState = () => {
    const sortedCategories = getSortedItems(taxonomyChildren);
    setTabFilters(sortedCategories);
  };

  const tabsData = useMemo(() => {
    const sortedReduxTaxonomy = getSortedItems(taxonomyChildren);
    const finalChildrenData = sortedReduxTaxonomy;
    const finalData = !isParentSelected ? sortedParents : finalChildrenData;
    const labelArrowsNumber = !isParentSelected ? 1 : 2;

    return finalData?.map((sub) => ({
      id: sub.id,
      title: getLabel(sub.label, labelArrowsNumber),
      selected: sub.active,
      counter: sub.count,
      filter: sub,
    }));
  }, [tabFilters, taxonomyFacet, results, isParentSelected, currentFilterKeys]);

  // Handle Edge-case, when from response.override comes multiple parents, which can't be
  // done in FE by user. Moved from memo-tabsData, to not mess with localState for tabs
  const tabsWithMultiParents = useMemo(() => {
    const onlyActiveChildren = taxonomyFacet?.filter(
      (item: FilterData) =>
        item.active && getCharsNumber(item?.label, '>') === 2,
    );
    const labelArrowsNumber = !isParentSelected ? 1 : 2;
    return onlyActiveChildren?.map((sub: FilterData) => ({
      id: sub.id,
      title: getLabel(sub.label, labelArrowsNumber),
      selected: sub.active,
      counter: sub.count,
      filter: sub,
    }));
  }, [taxonomyFacet, isParentSelected]);

  const handleFilterToggle = () => {
    dispatch(toggleFiltersSidebar(null));
  };

  const handleUpdateItems = (
    filter: FilterData | null,
    currentTabs: FilterData[],
  ) => {
    const currentLocalFilter = currentTabs.find(
      (item) => item.id === filter?.id,
    );
    if (currentLocalFilter != null) {
      setTabFilters((prev) => {
        const updatedList = prev.map((item) => {
          return item.id === filter?.id
            ? { ...item, active: !filter.active }
            : item;
        });
        return getSortedItems(updatedList);
      });
    }
  };

  const handleCategoryClick = (filter: FilterData) => {
    const filterLabel = getLabel(filter?.label);
    const filterData = {
      ...filter,
      parent: filterLabel,
    };
    if (filter?.active) {
      dispatch(removeFilter(filterData));
    } else {
      dispatch(addFilter([filterData]));
    }
    handleUpdateItems(filter, tabFilters);
    window.splitFactory
      ?.client()
      ?.track('user', 'mb_monolith_filter_click', undefined, {
        filterLocation: window.location.pathname.includes('curatedcollections')
          ? 'collections'
          : 'search',
      });
  };

  const handleFiltersClick = (filter: FilterData) => {
    if (filter?.active) {
      dispatch(removeFilter({ id: filter.id }));
    } else {
      const [key, value] = filter?.id?.split('=');
      const newFilter = getNewFilters(key, value, appliedFilters);
      dispatch(addFilter(newFilter));
    }
  };

  const resetFilters = () => {
    dispatch(
      setSearchOptions({
        ...searchOptions,
        action: searchActionsEnum.resetFilters,
      }),
    );
    dispatch(clearFilters(null));
  };

  const handleSeeAllClick = () => {
    if (isSubcategorySelected) {
      // Clear all filters beside Taxonomy parent
      const filterKeys = currentFilterKeys.filter((key) => {
        const arrowsNumber = getCharsNumber(key, '>');
        return !(key.includes(taxonomyFacetField) && arrowsNumber === 1);
      });
      const filteredFilters = omit(appliedFilters, filterKeys);
      if (!isAnyParentInCurrentFilters) {
        // Edge case, when coming from override different children from different parent
        // and removed one by one, till All [Parent] button will appear.
        const firstTaxonomyChild = currentFilterKeys?.find((key) => {
          const arrowsNumber = getCharsNumber(key, '>');
          return key.includes(taxonomyFacetField) && arrowsNumber === 2;
        });
        if (firstTaxonomyChild != null) {
          const parentLabelRaw = firstTaxonomyChild
            ?.split('=')?.[1]
            ?.split('>');
          parentLabelRaw.pop();
          const parentLabel = parentLabelRaw.join('>');
          if (parentLabel != null) {
            const newId = `filter.${taxonomyFacetField}=${parentLabel}`;
            // @ts-expect-error // TODO: fix types
            filteredFilters[newId] = {
              id: newId,
              field: taxonomyFacetField,
              label: parentLabel,
              value: parentLabel,
            };
          }
        }
      }
      dispatch(setAppFilters(filteredFilters));
    } else {
      resetFilters();
    }
    dispatch(setIsRainbowClicked(false));
  };

  const isShowAllActive = !isParentSelected
    ? appliedFiltersNumber === 0
    : !isSubcategorySelected;
  const showAllLabel = isParentSelected
    ? `${allButtonText} ${selectedParentLabel || parentLabel}`
    : allButtonText;
  const shouldShowAllButton = !isMultipleParents;

  return (
    <Styled.CatagoriesWrapper
      addToCartButtonWidth={addToCartButtonWidth}
      fullWidth={fullWidth}
      id="topbar_filters_products"
    >
      <CategoriesPickerV2
        shouldShowSecondLine={false}
        shouldShowAllButton={shouldShowAllButton}
        showAllLabel={showAllLabel}
        tabs={isMultipleParents ? tabsWithMultiParents : tabsData}
        shouldShowBackButton={isParentSelected}
        rightElementWidth={420}
        shouldResetScroll={
          isNewRequestAction || (isParentSelected && !isSubcategorySelected)
        }
        shouldShowFiltersToggle={windowWidth > 1024}
        showAllNumber={undefined}
        appliedFiltersNumber={isFilterShown ? 0 : appliedFiltersNumber}
        isShowAllActive={isShowAllActive}
        isFilterActive={isFilterShown}
        onFilterToggle={handleFilterToggle}
        // @ts-expect-error // TODO: fix types
        onTabClick={handleCategoryClick}
        onSeeAllClick={handleSeeAllClick}
        // @ts-expect-error // TODO: fix types
        onSubTabsClick={handleFiltersClick}
        onBackButtonClick={resetFilters}
        filterValue={taxonomySearchValue}
        backButtonText={backButtonText}
      />
    </Styled.CatagoriesWrapper>
  );
};

export default CategoriesContainer;
