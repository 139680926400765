import React from 'react';
import { MenuProps } from '@mui/material';
import { CustomIcon } from 'components/CustomIcon';
import { MBMenuItem } from 'components/Elements/MBMenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import * as Styled from './Sorting.styles';
import { searchSortingListSelector } from '../../../redux/selectors/searchSortingSelector';
import { currentSortOptionSelector } from '../../../redux/selectors/searchSortingSelector';
import { setCurrentSortOption } from '../../../redux/sliceCreators/sortingSlice';
import { setUrlSortOption } from '../../../utils';

export type SortItem = {
  direction: string;
  field: string;
  label: string;
};

type SortingProps = {
  menuProps?: MenuProps;
  onSort?: (sortItem: SortItem) => void;
};

const Sorting = (props: SortingProps) => {
  const dispatch = useDispatch();
  const sortingList = useSelector(searchSortingListSelector);
  const currentSortOption = useSelector(currentSortOptionSelector);
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  // @ts-expect-error // TODO: fix types
  const handleSortOptionClick = (sortItem) => {
    if (sortItem.label === currentSortOption?.label) {
      dispatch(setCurrentSortOption(null));
      setUrlSortOption({}, true);
    } else {
      dispatch(setCurrentSortOption(sortItem));
      setUrlSortOption(sortItem);
    }
    props.onSort?.(sortItem);
  };

  if (isMobile) {
    // @ts-expect-error // TODO: fix types
    return sortingList?.map((sortItem, index) => (
      <Styled.Option
        key={index}
        onClick={() => handleSortOptionClick(sortItem)}
      >
        <Styled.OptionName>{sortItem.label}</Styled.OptionName>
        <Styled.SelectedIcon
          // @ts-expect-error // TODO: fix types
          variant="body2"
          show={currentSortOption?.label === sortItem.label}
        >
          <CustomIcon name="tick" dense />
        </Styled.SelectedIcon>
      </Styled.Option>
    ));
  }

  return (
    // @ts-expect-error // TODO: fix types
    <Styled.Dropdown {...props.menuProps}>
      {/* @ts-expect-error // TODO: fix types */}
      {sortingList?.map((sortItem, index) => {
        // with just label validation - we can't set sort option,
        // when it comes from paramOverride  in Search API
        const isSelected =
          sortItem.field === currentSortOption?.field &&
          sortItem.direction === currentSortOption?.direction;
        return (
          <MBMenuItem
            key={index}
            isSelected={isSelected}
            onClick={() => handleSortOptionClick(sortItem)}
          >
            {sortItem.label}
          </MBMenuItem>
        );
      })}
    </Styled.Dropdown>
  );
};

export { Sorting };
