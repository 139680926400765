import React, { useMemo, useCallback } from 'react';
import { Divider } from '@mui/material';
import { Box } from '@mui/system';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as Styled from './FiltersListContainer.styles';
import { BrandSwitch } from '../../components/Filter/BrandSwitch';
import { FamilyViewSwitch } from '../../components/Filter/FamilyViewSwitch';
import { FilterItem } from '../../components/Filter/FilterItem';
import { FilterSwitch } from '../../components/Filter/FilterSwitch';
import { searchActionsEnum } from '../../config/constants';
import { useFiltersContext } from '../../contexts';
import { definedTranslation } from '../../i18n';
import {
  appFiltersSelector,
  currentFiltersSelector,
  mobileActiveFilterSelector,
  residentialCommercialFacetSelector,
} from '../../redux/selectors/filtersSelector';
import { searchOptionsSelector } from '../../redux/selectors/searchSelector';
import {
  addFilter,
  pushMobileActiveFilters,
  removeFilter,
} from '../../redux/sliceCreators/filtersSlice';
import { setSearchOptions } from '../../redux/sliceCreators/searchSlice';
import { FilterData } from '../../types';
import { splitSwitches } from '../../utils';
import { isBannersPage } from '../../utils/helpers';

// Facets, which should be ignored and not shown in frontend
const filterRedundantFacets = ['taxonomy'];

const isCollectionsPage = isBannersPage();

type FiltersListContainerProps = {
  onCollapseChange?: () => void;
  isMobile?: boolean;
};

const FiltersListContainer = ({
  onCollapseChange,
  isMobile,
}: FiltersListContainerProps) => {
  const dispatch = useDispatch();
  const appFilters = useSelector(appFiltersSelector);
  const searchOptions = useSelector(searchOptionsSelector);
  const filters = useSelector(currentFiltersSelector);
  const mobileActiveFilter = useSelector(mobileActiveFilterSelector);
  const residentialCommercialFacet = useSelector(
    residentialCommercialFacetSelector,
  );
  const intl = useIntl();

  const labels = {
    searchPlaceholder: intl.formatMessage(
      definedTranslation.filters.searchPlaceholder,
    ),
  };

  // @ts-expect-error // TODO: fix types
  const { getFiltersByField, getFilterById, getFiltersByPropertyValue } =
    useFiltersContext();

  const [switches, filterList] = useMemo(() => {
    return splitSwitches(filters || [], filterRedundantFacets);
  }, [filters]);

  const currentFilter = useMemo(() => {
    return (
      filterList?.find(
        (item) =>
          item.field === mobileActiveFilter?.field &&
          item.label === mobileActiveFilter?.label,
      ) ?? mobileActiveFilter
    );
  }, [filterList, mobileActiveFilter]);

  const handleToggleSwitch = useCallback(
    (value: FilterData, isActive: boolean) => {
      if (!isActive) {
        dispatch(addFilter(value));
      } else {
        dispatch(removeFilter(value));
      }
    },
    [dispatch],
  );

  const handleAddFilter = useCallback(
    (value: FilterData) => {
      dispatch(addFilter(value));
    },
    [dispatch],
  );

  const handleRemoveFilter = useCallback(
    (value: FilterData) => {
      // @ts-expect-error // TODO: fix types
      if (searchOptions?.action === searchActionsEnum.searchBar) {
        dispatch(
          setSearchOptions({
            ...searchOptions,
            action: searchActionsEnum.filter,
          }),
        );
      }
      dispatch(removeFilter(value));
    },
    [dispatch, searchOptions],
  );

  const handleMobileOpenFilter = useCallback(
    (value: FilterData) => {
      dispatch(pushMobileActiveFilters(value));
    },
    [dispatch],
  );

  const finalSwitches = useMemo(() => {
    const facetValues =
      residentialCommercialFacet?.field != null
        ? residentialCommercialFacet?.values
        : [];
    return [...switches, ...facetValues];
  }, [switches, residentialCommercialFacet]);

  if (isMobile && mobileActiveFilter) {
    return (
      <FilterItem
        // @ts-expect-error // TODO: fix types
        isMobile
        data={currentFilter}
        appFilters={appFilters}
        getFiltersByField={getFiltersByField}
        removeFilter={handleRemoveFilter}
        getFilterById={getFilterById}
        getFiltersByPropertyValue={getFiltersByPropertyValue}
        addFilter={handleAddFilter}
        onCollapseChange={onCollapseChange}
        mobileActiveFilter={currentFilter}
        onMobileOpenFilter={handleMobileOpenFilter}
        intl={intl}
      />
    );
  }

  return (
    <>
      <Box mt={'0.5em'} py={'0.5em'}>
        {finalSwitches.map((filter) => (
          <FilterSwitch
            key={`${filter?.field}-${filter?.label}`}
            // @ts-expect-error // TODO: fix types
            data={filter}
            appFilters={appFilters}
            onToggleSwitch={handleToggleSwitch}
          />
        ))}
        {!isCollectionsPage && <FamilyViewSwitch />}
        <BrandSwitch intl={intl} />
      </Box>
      <Divider sx={{ marginBottom: '0.5em' }} />

      {filters?.length !== 0 &&
        filterList.map((filter) => {
          //don't render filters without any values
          if (filter?.values?.length < 1) {
            return null;
          }

          const id = `${filter?.field}-${filter?.label}`;
          return (
            <Styled.FilterItem key={id}>
              <FilterItem
                key={id}
                // @ts-expect-error // TODO: fix types
                id={id}
                isMobile={isMobile}
                data={filter}
                appFilters={appFilters}
                getFiltersByField={getFiltersByField}
                removeFilter={handleRemoveFilter}
                getFilterById={getFilterById}
                getFiltersByPropertyValue={getFiltersByPropertyValue}
                addFilter={handleAddFilter}
                onCollapseChange={onCollapseChange}
                mobileActiveFilter={mobileActiveFilter}
                onMobileOpenFilter={handleMobileOpenFilter}
                searchPlaceholderText={labels.searchPlaceholder}
                intl={intl}
              />
            </Styled.FilterItem>
          );
        })}
    </>
  );
};

export default FiltersListContainer;
