// @ts-nocheck TODO: fix types for the entire file

import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 1.5em;
  padding: 0.5em;
  img {
    width: 100%;
    pointer-events: none;
    mix-blend-mode: multiply;
    border-radius: 20px;
    max-height: 300px;
    object-fit: contain;
  }
`;

export const Link = styled.a<{ isClickable?: boolean }>`
  display: flex;
  width: 100%;
  position: relative;
  border-radius: 20px;
  pointer-events: ${({ isClickable }) => (isClickable ? 'auto' : 'none')};
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;
