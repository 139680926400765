import {
  AnalyticsEventName,
  CustomEventName,
  isProjectTypeOther,
} from '@mb/lib';
import { IProject, IProjectTypes } from '../types';

export const handleTriggerProjectSelectModal = (
  projectId: string | number,
  analyticsEventLocation: string,
  onCurrentProjectSave: (project: IProject) => void,
  onProjectCreate: (project: IProject) => void,
  isDefaultView?: boolean,
) => {
  const defaultViewData = isDefaultView
    ? { defaultView: 'UPDATE_PROJECT' }
    : {};
  window.dispatchEvent(
    new CustomEvent(CustomEventName.OPEN_PROJECT_SELECTION_DIALOG, {
      detail: {
        ...defaultViewData,
        currentProjectId: projectId,
        analyticsEventLocation,
        onCurrentProjectSave: (project: IProject) => {
          onCurrentProjectSave?.(project);
        },
        onProjectCreate: (project: IProject) => {
          onProjectCreate?.(project);
        },
      },
    }),
  );
};

export const handleProjectDropdownClick = (
  project: IProject,
  projectTypes: IProjectTypes[],
  analyticsEventLocation: string,
  onCurrentProjectSave: (project: IProject) => void,
  onProjectCreate: (project: IProject) => void,
  onCurrentProjectSet: (id: string | number) => void,
) => {
  if (project) {
    const isOtherProjectType = isProjectTypeOther({
      projectTypes,
      projectType: String(project.project_type),
    });
    const isProjectLocationUnknown = !project.project_location;
    const isProjectPhaseUnknown = !project.phase_id;
    const isProjectDescriptionUnset = !project.project_details;

    if (
      isOtherProjectType ||
      isProjectLocationUnknown ||
      isProjectPhaseUnknown ||
      isProjectDescriptionUnset
    ) {
      handleTriggerProjectSelectModal(
        project.project_id,
        analyticsEventLocation,
        onCurrentProjectSave,
        onProjectCreate,
        true,
      );
    } else {
      onCurrentProjectSet?.(project.project_id);

      mitGTM.sendEvent({
        event: AnalyticsEventName.SET_PROJECT,
        event_location: analyticsEventLocation,
        action: 'Select',
      });
    }
  }
};
