import React, {
  useEffect,
  useMemo,
  useCallback,
  useRef,
  Fragment,
} from 'react';
import {
  AnalyticsEventLocation,
  AnalyticsEventName,
  CustomEventName,
  Project,
  isProjectTypeOther,
} from '@mb/lib';
import { getUserLang } from '@mb/lib/i18n';
import { SplitTrafficType, SPLIT_EVENTS } from '@mb/lib/splitio';
import { useProjectTypes } from '@mb/redux/selectors';
import { Pagination } from '@mui/material';
import debounce from 'lodash/debounce';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { ContentBlock } from './ContentBlock';
import { ContentBlocksModalContainer } from './ContentBlocksModalContainer';

import { ContentBlockType, OnClickCardInterface } from './types';
import {
  getPositionMap,
  calculateCBRows,
  calculateProductRow,
} from './utilities';
import { getProductCardLabels } from './utilities/getProductCardLabels';
import { sendAnalytics } from '../../api/events';
import { getMaterialsByKey } from '../../api/materials';
import { getUserProjects } from '../../api/projects';
import { CampaignBanner } from '../../components/CampaignBanner';
import { ProductCartClickProps } from '../../components/Cards/ResultCard/types';
import { EmptyResult } from '../../components/EmptyResult';
import { GridSkeleton } from '../../components/GridSkeleton';

import { config } from '../../config';
import { useSearchContext } from '../../contexts';
import useAddToBagWithValidations from '../../hooks/useAddToBagWithValidations';
import useCurrentProject from '../../hooks/useCurrentProject';
import { usePrevious } from '../../hooks/usePrevious';
import useSwitchCurrentUserProject from '../../hooks/useSwitchCurrentUserProject';
import { useViewScrollableProductsListEvent } from '../../hooks/useViewScrollableProductsListEvent';
import { pushColorwaysClickAnalytics } from '../../pushColorwaysClickAnalytics';
import {
  cartItemsSelector,
  comparisonItemsSelector,
  isComparisonActiveSelector,
  isContentBlocksModalOpenSelector,
  isSearchLiteSelector,
} from '../../redux/selectors/commonSelector';
import {
  multiSelectToggleSelector,
  selectedMaterialsSelector,
  searchToggleSelector,
  allCurrentPrimaryColorsLabelsSelector,
  appFiltersSelector,
} from '../../redux/selectors/filtersSelector';
import { projectsListSelector } from '../../redux/selectors/projectsSelector';
import {
  searchPaginationSelector,
  searchOptionsSelector,
  isSearchingSelector,
  searchResultsSelector,
  campaignFooterBannerSelector,
  campaignContentBannerSelector,
  isSearchRedirectSelector,
  selectedVariantsSelector,
  xRequestIdSelector,
  contentBlocksSelector,
} from '../../redux/selectors/searchSelector';
import {
  setSnackbarInfo,
  removeCartItemFromQueue,
  updateComparisonItems,
  setContentBlocksModalOpen,
  setActiveContentBlock,
  setCMSModalOpen,
} from '../../redux/sliceCreators/commonSlice';
import {
  setMultiSelectToggle,
  toggleSelectedMaterial,
} from '../../redux/sliceCreators/filtersSlice';
import {
  setIsProjectModalOpen,
  setCurrentProjectId,
  setCreatedProject,
  setProjectModalEventLocation,
} from '../../redux/sliceCreators/projectsSlice';
import {
  gridColumnNumber,
  setSelectedVariants,
} from '../../redux/sliceCreators/searchSlice';

import { ProductSearchMaterial, SnackbarStatusEnum } from '../../types';
import {
  areComponentPropsEqual,
  transformSearchspringResponseToAppFormat,
} from '../../utils';
import {
  isSearchPage,
  isCuratedCollectionPage,
  isBannersPage,
  isPartnersPage,
  pushSearchAnalyticsDataLayer,
  getTaxonomyAnalyticsDataFromFilters,
  handleAddToBoards,
} from '../../utils/helpers';
import { ItemContainer } from '../MaterialsContainer/ItemContainer';
import * as Styled from '../MaterialsContainer/MaterialsContainer.styles';
import { ResultCardWrapper } from '../MaterialsContainer/ResultCardWrapper';

const isSearch = isSearchPage();
const isBannersSearch = isBannersPage();
const isPartnersSearch = isPartnersPage();
const userLang = getUserLang();

const activeBagTime = 2500;
const defaultImageSize = 252;

export const SearchResultsContainer = React.memo(() => {
  const dispatch = useDispatch();
  const isContentBlockModalOpen = useSelector(isContentBlocksModalOpenSelector);
  const contentBlocks = useSelector(contentBlocksSelector);
  const results = useSelector(searchResultsSelector);
  const pagination = useSelector(searchPaginationSelector);
  const searchOptions = useSelector(searchOptionsSelector);
  const campaignContentBanner = useSelector(campaignContentBannerSelector);
  const projectTypes = useProjectTypes();
  // @ts-expect-error // TODO: fix types
  const { handleNextPage } = useSearchContext();
  const { setCurrentUserProject } = useSwitchCurrentUserProject();
  const selectedMaterials = useSelector(selectedMaterialsSelector);
  const comparisonItems = useSelector(comparisonItemsSelector);
  const searchToggle = useSelector(searchToggleSelector);
  const isMultiselect = useSelector(multiSelectToggleSelector);
  const isSearchLite = useSelector(isSearchLiteSelector);
  const xRequestId = useSelector(xRequestIdSelector);
  const currentSelectedColors = useSelector(
    allCurrentPrimaryColorsLabelsSelector,
  );
  const appFilters = useSelector(appFiltersSelector);
  const isSearchRedirect = useSelector(isSearchRedirectSelector);

  const { currentProject } = useCurrentProject();
  const projects = useSelector(projectsListSelector);
  const isSearching = useSelector(isSearchingSelector);
  const campaignBottomBanner = useSelector(campaignFooterBannerSelector);
  const cartItems = useSelector(cartItemsSelector);
  const selectedVariants = useSelector(selectedVariantsSelector);
  const isComparisonActive = useSelector(isComparisonActiveSelector);
  const previousResultsLength = usePrevious(results?.length);

  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const isSmallDesktop = useMediaQuery({ query: '(max-width: 1300px)' });
  const intl = useIntl();
  const { handleAddToBag, loadingIds } = useAddToBagWithValidations();

  const cardStaticLabels = useMemo(() => {
    return getProductCardLabels(intl);
  }, [intl]);

  const gridContainerRef = useRef<HTMLDivElement>(null);
  const resultContainerRef = useRef<HTMLDivElement>(null);

  const multiselectIds = useMemo(() => {
    const relevantList = isComparisonActive
      ? comparisonItems
      : selectedMaterials;
    return (relevantList ?? []).map(
      (item: ProductSearchMaterial) => item.entityId,
    );
  }, [selectedMaterials, comparisonItems, isComparisonActive]);

  const currentProjectData = {
    id: currentProject?.project_id,
    label: currentProject?.project_title ?? 'Select project..',
  };

  const structuredProjects = useMemo(() => {
    return [...projects].map((project) => ({
      id: project.project_id,
      label: project.project_title,
    }));
  }, [projects]);

  const updateLocalStorage = useCallback(
    debounce(() => {
      sessionStorage.setItem('ssScroll', String(window.scrollY));
    }, 500),
    [],
  );

  const handleScroll = () => {
    updateLocalStorage();
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // If something was added to Cart, show isAdded icon for X secconds,
  // and then remove icon
  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const oldCartItems = cartItems?.filter(
        // @ts-expect-error // TODO: fix types
        (item) => now - item.time >= activeBagTime,
      );
      if (oldCartItems?.length > 0) {
        dispatch(removeCartItemFromQueue(oldCartItems));
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [cartItems]);

  useEffect(() => {
    //do this only on mount, when coming back from PDP
    if (results?.length > 0 && previousResultsLength === 0) {
      const ssScroll = sessionStorage.getItem('ssScroll');
      const prevUrl = sessionStorage.getItem('prevSearchUrl');
      const currentUrl = window.location.href;
      if (ssScroll != null && prevUrl != null && prevUrl === currentUrl) {
        setTimeout(() => {
          window.scrollTo(0, Number(ssScroll));
        }, 500);
      }
    }
  }, [results?.length]);

  const contentBlocksMapping = useMemo(() => {
    return getPositionMap(isMobile, results.length, contentBlocks.length);
  }, [isMobile, results, contentBlocks]);

  const handleMaterialSelect = (product: ProductSearchMaterial) => {
    if (isComparisonActive) {
      dispatch(updateComparisonItems(product));
      return;
    }
    if (!isMultiselect) {
      dispatch(setMultiSelectToggle(true));
    }
    if (
      selectedMaterials?.length >= config.maxSelectNumber &&
      selectedMaterials?.find(
        (item: ProductSearchMaterial) => item.entityId === product.entityId,
      ) == null
    ) {
      dispatch(
        setSnackbarInfo({
          isOpen: true,
          message: `Maximum ${config.maxSelectNumber} materials can be selected`,
          status: SnackbarStatusEnum.WARNING,
        }),
      );
    } else {
      dispatch(toggleSelectedMaterial(product));
    }
  };

  const handleOnColorVariantsClick = useCallback(
    (product: ProductSearchMaterial) => {
      window.splitFactory
        ?.client()
        ?.track(SplitTrafficType.USER, SPLIT_EVENTS.Variants_click, undefined, {
          variationClickSource: 'PC',
        });
      dispatch(
        setSelectedVariants({
          [product.parentEntityId || '']: product.entityId,
        }),
      );
      pushColorwaysClickAnalytics(product);
    },
    [],
  );

  const handleProjectCreate = useCallback(
    (params?: { analyticsEventLocation?: string }) => {
      const { analyticsEventLocation } = params ?? {};

      dispatch(setIsProjectModalOpen(true));

      if (analyticsEventLocation) {
        dispatch(setProjectModalEventLocation(analyticsEventLocation));
      }
    },
    [],
  );

  const onProjectCreate = useCallback((project: Project) => {
    dispatch(setCreatedProject(project));
  }, []);

  const onProjectSelect = useCallback((projectId: number) => {
    dispatch(setCurrentProjectId(projectId));
  }, []);

  const handleProjectClick = useCallback(
    (projectId: string, options?: { analyticsEventLocation?: string }) => {
      const { analyticsEventLocation } = options ?? {};

      const project = projects.find(
        (project: Project) => String(project.project_id) === String(projectId),
      );

      if (project) {
        const isOtherProjectType = isProjectTypeOther({
          projectTypes,
          projectType: project.project_type,
        });
        const isProjectLocationUnknown = !project.project_location;
        const isProjectPhaseUnknown = !project.phase_id;
        const isProjectDescriptionUnset = !project.project_details;

        if (
          isOtherProjectType ||
          isProjectLocationUnknown ||
          isProjectPhaseUnknown ||
          isProjectDescriptionUnset
        ) {
          window.dispatchEvent(
            new CustomEvent(CustomEventName.OPEN_PROJECT_SELECTION_DIALOG, {
              detail: {
                defaultView: 'UPDATE_PROJECT',
                currentProjectId: project.project_id,
                analyticsEventLocation,
                onCurrentProjectSave: (project: any) => {
                  onProjectSelect(project?.externalId || project?.project_id);
                  dispatch(getUserProjects());
                },
                onProjectCreate: (project: Project) => {
                  onProjectCreate(project);
                },
              },
            }),
          );
        } else {
          // @ts-expect-error // TODO: fix types
          setCurrentUserProject(project.project_id);

          mitGTM.sendEvent({
            event: AnalyticsEventName.SET_PROJECT,
            event_location: analyticsEventLocation,
            action: 'Select',
          });
        }
      }
    },
    [
      projects,
      projectTypes,
      onProjectSelect,
      onProjectCreate,
      setCurrentUserProject,
      dispatch,
    ],
  );

  const { id: itemListId, title: itemListName } = useMemo(() => {
    return getTaxonomyAnalyticsDataFromFilters(appFilters);
  }, [appFilters]);

  const handleViewDataLayer = (products: ProductSearchMaterial[]) => {
    const currentColorsList = currentSelectedColors?.join(', ');
    pushSearchAnalyticsDataLayer({
      payload: products,
      currentColor: currentColorsList,
      listId: itemListId,
      listTitle: itemListName,
      eventName: 'view_item_list',
      eventLocation: '',
      isFromCatalog: true,
      isSearch: isSearchRedirect,
    });
  };

  const { onProductInViewChange } = useViewScrollableProductsListEvent({
    sendAnalyticsEvent: handleViewDataLayer,
  });

  const handleSelectDataLayer = (product: ProductSearchMaterial) => {
    const currentColorsList = currentSelectedColors?.join(', ');
    pushSearchAnalyticsDataLayer({
      payload: [product],
      currentColor: currentColorsList,
      listId: product?.isDigital ? 'digital_sampling' : itemListId,
      listTitle: product?.isDigital ? 'Digital sampling' : itemListName,
      eventName: 'select_item',
      eventLocation: isBannersSearch ? 'Collection' : 'Catalog',
    });
  };

  const handleProductClick = async (
    product: ProductSearchMaterial,
    _index = 0,
    withAnalytics = true,
  ) => {
    const splitIoClient = window.splitFactory?.client();
    // Analytics
    handleSelectDataLayer(product);
    if (withAnalytics) {
      await sendAnalytics(
        { id: [String(product?.entityId)], type: 'view' },
        1200,
      );
    }
    // split
    splitIoClient?.track('user', 'mb-cb-srp-click', undefined, {
      type: 'pc',
      rowNumber: calculateProductRow(
        product.gridIndex || 0,
        contentBlocksMapping,
        isMobile,
      ),
    });

    if (!isMobile) {
      window.dispatchEvent(
        new CustomEvent('initMiniPDP', {
          detail: {
            id: product.entityId,
            product,
            isProduct: product.isProducts,
            isMaterial: !product.isProducts,
            clickedProductGridIndex: product.gridIndex,
            clickedProductRequestId: product.originRequestId || xRequestId,
            clickedProductAnalyticsClass: product.analyticsData?.analyticsClass,
            clickedProductAnalyticsImpressions:
              product.analyticsData?.impressions,
            dataLayerAnalytics: product.analyticsData?.dataLayerAnalytics,
          },
        }),
      );
    } else {
      window.location.href = product.url;
    }
  };

  const handleSuggestionClick = async (e: CustomEvent) => {
    const productId = e.detail?.variantId || e.detail?.id;
    const requestId = e.detail?.xRequestId;
    const material = e.detail?.product;
    const analyticsData = {
      analyticsClass: e.detail?.analyticsClass,
      impressions: e.detail?.impressions,
      dataLayerAnalytics: e.detail?.dataLayerAnalytics,
    };

    if (productId != null && material == null) {
      const { results } = await getMaterialsByKey([productId]);
      const formatedResult = results.map(
        transformSearchspringResponseToAppFormat,
      )?.[0];

      if (formatedResult != null) {
        handleProductClick(
          {
            ...formatedResult,
            originRequestId: requestId,
            analyticsData,
          },
          0,
          false,
        )
          .then(() => {})
          .catch((e) => console.log('Failed to handleProductClick', e));
      }
    }
    if (material != null) {
      handleProductClick(
        { ...material, originRequestId: requestId, analyticsData },
        0,
        false,
      )
        .then(() => {})
        .catch((e) => console.log('Failed to handleProductClick', e));
    }
  };

  useEffect(() => {
    window.addEventListener('handleSuggestionClick', handleSuggestionClick);
    return () =>
      window.removeEventListener(
        'handleSuggestionClick',
        handleSuggestionClick,
      );
  }, []);

  const handleContextMenuClick = useCallback(
    (product: ProductSearchMaterial) => {
      // Analytics
      window.splitFactory
        ?.client()
        ?.track(SplitTrafficType.USER, SPLIT_EVENTS.Open_PDP, undefined, {
          pdpOpenRef: 'pc right click',
        });
      handleSelectDataLayer(product);
    },
    [],
  );

  const handleContentBlockClick = async (data: OnClickCardInterface) => {
    // split tracking
    const splitIoClient = window.splitFactory?.client();
    splitIoClient?.track('user', 'mb-cb-srp-click', undefined, {
      type: `cb-${data.type}`,
      rowNumber: calculateCBRows(
        data.indexLocation,
        contentBlocksMapping,
        isMobile,
      ),
    });

    if (data) {
      const blocksIds = contentBlocks?.map((item: ContentBlockType) => item.id);
      await sendAnalytics(
        {
          id: [String(data?.id)],
          type: 'view',
          class: 'recommend',
          impressions: blocksIds,
        },
        1200,
      );
      if (data.type === 'info' && data.redirectUrl) {
        const newWindow = window.open(data.redirectUrl, '_blank');
        if (newWindow) {
          newWindow.opener = null;
        }
        dataLayer.push({
          event: 'view_item_list',
          list_id: `contentblocks_catalog_${data.id}_${data.indexLocation}`,
          list_name: `contentblocks catalog ${data.id} ${data.indexLocation}`,
          event_location: 'Catalog',
          item_list_id: `contentblocks_catalog_${data.id}_${data.indexLocation}`,
          item_list_name: `contentblocks catalog ${data.id} ${data.indexLocation}`,
          ecommerce: null,
        });
        return;
      }

      if (data.type === 'info' && data.cmsContentBlockId) {
        dispatch(
          setCMSModalOpen({
            staus: true,
            id: data.cmsContentBlockId,
            indexLocation: data.indexLocation,
          }),
        );

        dataLayer.push({
          event: 'view_item_list',
          list_id: `contentblocks_catalog_${data.id}_${data.indexLocation}`,
          list_name: `contentblocks catalog ${data.id} ${data.indexLocation}`,
          event_location: 'Catalog',
          item_list_id: `contentblocks_catalog_${data.id}_${data.indexLocation}`,
          item_list_name: `contentblocks catalog ${data.id} ${data.indexLocation}`,
          ecommerce: null,
        });
        return;
      }

      if (
        (data.type === 'dynamic' || data.type === 'curated') &&
        !data.redirectUrl
      ) {
        dispatch(setActiveContentBlock(data));
        dispatch(setContentBlocksModalOpen(true));
      }
    }
  };

  const handleAddToCart = ({
    product,
    productEventData,
  }: ProductCartClickProps) => {
    handleAddToBag({
      product,
      productEventData,
      isMobile,
      analyticsEventLocation: isBannersSearch
        ? AnalyticsEventLocation.COLLECTION
        : AnalyticsEventLocation.CATALOG,
      relatedProductData: undefined,
      shouldTriggerRecModal: undefined,
      additionalSplitProperties: {},
      listId: itemListId,
      listName: itemListName,
      customSplitEvents: [],
    });
  };

  const handleBoards = async (
    product: ProductSearchMaterial,
    index: number,
  ) => {
    await handleAddToBoards({
      product,
      index,
      itemListId,
      itemListName,
      analyticsEventLocation: isBannersSearch
        ? AnalyticsEventLocation.COLLECTION
        : AnalyticsEventLocation.CATALOG,
    });
  };

  const cartItemsIds = useMemo(() => {
    return cartItems?.map((item: ProductSearchMaterial) => item.entityId) || [];
  }, [cartItems]);

  const renderItem = (index: number) => {
    const rawMaterial = results[index];
    const indexByPage = (pagination?.currentPage - 1) * pagination?.perPage;
    const material = {
      ...rawMaterial,
      gridIndex: indexByPage + index + 1,
    };

    const isMultiSelected =
      (multiselectIds ?? []).find(
        (id: string) =>
          id === material?.entityId ||
          id === material?.selectedColorFilterEntityId,
      ) != null;

    const isCompareLimitReached =
      isComparisonActive && comparisonItems?.length >= 5;
    const isSelectDisabled = !isMultiSelected && isCompareLimitReached;

    return (
      <ResultCardWrapper
        shouldShowVariants
        isVirtualized={false}
        key={material?.sku}
        isSearchPage={isCuratedCollectionPage() || isSearch}
        material={material}
        searchOptions={searchOptions}
        searchToggle={searchToggle}
        isMultiselect={isMultiselect || isComparisonActive}
        isSelected={isMultiSelected}
        onMaterialSelect={handleMaterialSelect}
        multiselectIds={multiselectIds}
        imageSize={defaultImageSize}
        projects={structuredProjects}
        // @ts-expect-error // TODO: fix types
        selectedProject={currentProjectData}
        cartItemsIds={cartItemsIds}
        onProductClick={handleProductClick}
        onCreateProjectClick={handleProjectCreate}
        // @ts-expect-error // TODO: fix types
        onProjectClick={handleProjectClick}
        isMobile={isMobile}
        isSmallDesktop={isSmallDesktop}
        onContextMenuClick={handleContextMenuClick}
        itemListId={itemListId}
        itemListName={itemListName}
        analyticsEventLocation={
          isBannersSearch
            ? AnalyticsEventLocation.COLLECTION
            : AnalyticsEventLocation.CATALOG
        }
        onInViewChange={(itemInView) =>
          onProductInViewChange(material, itemInView)
        }
        onProjectCreate={onProjectCreate}
        onProjectSelect={onProjectSelect}
        intl={intl}
        userLang={userLang}
        // @ts-expect-error // TODO: fix types
        selectedVariants={selectedVariants}
        additionalSplitProperties={{}}
        customSplitEvents={[]}
        onColorVariantsClick={handleOnColorVariantsClick}
        isSelectboxDisabled={isSelectDisabled}
        isCompareLimitReached={isCompareLimitReached}
        cardStaticLabels={cardStaticLabels}
        onAddToCart={handleAddToCart}
        onAddToFavorites={handleBoards}
        loadingCartItemIds={loadingIds}
      />
    );
  };

  const noResultsFound = results.length === 0 && !isSearching;

  if (isBannersSearch && results.length === 0) {
    return null;
  }

  if (noResultsFound) {
    return <EmptyResult intl={intl} />;
  }

  const withMargin =
    campaignContentBanner != null && results?.length < 25 && !isPartnersSearch;

  return (
    <>
      <Styled.ResultsWrapper withMargin={withMargin}>
        <div
          ref={gridContainerRef}
          id="searchScrollContainer"
          data-viewmode={`show-${gridColumnNumber}`}
        >
          <Styled.SimpleResultsList ref={resultContainerRef}>
            {isSearching ? (
              <GridSkeleton itemsNumber={20} />
            ) : (
              <>
                {results?.map((item, index: number) => (
                  <Fragment key={item.entityId}>
                    <ItemContainer
                      dataIndex={index}
                      // @ts-expect-error // TODO: fix types
                      contentBanner={campaignContentBanner}
                      contentBlocksLength={contentBlocks.length}
                      currentPage={pagination.currentPage}
                      searchResultsLength={results.length}
                    >
                      {renderItem(index)}
                    </ItemContainer>
                    {contentBlocksMapping[index] >= 0 ? (
                      <ContentBlock
                        index={index}
                        key={`content-${
                          contentBlocks[contentBlocksMapping[index]].id
                        }`}
                        onCardClick={handleContentBlockClick}
                        blockData={contentBlocks[contentBlocksMapping[index]]}
                      />
                    ) : null}
                  </Fragment>
                ))}
              </>
            )}
          </Styled.SimpleResultsList>
        </div>
        <div>
          {campaignBottomBanner != null && (
            // @ts-expect-error // TODO: fix types
            <CampaignBanner {...campaignBottomBanner} />
          )}
        </div>
        {(!isSearchLite || isBannersSearch) && (
          <div id="paginationWrapper">
            {pagination.totalPages > 1 && (
              <Styled.PaginationWrapper>
                <Pagination
                  count={pagination.totalPages}
                  page={pagination.currentPage}
                  onChange={handleNextPage}
                />
              </Styled.PaginationWrapper>
            )}
          </div>
        )}
      </Styled.ResultsWrapper>
      {isContentBlockModalOpen && (
        <ContentBlocksModalContainer
          // @ts-expect-error // TODO: fix types
          handleProjectClick={handleProjectClick}
          onProjectSelect={onProjectSelect}
          selectedProject={currentProjectData}
          onCreateProjectClick={handleProjectCreate}
        />
      )}
    </>
  );
}, areComponentPropsEqual);
