// @ts-nocheck TODO: fix types for the entire file

import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { Button } from 'components/Buttons/Button';
import { theme } from 'theme';
import { TypographyComponent } from 'ui-widgets';

export const NoResults = styled(Typography)`
  font-size: 1em;
  margin-bottom: 1em;
`;

export const FilterGroupContainer = styled.div<{
  hideFilterGroupName: boolean;
}>`
  ${({ hideFilterGroupName }) =>
    hideFilterGroupName &&
    `.MuiPaper-root > .MuiBox-root {
      display: none;
    }`}
  ${theme.breakpoints.down('md')} {
    .MuiPaper-root {
      border: none;
    }

    .MuiAccordionDetails-root {
      padding-top: 0px;
    }

    height: 100%;
  }
`;
export const ParentButton = styled(Button)`
  margin-top: -1em;
  margin-bottom: 10px;
  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  & > span:first-of-type {
    margin-right: 2px !important;
  }
`;

export const TooltipTitle = styled(TypographyComponent)`
  line-height: 1.5;
  font-weight: 600;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 292px;
  padding: 16px;
`;
