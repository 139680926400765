// @ts-expect-error // TODO: fix types
export const parseSsFacetColorFilter = (ssFacetColorFilter) => {
  try {
    if (ssFacetColorFilter == null) {
      return [];
    }
    if (Array.isArray(ssFacetColorFilter)) {
      return ssFacetColorFilter;
    }
    const parsedFacets = JSON.parse(
      ssFacetColorFilter?.replace(/&quot;/g, '"'),
    );

    return parsedFacets != null ? Object.values(parsedFacets) : [];
  } catch (error) {
    return [];
  }
};
