import { ProductSearchMaterial } from '../../../types';

/**
 * Function to get L2 taxonomy out of a product
 * @param product type of ProductSearchMaterial
 * @returns string that corresponds to L2 taxonomy
 */
export const getL2FromTaxonomy = (product: ProductSearchMaterial): string => {
  if (!product.taxonomy || product.taxonomy.length === 0) {
    return product.categories[0] || '';
  }

  let findL2Taxonomy;
  for (const tax of product.taxonomy) {
    findL2Taxonomy = tax.split('>')[1];
    if (findL2Taxonomy) {
      break;
    }
  }

  return findL2Taxonomy || '';
};
