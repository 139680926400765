import styled from '@emotion/styled';

export const Root = styled.div<{ enableStickyMode: boolean }>`
  position: ${(props) => (props.enableStickyMode ? 'sticky' : 'relative')};
  z-index: 6;
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 0.75em 0;
  background: #fff;
  min-height: 60px;
`;
