import React, { useCallback } from 'react';
import { IntlShape } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import * as Styled from './MobileFilterContainer.styles';
import { definedTranslation } from '../../i18n';
import { mobileActiveFilterSelector } from '../../redux/selectors/filtersSelector';
import { searchPaginationSelector } from '../../redux/selectors/searchSelector';
import {
  clearFilters,
  popMobileActiveFilters,
} from '../../redux/sliceCreators/filtersSlice';
import { FiltersListContainer } from '../FiltersListContainer';

type MobileFilterContainerProps = {
  open: boolean;
  onClose: () => void;
  intl: IntlShape;
};

const MobileFilterContainer: React.FC<MobileFilterContainerProps> = (props) => {
  const dispatch = useDispatch();
  const mobileActiveFilter = useSelector(mobileActiveFilterSelector);
  const searchPagination = useSelector(searchPaginationSelector);

  const labels = {
    filters: props.intl?.formatMessage(
      definedTranslation.filters.filtersButton,
    ),
    clearAll: props.intl?.formatMessage(definedTranslation.filters.clearAll),
    show: props.intl?.formatMessage(definedTranslation.filters.showLabel),
    items: props.intl?.formatMessage(definedTranslation.filters.itemsLabel),
  };

  const handleGoBack = () => {
    dispatch(popMobileActiveFilters());
  };

  const handleClearFilters = useCallback(() => {
    dispatch(clearFilters(null));
  }, []);

  const groupTitle = mobileActiveFilter?.label?.split('_parent')?.[0];

  return (
    <Styled.FiltersDialog
      open={props.open}
      onClose={props.onClose}
      mobileFullscreen={true}
      showCloseIcon={true}
      onGoBack={mobileActiveFilter && handleGoBack}
      goBackLabel={groupTitle}
      title={mobileActiveFilter?.label ? undefined : labels.filters}
      onApplyButtonClick={props.onClose}
      applyButtonLabel={`${labels.show} ${
        // @ts-expect-error // TODO: fix types
        searchPagination?.totalResults || 0
      } ${labels.items}`}
      onCancelButtonClick={handleClearFilters}
      cancelButtonLabel={labels.clearAll}
    >
      <FiltersListContainer isMobile={true} />
    </Styled.FiltersDialog>
  );
};

export default MobileFilterContainer;
