import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import * as Styled from './AllItemsAddedToCartSnackbarExceptFinishes.styles';

import { setErrorMessage } from '../../../redux/sliceCreators/commonSlice';

type AllItemsAddedToCartSnackbarExceptFinishesProps = {
  totalAmountOfAddedItems: number;
};

const AllItemsAddedToCartSnackbarExceptFinishes: FC<
  AllItemsAddedToCartSnackbarExceptFinishesProps
> = ({ totalAmountOfAddedItems }) => {
  const dispatch = useDispatch();

  const handleShowMoreInfoRequiredModal = useCallback(() => {
    dispatch(
      setErrorMessage({
        body: `Some of the items you added require the selection of a finish. Please click the “See
				Finishes” button on the items you wish to add and select a desired finish.`,
        showCancelButton: false,
        shouldDisableBottomPadding: true,
        title: 'More information required.',
      }),
    );
  }, [dispatch]);

  return (
    <>
      {totalAmountOfAddedItems} items added to cart. Some items could not be
      added. Click{' '}
      <Styled.SnackbarInfoClickableText
        onClick={handleShowMoreInfoRequiredModal}
      >
        here
      </Styled.SnackbarInfoClickableText>{' '}
      for more info.
    </>
  );
};

export default AllItemsAddedToCartSnackbarExceptFinishes;
