import styled from '@emotion/styled';
import { Box } from '@mui/system';

export const Wrapper = styled(Box)`
  padding: 0.75em 0;
  .MuiButtonBase-root {
    margin-right: 0.75em;
  }
  .MuiChip-root {
    margin-bottom: 0.5em;
  }
  .shadesFilter {
    text-transform: capitalize;
  }
`;
