import React, { FC, useCallback, MouseEvent, useEffect } from 'react';
import { AnalyticsEventLocation, useIsVisible } from '@mb/lib';
import { SplitFeatureFlag, ContentBlocksTreatment } from '@mb/lib/splitio';

import useCurrentProject from '../../../hooks/useCurrentProject';
import { ButtonOrLinkComponent } from '../ButtonOrLinkComponent';
import styles from './ContentBlock.module.scss';
import { DynamicIcon } from '../icons/DynamicIcon';
import { ContentBlockType, OnClickCardInterface } from '../types/';

interface ContentBlockProps {
  index: number;
  blockData: ContentBlockType;
  onCardClick: (data: OnClickCardInterface) => void;
}

export const ContentBlock: FC<ContentBlockProps> = ({
  index,
  blockData,
  onCardClick,
}) => {
  const { id, isClickable, cardAttributes, infoAttributes, dynamicAttributes } =
    blockData;
  const {
    title,
    subtitle,
    iconType,
    template = 'cb-tmpl-12',
    type,
  } = cardAttributes;
  const { redirectUrl, cmsContentBlockId } = infoAttributes;
  const { useCase } = dynamicAttributes;

  const { currentProject } = useCurrentProject();

  const { ref, isVisible, observe } = useIsVisible<HTMLDivElement>({
    options: { threshold: 1 },
  });

  useEffect(() => {
    observe();
  }, [ref.current]);

  useEffect(() => {
    if (isVisible) {
      dataLayer.push({
        event: 'contentblock_impression',
        contentblock_title: title,
        contentblock_id: id,
        contentblock_type: type,
        contentblock_template: template,
        event_location: AnalyticsEventLocation.CATALOG,
        contentblock_location: index,
        event_project_id: currentProject?.project_id,
        experiment_name: SplitFeatureFlag.CONTENT_BLOCKS,
        experiment_version: ContentBlocksTreatment.ON,
      });
    }
  }, [isVisible]);

  const handleOnClick = useCallback(
    (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      if (!isClickable) {
        return;
      }
      if (redirectUrl) {
        e.preventDefault();
      }
      dataLayer.push({
        event: 'contentblock_click',
        contentblock_title: title,
        contentblock_id: id,
        contentblock_type: type,
        contentblock_template: template,
        event_location: AnalyticsEventLocation.CATALOG,
        contentblock_location: index,
        event_project_id: currentProject?.project_id,
        experiment_name: SplitFeatureFlag.CONTENT_BLOCKS,
        experiment_version: ContentBlocksTreatment.ON,
      });
      onCardClick({
        id,
        useCase,
        type,
        redirectUrl,
        cmsContentBlockId,
        indexLocation: index,
      });
    },
    [redirectUrl, useCase, id, type],
  );

  return (
    <div ref={ref} className={styles.contentBlockWrapper}>
      <ButtonOrLinkComponent
        linkValue={redirectUrl || null}
        className={`${styles.contentBlockMain} ${styles[template]}`}
        disabled={!isClickable}
        onClick={handleOnClick}
      >
        <div className={styles.contentBlockIcon}>
          <DynamicIcon iconUrl={iconType} />
        </div>
        <div className={styles.contentBlockTextWrapper}>
          <p className={styles.contentBlockEyebrow}>{title.trim()}</p>
          <h2 className={styles.contentBlockTitle}>{` ${subtitle.trim()}`}</h2>
        </div>
        {isClickable ? (
          <p className={styles.contentBlockAction}>Click to view</p>
        ) : null}
      </ButtonOrLinkComponent>
    </div>
  );
};
