import { loadUserData } from './loadUserData';
import { isFeatureOn } from './splitio/isFeatureOn';

export async function checkFlag(
  flagName: string,
  flagValue: string,
): Promise<boolean> {
  try {
    const userData = await loadUserData();
    return isFeatureOn(flagName, userData, flagValue);
  } catch (error) {
    console.error('Error checking flag:', error);
    return false;
  }
}
