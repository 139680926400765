class StatusError extends Error {
  status: number | undefined;
  info: string | undefined;
}

export const postFetcher = async (url: string, payload: unknown) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    const error = new StatusError('An error occurred while fetching the data.');

    error.info = await response.json();
    error.status = response.status;
    throw error;
  }

  const data = await response.json();

  if (data) {
    data.headers = response.headers.get('X-Request-Id');
  }

  return data;
};
