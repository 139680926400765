import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { theme } from 'theme';

export const AppWrapper = styled.div`
  display: block;
  min-height: 18.75em;
  height: auto;
  position: relative;
`;

export const ResultsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const ResultsWrapper = styled(Box)`
  width: 100%;
`;

export const ResultsHeaderWrapper = styled(Box)`
  background: ${theme.palette.common.white};
  position: relative;
`;

export const FilterWrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    !['isFilterShown', 'isFilterRemoved'].includes(prop),
})`
  width: ${(props) => (props.isFilterShown ? '210px' : '0')};
  margin-right: ${(props) => (props.isFilterShown ? '24px' : '0')};
  visibility: ${(props) => (props.isFilterShown ? 'visible' : 'hidden')};
  position: relative;
  left: ${(props) => (props.isFilterShown ? '0' : '-40em')};
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  background: ${theme.palette.common.white};
  display: ${({ isFilterRemoved }) => (isFilterRemoved ? 'none' : 'flex')};
  .sticky-inner-wrapper {
    width: 100% !important;
    background: ${theme.palette.common.white};
    max-width: ${(props) => (props.isFilterShown ? '210px' : '0')};
  }
  ${theme.breakpoints.up('lg')} {
    padding: 0;
  }
  ${theme.breakpoints.down('lg')} {
    position: absolute;
    top: 3em;
    z-index: 100;
    width: ${(props) => (props.isFilterShown ? '100%' : '0')};
    margin-right: 0;
    .sticky-inner-wrapper {
      max-width: none;
    }
  }
`;
