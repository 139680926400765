/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix types

import { getUrlParameter } from '../searchSpring.utils';
import { getAnalyticsDataIfCollection } from './getAnalyticsDataIfCollection';
import { getCharsNumber } from './getCharsNumber';
import { getPartnersPageItemListData } from './getPartnersPageItemListData';
import {
  isHomePage,
  isPartnersPage,
  isSearchPage,
  isCompareGridShown,
} from './isPage';

export const getTaxonomyAnalyticsDataFromFilters = (
  appFilters: any[],
  defaultId = 'catalog_all_products',
  defaultTitle = 'All products',
) => {
  let analyticsId = defaultId;
  let analyticsTitle = defaultTitle;
  const manufacturerKey: any = Object.keys(appFilters).find((key) =>
    key.includes('filter.manufacturer'),
  );
  const manufacturer = manufacturerKey
    ? appFilters[manufacturerKey].value
    : null;

  if (isCompareGridShown()) {
    return {
      id: 'comparison_tool_comparison_table',
      title: 'Comparison tool comparison table',
    };
  }
  if (window.location.pathname?.includes('/curatedcollections/')) {
    return getAnalyticsDataIfCollection();
  }

  try {
    const taxonomyFilters = Object.values(appFilters)?.filter(
      (item) => item.field === 'taxonomy',
    );
    const currentTaxonomyParent = taxonomyFilters?.find(
      (item) => getCharsNumber(item.label, '>') === 1,
    );
    const currentTaxonomyChild = taxonomyFilters?.find(
      (item) => getCharsNumber(item.label, '>') === 2,
    );
    const currentTaxonomy = currentTaxonomyChild || currentTaxonomyParent;
    if (currentTaxonomy != null) {
      const taxonomyLabelArr = currentTaxonomy?.label?.split('>');
      taxonomyLabelArr.shift();
      const newLavel = taxonomyLabelArr.join('-');
      if (newLavel != null) {
        analyticsId = `catalog_${newLavel
          .toLowerCase()
          ?.replaceAll(/-|\s/g, '_')}`;
        analyticsTitle = newLavel;
      }
    }
    if (isHomePage()) {
      analyticsId = 'homepage_recommendations';
      analyticsTitle = 'Homepage recommendations';
    }
    if (isPartnersPage()) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const { itemListId, itemListName } = getPartnersPageItemListData();
      analyticsId = itemListId;
      analyticsTitle = itemListName;
    }
    if (manufacturer != null && !isPartnersPage()) {
      analyticsId = `catalog_brand_${manufacturer
        .toLowerCase()
        ?.replaceAll(/-|\s/g, '_')}`;
      analyticsTitle = manufacturer;
    }
    if (isSearchPage() && getUrlParameter('q')?.length !== 0) {
      analyticsId = 'search_results_sampling';
      analyticsTitle = 'Sampling search results';
    }

    const colorFilters = Object.values(appFilters)?.filter(
      (item) => item.field === 'virtual_color_group',
    );
    if (colorFilters?.length) {
      analyticsId =
        analyticsId +
        '_color_filters_' +
        colorFilters.map((filter) => filter.label || filter.hexcode).join('_');
      analyticsTitle = analyticsTitle + ' with color filters';
    }

    return { id: analyticsId, title: analyticsTitle };
  } catch (e) {
    return { id: analyticsId, title: analyticsTitle };
  }
};
