import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { ButtonWrapper, ToogleButton } from './CompareButton.styles';
import { pushCompareAnalyticsEvent } from '../../helpers';
import useCurrentProject from '../../hooks/useCurrentProject';
import { definedTranslation } from '../../i18n';
import { isComparisonActiveSelector } from '../../redux/selectors/commonSelector';
import {
  resetComparisonFeature,
  setIsComparisonActive,
} from '../../redux/sliceCreators/commonSlice';

const CompareButton = () => {
  const intl = useIntl();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const { currentProject } = useCurrentProject();
  const isComparisonActive = useSelector(isComparisonActiveSelector);
  const dispatch = useDispatch();

  const label = intl?.formatMessage(definedTranslation.compare.toggleLabel);
  const handleChange = () => {
    if (isComparisonActive) {
      dispatch(resetComparisonFeature());
    } else {
      pushCompareAnalyticsEvent({
        eventName: 'comparison_tool_compare_cta_clicks',
        projectData: {
          name: String(currentProject?.project_title),
          id: String(currentProject?.project_id),
        },
        additionalProps: {
          event_items_selected: 0,
        },
      });
      dispatch(setIsComparisonActive(!isComparisonActive));
    }
  };

  if (isMobile) return <Fragment />;

  return (
    <ButtonWrapper>
      <ToogleButton
        variant="outlined"
        size="medium"
        color="inherit"
        onClick={handleChange}
      >
        {label}
      </ToogleButton>
    </ButtonWrapper>
  );
};

export { CompareButton };
