import { AnalyticsEventName, AddToCartMethod } from '@mb/lib';
import { ProductSearchMaterial } from '../../types';
import { getUrlParameter } from '../searchSpring.utils';
import { isCompareGridShown } from './isPage';
import { mapMaterialsForAnalytics } from './mapMaterialsForAnalytics';

interface PushSearchAnalyticsDataLayerProps {
  payload: ProductSearchMaterial[];
  currentColor?: string;
  listId: string;
  listTitle: string;
  eventName: string;
  eventLocation: string;
  isFromCatalog?: boolean;
  isSearch?: boolean;
}

export const pushSearchAnalyticsDataLayer = ({
  payload = [],
  currentColor = '',
  listId,
  listTitle,
  eventName = 'view_item_list',
  eventLocation,
  isFromCatalog = false,
  isSearch = false,
}: PushSearchAnalyticsDataLayerProps) => {
  try {
    const currentQuery = getUrlParameter('q');
    let finalListId = listId;
    let finalListTitle = listTitle;

    if (isSearch || currentQuery?.length > 0) {
      finalListId = 'search_results_sampling';
      finalListTitle = 'Sampling search results';
    }

    if (isCompareGridShown()) {
      finalListId = 'comparison_tool_comparison_table';
      finalListTitle = 'Comparison tool comparison table';
    }

    const { gaType, currency } = mapMaterialsForAnalytics(
      payload,
      currentColor,
      finalListId,
      finalListTitle,
    );

    const gaEvent: any = {
      event: eventName,
      list_id: finalListId,
      list_name: finalListTitle,
      ecommerce: {
        currency,
        value: 0,
        items: gaType,
      },
    };

    if (eventName === AnalyticsEventName.ADD_TO_CART) {
      gaEvent['method'] =
        gaType.length > 1
          ? AddToCartMethod.MULTI_SELECT
          : AddToCartMethod.SINGLE;
    }

    if (eventLocation != null) {
      gaEvent['event_location'] = eventLocation;
    }
    if (listId != null && isFromCatalog) {
      gaEvent.ecommerce['item_list_id'] = finalListId;
    }
    if (listTitle != null && isFromCatalog) {
      gaEvent.ecommerce['item_list_name'] = finalListTitle;
    }

    mitGTM.sendEvent({ ecommerce: null });
    mitGTM.sendEvent(gaEvent);
  } catch (e) {
    console.log(e);
  }
};
