import { useCallback, useMemo } from 'react';

import {
  shouldTriggerProjectSelectionDialog,
  AnalyticsEventLocation,
  CustomEventName,
  getUserAttributes,
} from '@mb/lib';

import {
  SPLIT_EVENTS,
  SplitEventData,
  SplitTrafficType,
} from '@mb/lib/splitio';
import { useDispatch, useSelector } from 'react-redux';
import {
  goToLogin,
  triggerAssociatedProductsModal,
} from '../../../pages/ProductSearch/utils/helpers';
import { getEventsMaterialProps } from '../helpers';
import useCurrentProject from './useCurrentProject';
import { sendAnalytics } from '../api/events';
import { EngagementTargets } from '../config/constants';
import useAddToBag from '../hooks/useAddToBag';
import { projectsListSelector } from '../redux/selectors/projectsSelector';
import {
  setCreatedProject,
  setCurrentProjectId,
  setIsProjectModalOpen,
  setProjectModalEventLocation,
} from '../redux/sliceCreators/projectsSlice';
import { setMaterialsInQueue } from '../redux/sliceCreators/searchSlice';
import { StructuredProject, IProject } from '../types';
import { ProductSearchMaterial, RelatedProduct } from '../types';

export interface AddToCartProps {
  product: ProductSearchMaterial;
  isMobile: boolean;
  productEventData: {
    subtitle1: string;
    subtitle2: string;
  };
  analyticsEventLocation: AnalyticsEventLocation;
  relatedProductData?: RelatedProduct;
  shouldTriggerRecModal?: boolean;
  additionalSplitProperties?: Record<string, string>;
  customSplitEvents?: SplitEventData[];
  listId?: string;
  listName?: string;
}

const useAddToBagWithValidations = () => {
  const { isLoading, loadingIds, addToBag } = useAddToBag();
  const { currentProject } = useCurrentProject();
  const projects = useSelector(projectsListSelector);
  const dispatch = useDispatch();

  const selectedProject = useMemo(
    () => ({
      id: currentProject?.project_id || 0,
      label: currentProject?.project_title ?? 'Select project..',
    }),
    [currentProject],
  ) as StructuredProject;

  const onProjectSelect = useCallback((projectId: number | string) => {
    dispatch(setCurrentProjectId(projectId));
  }, []);

  const onProjectCreate = useCallback((project: IProject) => {
    dispatch(setCreatedProject(project));
  }, []);

  const onCreateProjectClick = useCallback(
    (params?: { analyticsEventLocation?: string }) => {
      const { analyticsEventLocation } = params ?? {};

      dispatch(setIsProjectModalOpen(true));

      if (analyticsEventLocation) {
        dispatch(setProjectModalEventLocation(analyticsEventLocation));
      }
    },
    [],
  );

  const handleAddToBag = (props: AddToCartProps) => {
    const {
      product,
      isMobile = false,
      productEventData,
      analyticsEventLocation,
      relatedProductData,
      shouldTriggerRecModal,
      additionalSplitProperties,
      customSplitEvents,
      listId,
      listName,
    } = props;
    const userData = getUserAttributes();
    if (userData.signin_token == null) {
      goToLogin();
      return;
    }

    const analyticsProps = getEventsMaterialProps(product);
    const isFinishMaterial = product?.isFinish;
    const originRequestId = product?.originRequestId;

    // GEO SIM validations
    if (product?.isGeoRestricted && !isMobile) {
      sendAnalytics(
        {
          id: [String(product.entityId)],
          type: 'view',
          ...analyticsProps,
        },
        1200,
      );
      window.dispatchEvent(
        new CustomEvent('open-restricted-item-modal', {
          detail: {
            title: product?.manufacturer,
            subtitle1: productEventData?.subtitle1,
            subtitle2: productEventData?.subtitle2,
            entityId: product?.entityId,
            material: product,
            imgUrl: product?.imageUrl || product.thumbnailImageUrl,
            projectId: selectedProject?.id,
          },
        }),
      );
      return;
    }

    // Validate if project selection dialog should be triggered
    if (
      (product?.associatedFinishProducts?.length ?? 0) > 0 &&
      isFinishMaterial
    ) {
      // If Finishes - trigger modal to select associated Products
      const productsIds = product?.associatedFinishProducts?.join('|');
      const productsModalData = {
        id: product?.entityId,
        name: product?.name || product.title,
        manufacturerName: product?.manufacturer,
        productImageUrl: product?.imageUrl || product.thumbnailImageUrl,
      };

      if (shouldTriggerProjectSelectionDialog()) {
        window.dispatchEvent(
          new CustomEvent(CustomEventName.OPEN_PROJECT_SELECTION_DIALOG, {
            detail: {
              currentProjectId: selectedProject?.id,
              analyticsEventLocation,
              onCurrentProjectSave: (project: any) => {
                onProjectSelect?.(project?.externalId || project?.project_id);
                triggerAssociatedProductsModal(productsIds, productsModalData);
              },
              onProjectCreate: (project: IProject) => {
                onProjectCreate?.(project);
              },
            },
          }),
        );
        return;
      }

      triggerAssociatedProductsModal(productsIds, productsModalData);
      // track
      window.splitFactory
        ?.client()
        .track(
          SplitTrafficType.USER,
          SPLIT_EVENTS.SRP_engagement_click,
          undefined,
          {
            srpEngagementTarget: EngagementTargets.QV_OPEN,
          },
        );
      return;
    }

    const currentProduct = product;
    if (originRequestId != null) {
      currentProduct['originRequestId'] = originRequestId;
    }

    if (projects?.length > 0 && Boolean(selectedProject.id)) {
      const gaEventLocation = 'Catalog';

      if (shouldTriggerProjectSelectionDialog()) {
        window.dispatchEvent(
          new CustomEvent(CustomEventName.OPEN_PROJECT_SELECTION_DIALOG, {
            detail: {
              currentProjectId: selectedProject?.id,
              analyticsEventLocation,
              onCurrentProjectSave: (project: any) => {
                onProjectSelect?.(
                  project?.externalId || Number(project?.project_id),
                );
                addToBag({
                  materials: [currentProduct],
                  projectId: project?.externalId || project?.project_id,
                  project: project,
                  relatedProductData,
                  triggerLocation: gaEventLocation,
                  isFinishMaterial: false,
                  shouldTriggerModal: shouldTriggerRecModal,
                  additionalSplitProperties,
                  customSplitEvents,
                  additionalAnalyticsProperties: {
                    listId,
                    listName,
                  },
                });
              },
              onProjectCreate: (project: IProject) => {
                onProjectCreate?.(project);
              },
            },
          }),
        );
        return;
      }
      addToBag({
        materials: [currentProduct],
        projectId: selectedProject.id,
        relatedProductData,
        triggerLocation: gaEventLocation,
        isFinishMaterial: false,
        shouldTriggerModal: shouldTriggerRecModal,
        additionalSplitProperties,
        customSplitEvents,
        additionalAnalyticsProperties: {
          listId,
          listName,
        },
      });
    } else {
      onCreateProjectClick?.();
      dispatch(setMaterialsInQueue([currentProduct]));
    }
  };

  return { isLoading, loadingIds, handleAddToBag };
};

export default useAddToBagWithValidations;
