import React, { FC } from 'react';
import { Category, ProductSearchMaterial } from '../../../types';
import { ModalSidebar } from '../ModalSidebar';
import { ProductGrid } from '../ProductGrid';
import { ProductGridControls } from '../ProductGridControls';
import { CardAttributesType } from '../types';

import styles from './contentBlockDisplay.module.scss';

interface ContentBlockModalDisplayProps {
  id: string;
  indexLocation: number;
  isLoading: boolean;
  cardAttributes: CardAttributesType;
  results: ProductSearchMaterial[];
  categories: Category[];
  activeFilters: string[];
  isMobile?: boolean;
  filterResults: () => void;
  handleAddToBag: () => void;
  onSelectAll: () => void;
  handleAddToBoard: () => void;
  onHandleCancel: () => void;
  handleProductSelect: () => void;
  selectedProducts?: [];
  isBagLoading: boolean;
  handleProjectClick: () => void;
  onProjectSelect: () => void;
  selectedProject?: string;
  onCreateProjectClick: () => void;
}

export const ContentBlockModalDisplay: FC<ContentBlockModalDisplayProps> = ({
  id,
  indexLocation,
  isLoading,
  cardAttributes,
  results,
  categories,
  activeFilters,
  isMobile = false,
  filterResults,
  handleAddToBag,
  onSelectAll,
  handleAddToBoard,
  onHandleCancel,
  handleProductSelect,
  selectedProducts = [],
  isBagLoading = false,
  handleProjectClick,
  onProjectSelect,
  selectedProject,
  onCreateProjectClick,
}) => {
  const isSelectAllDisabled = selectedProducts.length > 75;
  const isProductInSelected = selectedProducts.some(
    (item: ProductSearchMaterial) => item?.isProduct,
  );
  const isDigitalInSelected = selectedProducts.find(
    (item: ProductSearchMaterial) => item?.isDigital,
  );
  const isAddToBagDisabled =
    isBagLoading ||
    isProductInSelected ||
    isDigitalInSelected ||
    selectedProducts?.length === 0;

  return (
    <>
      {cardAttributes && !isLoading ? (
        <div className={styles.modalWrapper}>
          <div className={styles.modalSidebarWrapper}>
            <ModalSidebar
              id={id}
              index={indexLocation}
              cardAttributes={cardAttributes}
              // @ts-expect-error // TODO: fix types
              categories={categories}
              activeFilters={activeFilters}
              onCategoryClick={filterResults}
            />
          </div>
          <div className={styles.contentBlockResults}>
            <div className={styles.contentBlockControlsWrapper}>
              {selectedProducts.length > 0 ? (
                <ProductGridControls
                  currentView="grid"
                  handleAddToBag={handleAddToBag}
                  handleAddToBoard={handleAddToBoard}
                  handleCancel={onHandleCancel}
                  handleOnDeskClick={() => {}}
                  handleOnlySamplesSwitch={() => {}}
                  handleOpenDeleteConfirmationModal={() => {}}
                  handleSelectAll={onSelectAll}
                  handleShareExportTo={() => {}}
                  isAddToBagDisabled={isAddToBagDisabled}
                  isSelectAllDisabled={isSelectAllDisabled}
                  isMobileDevice={isMobile}
                  shouldShowDeleteButton={false}
                  numberOfSelectedItems={selectedProducts.length}
                  addToBagTooltip={
                    isAddToBagDisabled
                      ? 'Not available for products'
                      : undefined
                  }
                />
              ) : null}
            </div>

            <ProductGrid
              products={results}
              blockId={id}
              indexLocation={indexLocation}
              handleProductSelect={handleProductSelect}
              handleProjectClick={handleProjectClick}
              selectedProducts={selectedProducts}
              onProjectSelect={onProjectSelect}
              selectedProject={selectedProject}
              onCreateProjectClick={onCreateProjectClick}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};
