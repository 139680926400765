import React, { FC, ReactNode } from 'react';
import MBDialog from 'components/Elements/MBDialog';
import styles from './contentBlockModal.module.scss';

interface ContentBlockModalProps {
  open: boolean;
  visible: boolean;
  onClose: () => void;
  isMobile?: boolean;
  children: ReactNode;
}

export const ContentBlockModal: FC<ContentBlockModalProps> = ({
  open,
  visible,
  onClose,
  children,
  ...props
}) => {
  return (
    <MBDialog
      disableEnforceFocus
      disableAutoFocus
      disableRestoreFocus
      container={document.getElementById('contentBlocksModalWrapper')}
      open={open}
      id="content_blocks_modal"
      title=""
      onClose={onClose}
      showCloseIcon
      className={styles.dialog}
      titleBottomPadding={16}
      fullScreenModal
      doNotScrollContent
      shouldDisableBottomPadding
      sx={{ zIndex: 10 }}
      {...props}
    >
      {children}
    </MBDialog>
  );
};
