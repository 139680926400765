import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { FiltersSkeleton } from '../FiltersSkeleton';
import { GridSkeleton } from '../GridSkeleton';

const PageSkeleton = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  return (
    // Add margin/padding for potential A1 or Collections block to avoid CLS jump
    <div style={{ display: 'flex', marginTop: '60px', width: '100%' }}>
      {!isMobile && (
        <div style={{ width: '210px', marginRight: '24px' }}>
          <FiltersSkeleton />
        </div>
      )}
      <GridSkeleton itemsNumber={20} />
    </div>
  );
};

export default PageSkeleton;
