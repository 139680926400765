import React, { useCallback, useState } from 'react';
import { sendChangeViewAnalyticsEvent } from '@mb/lib/analytics/utils';
import { InputAdornment } from '@mui/material';
import ColorWheelIcon from 'components/Elements/ColorWheelIcon/ColorWheelIcon';
import { IntlShape } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { MobileResultsHeader } from './components/MobileResultsHeader';
import * as Styled from './ResultsHeader.styles';
import { Sorting, SortItem } from '../../components/Filter/Sorting/Sorting';
import { definedTranslation } from '../../i18n';
import {
  appFiltersSelector,
  isColorCircleTopbarShownSelector,
  taxonomyFilterValueSelector,
} from '../../redux/selectors/filtersSelector';
import { searchResultsSelector } from '../../redux/selectors/searchSelector';
import {
  setIsColorCircleTopbarShown,
  setTaxonomyFilterValue,
} from '../../redux/sliceCreators/filtersSlice';

interface ResultsHeaderProps {
  intl: IntlShape;
}

const ResultsHeader: React.FC<ResultsHeaderProps> = ({ intl }) => {
  const dispatch = useDispatch();
  const searchResults = useSelector(searchResultsSelector);
  const appFilters = useSelector(appFiltersSelector);
  const taxonomySearchValue = useSelector(taxonomyFilterValueSelector);
  const isColorCircleTopbarShown = useSelector(
    isColorCircleTopbarShownSelector,
  );
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const labels = {
    categories: intl.formatMessage(
      definedTranslation.filters.searchCategoriesLabel,
    ),
  };

  const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const isSortsMenuOpen = Boolean(sortAnchorEl);

  const handleSortClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSortAnchorEl(sortAnchorEl != null ? null : event.currentTarget);
  };
  const handleSortClose = () => {
    setSortAnchorEl(null);
  };
  const handleToggleColors = () => {
    dispatch(setIsColorCircleTopbarShown(null));
  };

  const onSort = useCallback((sortItem: SortItem) => {
    sendChangeViewAnalyticsEvent({
      eventLocation: 'Catalog',
      type: 'Sorting',
      action: sortItem.label,
    });
  }, []);
  const handleInitSearch = () => {
    setIsSearchActive(true);
  };

  const handleSearchBlur = () => {
    setIsSearchActive(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTaxonomyFilterValue(event.target.value));
  };

  const startAdornment = (
    <InputAdornment position="end">
      {/* @ts-expect-error // TODO: fix types */}
      <Styled.SearchIcon name="search" dense />
    </InputAdornment>
  );

  if (isMobile) {
    return (
      <MobileResultsHeader
        isSortingDisabled={!searchResults?.length}
        appFilters={appFilters}
        isColorCircleTopbarShown={isColorCircleTopbarShown}
        onColorsToggle={handleToggleColors}
      />
    );
  }

  return (
    <Styled.ResultsOuterWrapper>
      <div>
        {!isSearchActive && !taxonomySearchValue ? (
          <Styled.SearchButton
            onClick={handleInitSearch}
            dense
            color="inherit"
            iconName="search"
          />
        ) : (
          <Styled.SearchField
            autoFocus
            autoComplete="off"
            // @ts-expect-error // TODO: fix types
            variant="outlined"
            placeholder={labels.categories}
            value={taxonomySearchValue}
            onBlur={handleSearchBlur}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment,
            }}
          />
        )}
      </div>
      <div>
        <Styled.IconButton
          onClick={handleSortClick}
          dense
          color="inherit"
          iconName="sorting-thin"
        />
        {isSortsMenuOpen && (
          <Sorting
            onSort={onSort}
            menuProps={{
              open: true,
              anchorEl: sortAnchorEl,
              onClose: handleSortClose,
              // @ts-expect-error // TODO: fix types
              minWidth: 200,
              placement: 'bottom-end',
            }}
          />
        )}
      </div>
      <Styled.ColorWheelWrapper>
        {!isColorCircleTopbarShown ? (
          <ColorWheelIcon
            wrapperClassName="colorwheelIcon"
            onClick={() => handleToggleColors()}
          />
        ) : (
          <Styled.CloseRainbowButton
            dense
            size="small"
            color="secondary"
            iconName="close"
            onClick={() => handleToggleColors()}
          />
        )}
      </Styled.ColorWheelWrapper>
    </Styled.ResultsOuterWrapper>
  );
};

ResultsHeader.displayName = 'ResultsHeader';

export { ResultsHeader };
