import React from 'react';

import * as Styled from './FilterContainer.styles';
import { SearchForFilters } from '../../components/Filter/SearchForFilters';
import { SelectedFilters } from '../../components/Filter/SelectedFilters';
import { FiltersListContainer } from '../FiltersListContainer';

type FilterContainerProps = {
  onCollapseChange: () => void;
};

const FilterContainer = ({ onCollapseChange }: FilterContainerProps) => {
  return (
    <Styled.FiltersOuterWrapper>
      <SearchForFilters />
      <SelectedFilters />
      <FiltersListContainer onCollapseChange={onCollapseChange} />
    </Styled.FiltersOuterWrapper>
  );
};

FilterContainer.displayName = 'FilterContainer';

export { FilterContainer };
