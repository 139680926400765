// @ts-nocheck TODO: fix types for the entire file

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import FilterGroup from 'components/Filters/FilterGroup';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

import * as Styled from './FilterItem.styles';
import { filterParentPostfix, useFiltersContext } from '../../../contexts';
import { FilterData } from '../../../types';
import {
  createMultiLevelFilters,
  splittedCategoriesFields,
} from '../../../utils';
import { ColorCircles } from '../ColorCircles';

import { FilterSubItems } from '../FilterSubItems';
import { TooltipContent } from './TooltipContent';
import { definedTranslation } from '../../../i18n';

const colorGroupLabel = 'virtual_color_group';
const colorsLabel = 'virtual_color';

const FilterItem = React.memo((props) => {
  const {
    appFilters,
    data,
    onCollapseChange,
    isMobile,
    mobileActiveFilter,
    onMobileOpenFilter,
    onFilterClicked,
    getFiltersByField,
    getFilterById,
    getFiltersByPropertyValue,
    removeFilter,
    addFilter,
    searchPlaceholderText,
    intl,
  } = props;
  const { label, field, values, multiple, collapse, active } = data;
  const isItemCollapsed = isMobile
    ? mobileActiveFilter?.field === field
    : !collapse;
  const [isAccordionExpanded, setIsAccordionExpanded] =
    useState(isItemCollapsed);
  const [filterInputValue, setFilterInputValue] = useState<null | string>(null);
  const { createFilter } = useFiltersContext();

  useEffect(() => {
    if (active && !isMobile) {
      setIsAccordionExpanded(true);
    }
  }, [active]);

  useEffect(() => {
    if (isMobile) {
      setIsAccordionExpanded(mobileActiveFilter?.field === field);
    }
  }, [mobileActiveFilter?.field]);

  const handleAccordionChange = useCallback(
    (event, expanded) => {
      if (isMobile) {
        onMobileOpenFilter(data);
        setIsAccordionExpanded(false);
        return;
      }
      setIsAccordionExpanded(expanded);
      setFilterInputValue(null);
      onCollapseChange?.();
    },
    [data, isMobile],
  );

  const isParentWithSiblings = useCallback((filter) => {
    return (
      splittedCategoriesFields.includes(filter.field) && filter.parent == null
    );
  }, []);

  const handleFilterClicked = useCallback(
    (filter, event) => {
      sessionStorage.setItem('ssScroll', '0');
      setFilterInputValue(null);
      const activeFilters = getFiltersByField(field);
      const isActive = Boolean(getFilterById(filter.id));
      const isParent = event === true;

      if (!isActive) {
        onFilterClicked?.(filter);
      }

      const parentFilter = isParent
        ? filter
        : getFiltersByPropertyValue('value', filter.parent)?.[0];

      const hasActiveChildren = Boolean(
        activeFilters?.some((child) => child.parent === parentFilter?.label),
      );

      const activeParentWithChildren =
        isActive && Boolean(isParent) && hasActiveChildren;

      const hasParentSiblings = isParentWithSiblings(filter);

      if (multiple === 'single' || hasParentSiblings) {
        removeFilter(activeFilters);

        if (
          !activeFilters.find((activeFilter) => activeFilter.id === filter.id)
        ) {
          addFilter(filter);
        }
      } else {
        if (isActive) {
          if (isParent) {
            const activeSubFilters = getFiltersByField(
              field,
              'filter',
              `=${filter.label}`,
            );

            removeFilter(activeSubFilters);
          }

          if (!isParent) {
            removeFilter(filter);
          }
        } else {
          if (parentFilter) {
            addFilter(parentFilter);
          }

          removeFilter(filter);

          if (!activeParentWithChildren) {
            addFilter(filter);
          }
        }
      }
    },
    [field, multiple, appFilters],
  );

  const TooltipData = useMemo(() => {
    if (data?.field === 'sample_type_filter') {
      const title = data?.label?.split('_parent')?.[0];
      const subtitle = intl.formatMessage(
        definedTranslation.filters.digitalFilterTooltipSubtitle,
      );
      return <TooltipContent title={title} subtitle={subtitle} />;
    } else {
      return undefined;
    }
  }, [intl, data]);

  const renderedFilters = useMemo(
    () => createMultiLevelFilters(values, field, multiple),
    [values, field, multiple],
  );
  const filterValues = Object.values(appFilters) as FilterData[];
  const parentLabel =
    filterValues?.find(
      (item) => isParentWithSiblings(item) && item.field === field,
    )?.label ?? label;
  const renderedParent = renderedFilters.find(
    (item) => item?.[0] === parentLabel,
  );
  const isParent = Array.isArray(renderedParent?.[1]?.items);

  const groupFiltersNumber = filterValues.filter((item) => {
    return item.field === field && !isParentWithSiblings(item);
  }).length;

  const mobileGroupFiltersNumber =
    groupFiltersNumber === 0
      ? filterValues.filter(
          (item) => item.field === field && isParentWithSiblings(item),
        ).length
      : groupFiltersNumber;

  const handleFilterInputValueChange = (event) => {
    if (event.target.value?.length > 0) {
      setFilterInputValue(event.target.value);
    } else {
      setFilterInputValue(null);
    }
  };

  const filteredFilters = useMemo(() => {
    const newArray = renderedFilters.filter((item) => {
      if (filterInputValue != null) {
        return item[0]?.toLowerCase().includes(filterInputValue?.toLowerCase());
      }
      const data = item?.[1];
      if (data?.field === 'manufacturer' && data?.count === 0) {
        return false;
      }
      return true;
    });
    if (field.includes('price_sign_filter')) {
      return sortBy(newArray, (item) => item?.[1]?.high);
    } else if (field.includes('abrasion_result')) {
      return sortBy(newArray, (item) => item?.[1]?.low);
    } else {
      return newArray;
    }
  }, [renderedFilters, filterInputValue, appFilters, field]);

  const handleBackButtonClick = () => {
    const parentFilter = createFilter(renderedParent?.[1]?.field, parentLabel);
    handleFilterClicked(parentFilter, true);
  };

  const isColorFacet = field?.includes(colorsLabel);

  return (
    <Styled.FilterGroupContainer
      hideFilterGroupName={isMobile && isAccordionExpanded}
    >
      <FilterGroup
        shouldShowFilter={
          !isMobile &&
          renderedFilters?.length > 10 &&
          !isParent &&
          !isColorFacet
        }
        isExpanded={isAccordionExpanded}
        onCollapseChange={handleAccordionChange}
        groupTitle={label?.replace(filterParentPostfix, '')}
        expandIcon={isMobile ? 'arrow-right' : 'down'}
        selectedInGroupNumber={
          isMobile ? mobileGroupFiltersNumber : groupFiltersNumber
        }
        onFilterInputValueChange={handleFilterInputValueChange}
        maxHeight={isMobile ? 600 : 420}
        showScrollbar={false}
        shouldBackArrow={false}
        filterInputId={`${field}_filter_search_box`}
        searchPlaceholder={searchPlaceholderText}
        titleTooltipContent={TooltipData}
      >
        {isParent && !isMobile && (
          <Styled.ParentButton
            dense
            label={parentLabel}
            variant="text"
            color="primary"
            startIcon="arrow-left"
            onClick={handleBackButtonClick}
          />
        )}
        {isColorFacet ? (
          <ColorCircles isShades={field !== colorGroupLabel} />
        ) : (
          <FilterSubItems
            isMobile={isMobile}
            renderedFilters={filteredFilters}
            appFilters={appFilters}
            field={field}
            loadingFilters={[]}
            onFilterClicked={handleFilterClicked}
            currentParentItems={renderedParent?.[1]?.items}
            getFiltersByField={getFiltersByField}
            onMobileOpenFilter={onMobileOpenFilter}
            mobileActiveFilter={mobileActiveFilter}
          />
        )}

        {filteredFilters.length === 0 && (
          <Styled.NoResults>No results</Styled.NoResults>
        )}
      </FilterGroup>
    </Styled.FilterGroupContainer>
  );
}, isEqual);

FilterItem.displayName = 'FilterItem';

export { FilterItem };
